import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';

import './styles.scss';

const DropdownSelectButton = ({ value, id, onClick }) => {
    return (
        <ButtonBase
            className={`asn-select-button ${value ? '' : 'placeholder'}`}
            disableRipple
            aria-describedby={id}
            onClick={onClick}
        >
            {value ? (
                <Typography component="span" className="asn-label">
                    {value.label}
                </Typography>
            ) : (
                <Typography component="span">Select ISP&nbsp;&#47;&nbsp;ASN</Typography>
            )}
            <Chevron width={20} height={20} />
        </ButtonBase>
    );
};

export default DropdownSelectButton;
