import { ReactComponent as StaticProxyIcon } from 'icons/static-proxy-outlined.svg';
import { ReactComponent as RotatingProxyIcon } from 'icons/rotating-proxy.svg';
import { ReactComponent as LockIcon } from 'icons/lock-outlined.svg';
import { ReactComponent as SocksIcon } from 'icons/socks-proxy-outlined.svg';

export const form_keys = {
    TYPE: 'list_session_type',
    COUNTRY: 'country_id',
    CITY: 'city_id',
    ASN: 'asn_id',
    PROXY_PROTOCOL: 'list_protocol',
    PROXY_FORMAT: 'list_format',
    PROXY_USER_ID: 'proxy_user_id',
    QUANTITY: 'list_count',
    AI_ENABLED: 'list_ai_enabled',
};

export const type_options = [
    { value: 'sticky', label: 'Sticky', icon: StaticProxyIcon },
    { value: 'rotating', label: 'Rotating', icon: RotatingProxyIcon },
];

export const protocol_options = [
    { value: 'http', label: 'HTTP', icon: LockIcon },
    { value: 'socks5', label: 'SOCKs', icon: SocksIcon },
];
