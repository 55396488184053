const badge = {
    styleOverrides: {
        // Name of the slot
        badge: {
            fontSize: '12px',
            letterSpacing: 0,
        },
    },
};

export default badge;
