import { useCallback, useState } from 'react';

const useSearchableDropdown = ({ id = 'search-dropdown' }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback(
        event => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl],
    );

    const handleClose = useCallback(() => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    }, [anchorEl, setAnchorEl]);

    const dropdownId = anchorEl ? id : undefined;

    return { id: dropdownId, anchorEl, handleClick, handleClose, isOpen: !!anchorEl };
};

export default useSearchableDropdown;
