import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Chip from '@mui/material/Chip';

import { selectCustomer } from 'store/slices/customer';
import { convertToDollar } from 'helpers/payments';
import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';

import StatisticItem from './StatisticItem';
import ModalTopUp from 'components/Modals/ModalTopUp';

import { ReactComponent as Icon1 } from 'icons/colored/statistic-bar-3.svg';
import { ReactComponent as Icon2 } from 'icons/colored/statistic-bar-2.svg';
import { ReactComponent as Icon3 } from 'icons/colored/statistic-bar-1.svg';

import './styles.scss';

const StatisticBar = () => {
    const { residential_bytes_left, credit_balance, proxy_count, active_residential_service_id } =
        useSelector(selectCustomer);

    const formatBandwidth = bytes => {
        if (!bytes) {
            return '0 MB';
        }
        const units = ['MB', 'GB', 'TB', 'PB'];
        let unitIndex = 0;
        let value = bytes / (1024 * 1024) || 0; // Start with MB

        while (value >= 1000 && unitIndex < units.length - 1) {
            value /= 1024;
            unitIndex++;
        }

        return `${value.toFixed(2)} ${units[unitIndex]}`;
    };

    const convertedValue = formatBandwidth(residential_bytes_left);
    const convertedBalance = convertToDollar(credit_balance);

    const items = [
        {
            title: 'Proxies Number',
            tooltip: 'The number of static proxies assigned to your account',
            value: proxy_count,
            icon: <Icon1 />,
            action: (
                <Chip
                    label={proxy_count === 0 ? 'Buy Proxies' : 'View'}
                    color="success"
                    component={NavLink}
                    to={proxy_count === 0 ? ROUTES.products : ROUTES.proxyList}
                    replace
                    size="small"
                    variant="filled"
                    clickable
                />
            ),
        },
        {
            title: 'Residential Bandwidth',
            tooltip: 'The amount of available residential bandwidth left on your account',
            value: convertedValue,
            icon: <Icon2 />,
            action: (
                <Chip
                    label={active_residential_service_id ? 'Add Bandwidth' : 'Generate'}
                    color="success"
                    component={NavLink}
                    to={
                        active_residential_service_id
                            ? ROUTES.products + '/' + service_types.residential
                            : ROUTES.residential
                    }
                    replace
                    size="small"
                    variant="filled"
                    clickable
                />
            ),
        },
        {
            title: 'Balance',
            tooltip: 'The amount of store credit available on your account',
            value: convertedBalance,
            icon: <Icon3 />,
            action: <ModalTopUp labelButton />,
        },
    ];

    return (
        <>
            <div className="statistic-bar">
                {items.map(props => (
                    <StatisticItem key={props.title} {...props} />
                ))}
            </div>
        </>
    );
};

export default StatisticBar;
