import { useDispatch, useSelector } from 'react-redux';

import {
    selectBillingPaymentsLoading,
    selectBillingPaymentsTable,
    selectBillingPaymentsTotal,
    fetchBillingPayments,
} from 'store/slices/billingPaymentSettings';

import BillingLayout from '../Layout';
import PaymentSettingsTable from './Table';
import NoData from './NoData';
import AddNewMethodButton from 'components/Buttons/AddNewMethodButton';

import '../styles.scss';

const PaymentSettings = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectBillingPaymentsTable);
    const loading = useSelector(selectBillingPaymentsLoading);
    const total = useSelector(selectBillingPaymentsTotal);

    return (
        <BillingLayout headerContent={<AddNewMethodButton text="Add Card" />}>
            <PaymentSettingsTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchBillingPayments(filters))}
                staticFilters={{
                    payment_method_status: 'attached',
                }}
                total={total}
                noDataComponent={<NoData />}
            />
        </BillingLayout>
    );
};

export default PaymentSettings;
