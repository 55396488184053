import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCountries, selectCountriesOptions } from 'store/slices/customer';
import { selectProxyFilterOptions } from 'store/slices/proxy';
import { proxyFilters } from 'helpers/constants';

import Select from 'components/_common/Select/SimpleSelect';
import Flag from 'components/_common/CountryDropdown/Items/Flag';

const CountryFilter = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const proxyFilterOptions = useSelector(selectProxyFilterOptions);
    const countryOptions = useSelector(selectCountriesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCountries({ per_page: 500 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedOptions = useMemo(() => {
        const filterOptionsMap = new Set(proxyFilterOptions[proxyFilters.COUNTRY_ID]);

        return (
            countryOptions?.reduce((acc, country) => {
                if (!filterOptionsMap.has(country.value)) return acc;

                const option = { ...country, icon: () => <Flag country_id={country.value} size={20} /> };

                return acc.concat(option);
            }, []) ?? []
        );
    }, [proxyFilterOptions, countryOptions]);

    return (
        <Select
            label="Countries"
            options={formattedOptions || []}
            value={value}
            onChange={onChange}
            optionClassName="static-proxy-country-filter"
            withIcon
        />
    );
};

export default CountryFilter;
