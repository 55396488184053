// @ts-nocheck
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import LogService from 'api/services/LogService';
import { withoutEmptyValues } from 'helpers';

export const fetchAnalytict = createAsyncThunk('fetchAnalytict', async ({ searchParams, userIds }) => {
    // Remove any empty string or undefined
    const params = withoutEmptyValues(searchParams);

    if ((userIds?.length ?? 0) < 1) {
        const response = await LogService.analytics(params);

        return [transformResponse(response)];
    } else {
        const responses = await Promise.all(
            userIds.map(async userId => {
                // Override previous user id
                Object.assign(params, { proxy_user_id: userId });
                const response = await LogService.analytics(params);

                return transformResponse(response, userId);
            }),
        );

        return responses;
    }

    function transformResponse(response, user_id) {
        return {
            proxy_user_id: user_id ?? null,
            intervals: response.data.data.intervals,
            totals: {
                totalBytes: response.data.data.total_bytes,
                totalSuccessful: response.data.data.total_successful,
                totalError: response.data.data.total_error,
                totalRequests: response.data.data.total_requests,
            },
        };
    }
});

const LogSlice = createSlice({
    name: 'LogSlice',
    initialState: {
        analytics: {
            loading: false,
            data: null,
            error: null,
            lastUpdated: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAnalytict.pending, state => {
                state.analytics.loading = true;
            })
            .addCase(fetchAnalytict.fulfilled, (state, action) => {
                state.analytics.loading = false;
                state.analytics.data = action.payload;
                state.analytics.lastUpdated = Date.now();
            })
            .addCase(fetchAnalytict.rejected, (state, action) => {
                state.analytics.loading = false;
                state.analytics.error = action.error;
            });
    },
});

export const selectAnalytics = createSelector([state => state.logState.analytics.data], data => {
    return data || [];
});

export const selectAnalyticsLoading = createSelector(
    [state => state.logState.analytics],
    analytics => analytics.loading,
);

export default LogSlice.reducer;
