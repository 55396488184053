import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import DropdownFlag from '../DropdownFlag';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';

import './styles.scss';

const DropdownSelectButton = ({ value, id, onClick }) => {
    return (
        <ButtonBase
            className={`country-select-button ${value ? '' : 'placeholder'}`}
            disableRipple
            aria-describedby={id}
            onClick={onClick}
        >
            {value ? (
                <>
                    <DropdownFlag country_code={value.value} label={value.label} />
                    <Typography component="span" className="country-label">
                        {value.label}
                    </Typography>
                </>
            ) : (
                <Typography component="span" gridColumn="1 / 3">
                    Select country
                </Typography>
            )}
            <Chevron width={20} height={20} />
        </ButtonBase>
    );
};

export default DropdownSelectButton;
