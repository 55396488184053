import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';
import { ReactComponent as Icon } from 'icons/plus.svg';

const ButtonBuyNewProxies = () => (
    <Button
        Icon={<Icon />}
        variant="outlined"
        size="medium"
        to={ROUTES.products}
        customStyles={{ fontWeight: 600 }}
        fullWidth
    >
        Buy new proxies
    </Button>
);

export default ButtonBuyNewProxies;
