import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import ModalCreateApiKey from 'components/Modals/ModalCreateApiKey/ModalCreateApiKey';
import ModalGeneratedApiKeys from 'components/Modals/ModalGeneratedApiKeys';
import showMessage from 'components/NoticeProvider/showMessage';
import InfoBanner from 'components/InfoBanner';
import ApiKeysTable from './Table';
import NoData from './NoData';

import ApiKeysService from 'api/services/ApiKeysService';

import { fetchApiKeys, selectApiKeysLoading, selectApiKeysTableData, selectApiKeysTotal } from 'store/slices/apiKeys';

const ApiKeys = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectApiKeysTableData);
    const loading = useSelector(selectApiKeysLoading);
    const total = useSelector(selectApiKeysTotal);
    const [searchParams] = useSearchParams();

    const [loadingModal, setLoadingModal] = useState(false);
    const [createdApiKeypair, setCreatedApiKeypair] = useState({});
    const [open, setOpen] = useState(false);

    const handleCreate = async api_key_label => {
        try {
            setLoadingModal(true);
            await ApiKeysService.create({ api_key_label })
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoadingModal(false);
                    setCreatedApiKeypair(response.data.data);
                })
                .then(() => {
                    setOpen(true);
                    const showDeleted = searchParams.get('api_key_status') === 'active';
                    const pageAsNumber = +(searchParams.get('page') ?? 1);
                    const perPageAsNumber = +(searchParams.get('per_page') ?? 10);

                    const params = {
                        page: isNaN(pageAsNumber) ? 1 : pageAsNumber > 0 ? pageAsNumber : 1,
                        per_page: isNaN(perPageAsNumber)
                            ? 10
                            : [10, 25, 50, 100].includes(perPageAsNumber)
                            ? perPageAsNumber
                            : 10,
                    };

                    if (showDeleted) {
                        params.api_key_status = 'active';
                    }

                    dispatch(fetchApiKeys(params));
                })
                .finally(() => {
                    setLoadingModal(false);
                });
        } catch (e) {
            return false;
        }
    };

    const showModal = !!data.length;

    return (
        <>
            <PageHeader
                title="API Keys"
                right={showModal && <ModalCreateApiKey loading={loadingModal} onCreate={handleCreate} />}
            />
            <ApiKeysTable
                data={data}
                dataLoading={loading}
                fetchData={filters => dispatch(fetchApiKeys(filters))}
                total={total}
                noDataComponent={<NoData loadingModal={loadingModal} handleCreate={handleCreate} />}
            />
            <InfoBanner />
            <ModalGeneratedApiKeys setOpen={setOpen} open={open} keys={createdApiKeypair} />
        </>
    );
};

export default ApiKeys;
