import './styles.scss';

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import Flag from 'components/_common/CountryDropdown/Items/Flag';
import SearchableDropdown from 'components/_common/SearchableDropdown';
import Badge from 'components/_common/Badge';

import { selectNonResidentialServicesOptions } from 'store/slices/services';

import { getServiceIcon } from 'helpers/services';

const Dropdown = ({ value, onChange }) => {
    const non_residential_products_options = useSelector(selectNonResidentialServicesOptions);

    const handleChange = useCallback(
        newValues => {
            onChange(newValues.map(product => (typeof product === 'string' ? product : product.value)));
        },
        [onChange],
    );

    return (
        <SearchableDropdown
            id="link-product"
            options={non_residential_products_options}
            value={value}
            onChange={handleChange}
            noOptionsPlaceholder="No products found"
            placeholder="Link To Product"
            renderOption={({ option }) => {
                return (
                    <>
                        <Tooltip
                            title={<Chip label={option.name} />}
                            slotProps={{
                                tooltip: { sx: { backgroundColor: 'transparent' } },
                            }}
                        >
                            {getServiceIcon(option.service_type, 20)}
                        </Tooltip>
                        <Typography className="product-label product-id">{option.label}</Typography>
                        <Chip
                            label={option.quantity + (option.quantity > 1 ? ' IPs' : ' IP')}
                            variant="filled"
                            color="default"
                        />
                        <Flag country_id={option.country_id} size={20} />
                    </>
                );
            }}
            renderDropdownLabel={({ value, placeholder, isEmpty }) => (
                <Typography
                    className={`edit-user__products-dropdown-label ${isEmpty ? 'is-empty' : ''}`}
                    component="span"
                >
                    {placeholder} {!isEmpty ? <Badge value={value.length} /> : null}
                </Typography>
            )}
            slotProps={{
                dropdownList: {
                    multiple: true,
                    disableCloseOnSelect: true,
                    className: 'edit-user__products-dropdown__search',
                    isOptionEqualToValue: (option, value) => option.value === (value?.value ?? value),
                },
            }}
            dropdownClassname="edit-user__products-dropdown"
            optionContainerClassName="product-option"
        />
    );
};

export default Dropdown;
