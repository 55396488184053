import React from 'react';

import PopupBadge from 'components/_common/PopupBadge';

import Code from './Code';
import Dots from './Dots';

import './styles.scss';

const CodeSnippet = ({ badgeVariant }) => {
    return (
        <div className="code-snip-container">
            <Dots />
            <span className="divider" />
            <Code />

            {badgeVariant ? <PopupBadge variant={badgeVariant} /> : null}
        </div>
    );
};

export default CodeSnippet;
