import React from 'react';
import Box from '@mui/material/Box';

import { ReactComponent as TrashBinIcon } from 'images/popup/trash-bin.svg';
import { ReactComponent as SuccessCheckIcon } from 'images/popup/success-check.svg';
import { ReactComponent as WarnCircleIcon } from 'images/popup/warning.svg';
import { ReactComponent as WarnRectIcon } from 'images/popup/warn-reactangle-filled.svg';
import { ReactComponent as MoneyWavyIcon } from 'images/popup/money-wavy.svg';

import './styles.scss';

export const BADGE_VARIANTS = Object.freeze({
    success: 'success',
    warn: 'warn',
    delete: 'delete',
    warn_rect: 'warn_rect',
    money_wavy: 'money_wavy',
});

export const BADGE_SIZE = Object.freeze({
    sm: 'sm',
    base: 'base',
    md: 'md',
    xl: 'xl',
});

const Icons = {
    [BADGE_VARIANTS.success]: SuccessCheckIcon,
    [BADGE_VARIANTS.warn]: WarnCircleIcon,
    [BADGE_VARIANTS.delete]: TrashBinIcon,
    [BADGE_VARIANTS.warn_rect]: WarnRectIcon,
    [BADGE_VARIANTS.money_wavy]: MoneyWavyIcon,
};

const PopupBadge = ({
    variant = BADGE_VARIANTS.success,
    rounded = true,
    iconSize = 18,
    size = BADGE_SIZE.base,
    className,
}) => {
    const Icon = Icons[variant];

    if (!Icon) return;

    return (
        <Box
            className={`popup-badge ${size} ${variant} ${rounded ? 'rounded' : ''} ${className ?? ''}`}
            component="span"
        >
            <Icon width={iconSize} height={iconSize} />
        </Box>
    );
};

export default PopupBadge;
