import './styles.scss';

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import SearchableDropdownList from 'components/_common/SearchableDropdownList';
import DropdownSelectButton from './DropdownSelectButton';

import useSearchableDropdown from './useSearchableDropdown';

const SearchableDropdown = ({
    value,
    onChange,
    options = [],
    placeholder,
    renderOption,
    optionContainerClassName,
    renderDropdownLabel,
    dropdownClassname,
    id: dropdownId,
    noOptionsPlaceholder,
    slotProps = { dropdown: {}, dropdownList: {} },
}) => {
    const { id, anchorEl, handleClick, handleClose, isOpen } = useSearchableDropdown({ id: dropdownId });

    return (
        <>
            <DropdownSelectButton
                id={id}
                onClick={handleClick}
                value={value}
                placeholder={placeholder}
                renderLabel={renderDropdownLabel}
                className={dropdownClassname}
                {...slotProps.dropdown}
            />

            <SearchableDropdownList
                anchorEl={anchorEl}
                id={id}
                onClose={handleClose}
                open={isOpen}
                value={value}
                setValue={onChange}
                renderTags={() => null}
                noOptionsText={noOptionsPlaceholder}
                renderOption={(props, option, { selected }) => {
                    const optionLabel =
                        typeof slotProps?.dropdownList?.getOptionLabel === 'function'
                            ? slotProps.dropdownList.getOptionLabel(option)
                            : typeof option === 'string'
                            ? option
                            : option.label;

                    const isMultiple = slotProps.dropdownList.multiple;

                    return (
                        <li
                            {...props}
                            key={option.value}
                            className={`dropdown-list__option ${props.className} ${selected ? 'selected' : ''} ${
                                isMultiple ? 'multiple' : ''
                            }`}
                        >
                            {isMultiple ? <Checkbox checked={selected} sx={{ padding: 0 }} size="small" /> : null}
                            <Box className={optionContainerClassName}>
                                {renderOption && typeof renderOption === 'function' ? (
                                    renderOption({ option, selected })
                                ) : (
                                    <Typography className="dropdown-list__label">{optionLabel}</Typography>
                                )}
                            </Box>
                        </li>
                    );
                }}
                options={options}
                getOptionLabel={option => option.label ?? ''}
                {...slotProps.dropdownList}
            />
        </>
    );
};

export default SearchableDropdown;
