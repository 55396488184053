import axios from 'axios';

import urls from 'api/urls-config.json';

const strapiBaseUrl = process.env.REACT_APP_CMS_API_URL;
const authToken = process.env.REACT_APP_CMS_API_TOKEN;

export default class StrapiService {
    static async getCta() {
        return axios.get(urls.strapi.cta, {
            baseURL: strapiBaseUrl,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    }

    static async getBanner() {
        return axios.get(urls.strapi.banner, {
            baseURL: strapiBaseUrl,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    }
}
