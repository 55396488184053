import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import FormTemplate from 'components/FormTemplate';
import AuthDivider from 'components/FormTemplate/Items/AuthDivider';
import AuthLink from 'components/FormTemplate/Items/AuthLink';
import ErrorText from 'components/FormTemplate/Items/ErrorText';
import GoggleButton from 'components/FormTemplate/Items/GoggleButton';
import CreateAccountButton from 'components/FormTemplate/Items/CreateAccountButton';
import SubmitButton from 'components/FormTemplate/Items/SubmitButton';
import TextInput from 'components/_common/TextInput';
import TwoFactorForm from './TwoFactorForm';

import { setAuth } from 'store/slices/auth';
import AuthService from 'api/services/AuthService';

import ROUTES from 'helpers/routes';
import { loginValidationSchema } from 'helpers/validations';
import { setAuthToken } from 'api/helpers';
import { trackSession } from 'trackers/UtmTracker';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';

const initialValues = { customer_email_address: '', customer_password: '' };

const SignInForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(1);
    const [otp, setOtp] = useState('');
    const [fields, setFields] = useState(null);
    const [searchParams] = useSearchParams();
    const errorMessage = searchParams.get('error');
    const access_token = searchParams.get('access_token');
    const productType = searchParams.get('product-type');

    const eventTracker = useAnalyticsEventTracker({
        name: 'User',
        params: 'Sign in',
    });

    const auth = token => {
        localStorage.removeItem('shownInvoicesIds');
        setAuthToken(token);
        dispatch(setAuth(true));
        navigate(productType ? `${ROUTES.products}${search}` : ROUTES.dashboard);
    };

    useEffect(() => {
        if (access_token) {
            auth(access_token);
        }
    }, [access_token]);

    const formik = useFormik({
        initialValues,
        validationSchema: loginValidationSchema,
        onSubmit: async params => {
            try {
                setLoading(true);
                const utm_information = trackSession();
                const { customer_email_address, customer_password } = params;
                const newParams = { customer_email_address, customer_password, utm_information };
                await AuthService.login(newParams)
                    .then(response => {
                        auth(response.data.data.access_token);
                        eventTracker();
                    })
                    .catch(({ response }) => {
                        const { two_factor_authentication_public_key } = response.data.data;
                        if (response.status === 499) {
                            setFields({ ...newParams, two_factor_authentication_public_key });
                            setState(2);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    const handleFinalSubmit = async () => {
        const utm_information = trackSession();
        const params = { ...fields, two_factor_authentication_private_key: otp, utm_information };
        setLoading(true);
        try {
            await AuthService.login(params)
                .then(response => {
                    auth(response.data.data.access_token);
                    eventTracker();
                })
                .catch(() => {
                    setOtp('');
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleReturn = () => setState(1);

    if (state === 1) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <FormTemplate title="Sign in to Ping">
                    <TextInput
                        withFormik
                        name="customer_email_address"
                        label="Email"
                        value={formik.values.customer_email_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_email_address && Boolean(formik.errors.customer_email_address)}
                        helperText={formik.touched.customer_email_address && formik.errors.customer_email_address}
                    />
                    <TextInput
                        withFormik
                        name="customer_password"
                        label="Password"
                        type="password"
                        value={formik.values.customer_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_password && Boolean(formik.errors.customer_password)}
                        helperText={formik.touched.customer_password && formik.errors.customer_password}
                    />
                    <div className="mt-8">
                        <AuthLink to={ROUTES.recovery}>Forgot your password?</AuthLink>
                    </div>
                    <SubmitButton loading={loading}>Sign in</SubmitButton>
                    <AuthDivider />
                    {process.env.REACT_APP_ENABLE_GOOGLE_LOGIN_SIGNUP_ACCOUNT === 'TRUE' && (
                        <GoggleButton isLogin to={search && `${ROUTES.products}${search}`} />
                    )}
                    <CreateAccountButton queryParams={search} />
                    <div className="auth-form__support">
                        Do you need help?&nbsp;
                        <div
                            className="auth-link"
                            onClick={() =>
                                // @ts-ignore
                                window.MavaWebChatToggle()
                            }
                        >
                            Contact Support
                        </div>
                    </div>
                    {Boolean(errorMessage) && <ErrorText>{errorMessage}</ErrorText>}
                </FormTemplate>
            </form>
        );
    }

    if (state === 2) {
        return (
            <TwoFactorForm otp={otp} setOtp={setOtp} loading={loading} onSubmit={handleFinalSubmit}>
                <button className="auth-link" onClick={handleReturn}>
                    Return to login
                </button>
            </TwoFactorForm>
        );
    }
};

export default SignInForm;
