import React from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';

import Table from 'components/_common/Table';
import AmountCell from 'components/_common/TableCells/AmountCell';
import TextCell from 'components/_common/TableCells/TextCell';

import { balanceTransactionReasons } from 'helpers/constants';
import ROUTES from 'helpers/routes';

const BalanceTransactionsTable = ({ data, dataLoading, fetchData, total, noDataMessage }) => {
    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            {
                field: 'balance_transaction_id',
                headerName: 'Balance transaction ID',
                flex: 0.5,
                minWidth: 250,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'balance_transaction_creation_datetime',
                headerName: 'Date',
                flex: 0.5,
                minWidth: 180,
                sortable: true,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'invoice_id',
                headerName: 'Invoice ID',
                flex: 0.5,
                minWidth: 250,
                renderCell: ({ value }) => (
                    <TextCell text={value} onClick={() => navigate(`${ROUTES.billingInvoices}/${value}`)} />
                ),
            },
            {
                field: 'balance_transaction_reason',
                headerName: 'Reason',
                width: 150,
                renderCell: ({ value, row }) => (
                    <Chip
                        label={balanceTransactionReasons[value] || value}
                        color={row.amount >= 0 ? 'success' : 'error'}
                    />
                ),
            },
            {
                field: 'amount',
                headerName: 'Amount',
                headerAlign: 'right',
                minWidth: 160,
                renderCell: ({ value }) => <AmountCell value={value} />,
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            total={total}
            noDataMessage={noDataMessage}
            columnVisibilityModel={{ id: false }}
            sortModel={[
                {
                    field: 'balance_transaction_creation_datetime',
                    sort: 'desc',
                },
            ]}
        />
    );
};

export default BalanceTransactionsTable;
