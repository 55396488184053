import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ProductPage from './ProductPage';
import Spinner from 'components/_common/Spinner';

import { fetchProducts, selectMainProductsArray, selectProductsIsLoading } from 'store/slices/products';
import { selectActiveResidentialServiceId } from 'store/slices/customer';

import ROUTES from 'helpers/routes';
import { service_types, serviceStatuses } from 'helpers/services';
import ServicesService from 'api/services/ServicesService';

const Product = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const loading = useSelector(selectProductsIsLoading);
    const products = useSelector(selectMainProductsArray);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);

    const [isActiveResidentialServiceLoading, setIsActiveResidentialServiceLoading] = useState(false);

    const currentProduct = products.find(product => product.product_type === slug);
    const isResidential = currentProduct?.product_type === service_types.residential;
    const isReconfigure = searchParams.get('reconfigure');
    const serviceQuantity = searchParams.get('quantity');

    const retrieveResidentialService = async id => {
        try {
            setIsActiveResidentialServiceLoading(true);
            const response = await ServicesService.retrieve(id);
            setIsActiveResidentialServiceLoading(false);
            const data = response?.data?.data;
            return data;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            if (!currentProduct && !loading) {
                navigate(ROUTES[404]);
            }

            if (isResidential && active_residential_service_id) {
                const data = await retrieveResidentialService(active_residential_service_id);
                const isActive = data?.service_status === serviceStatuses.ACTIVE;
                if (isActive && (!isReconfigure || !serviceQuantity)) {
                    return navigate(
                        `${ROUTES.products}/${service_types.residential}?reconfigure=true&value=${data.service_quantity}`,
                        { replace: true },
                    );
                }
                if (!isActive && isReconfigure) {
                    return navigate(`${ROUTES.products}/${service_types.residential}`, { replace: true });
                }
            }
        })();
    }, [currentProduct, loading]);

    if (loading || isActiveResidentialServiceLoading) {
        return <Spinner />;
    }

    return currentProduct && <ProductPage currentProduct={currentProduct} />;
};

export default Product;
