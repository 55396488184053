import { useSelector } from 'react-redux';

import ButtonBuyNewProxies from './Items/ButtonBuyNewProxies';
import SidebarNavLink from './Items/SidebarNavLink';
import MenuDropdown from './Items/MenuDropdown';

import {
    developerItems,
    servicesItems,
    helpItems,
    myProxyItems,
    staticProxyItems,
    billingItems,
} from 'helpers/constants';
import ROUTES from 'helpers/routes';

import { selectCustomer } from 'store/slices/customer';

const SidebarBody = () => {
    const { customer_id, customer_email_address } = useSelector(selectCustomer);

    const customerData = [
        { label: 'customer_id', value: String(customer_id) },
        { label: 'customer_email_address', value: customer_email_address },
    ];

    return (
        <div className="sidebar-body">
            <ButtonBuyNewProxies />
            <SidebarNavLink to={ROUTES.dashboard} text="dashboard" />
            <div>
                <div className="sidebar-title">Service Management</div>
                {servicesItems.map(data => {
                    if (data.to === ROUTES.billingSubscriptions) {
                        return (
                            <MenuDropdown
                                key={data.to}
                                path={'/billing'}
                                items={billingItems}
                                to={data.to}
                                text={data.text}
                            />
                        );
                    }
                    return <SidebarNavLink key={data.to || data.text} {...data} />;
                })}
            </div>
            <div>
                <div className="sidebar-title">My Proxies</div>
                {myProxyItems.map(data => {
                    if (data.to === ROUTES.proxyList) {
                        return (
                            <MenuDropdown
                                key={data.to}
                                path={'/static-proxy'}
                                items={staticProxyItems}
                                to={data.to}
                                text={data.text}
                            />
                        );
                    }
                    return <SidebarNavLink key={data.to || data.text} {...data} />;
                })}
            </div>
            {process.env.REACT_APP_ENABLE_API_SECTION === 'TRUE' && (
                <div>
                    <div className="sidebar-title">Developer Section</div>
                    {developerItems.map(({ to, text, blank, className, icon }) => {
                        return (
                            <SidebarNavLink
                                key={to}
                                to={to}
                                icon={icon}
                                text={text}
                                className={className}
                                blank={blank}
                            />
                        );
                    })}
                </div>
            )}
            <div>
                <div className="sidebar-title">Help</div>
                {helpItems.map(({ icon, text, onClick, to, blank }) => {
                    return (
                        <SidebarNavLink
                            key={text}
                            to={to}
                            icon={icon}
                            text={text}
                            onClick={() => (onClick ? onClick(customerData) : null)}
                            blank={blank}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SidebarBody;
