import PageHeader from 'components/PageHeader';
import NoData from './NoData';
import ReplacementListTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectReplacementListLoading,
    fetchReplacementList,
    selectReplacementListTableData,
    selectReplacementListTotal,
} from 'store/slices/replacement';

import InfoBanner from 'components/InfoBanner';

const Replacements = () => {
    const dispatch = useDispatch();

    const data = useSelector(selectReplacementListTableData);
    const loading = useSelector(selectReplacementListLoading);
    const total = useSelector(selectReplacementListTotal);

    return (
        <div>
            <PageHeader title="Replacements" />
            <InfoBanner
                {...{
                    marginBottom: 24,
                    marginTop: 0,
                    header: 'Replacement Guide',
                    content:
                        'On rare occasions, we have to replace certain proxies. Read our guide about replacements and how to update your proxy lists.',
                    article: process.env.REACT_APP_API_DOCUMENTATION_REPLACEMENT_LINK,
                }}
            />
            <ReplacementListTable
                data={data}
                dataLoading={loading}
                fetchData={filters => dispatch(fetchReplacementList(filters))}
                total={total}
                noDataComponent={<NoData />}
            />
        </div>
    );
};

export default Replacements;
