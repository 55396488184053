// @ts-nocheck
import React, { forwardRef } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ButtonSpinner from 'components/_common/Button/ButtonSpinner';

import { ReactComponent as ChevronLeftIcon } from 'icons/chevron-left.svg';

import './styles.scss';

const PopupActions = forwardRef(
    (
        {
            onCancel,
            onSubmit,
            loading,
            single = false,
            cancelContent = 'Cancel',
            submitContent,
            submitProps = {},
            cancelProps = {},
            className,
        },
        ref,
    ) => {
        return (
            <DialogActions ref={ref} className={`dialog-actions ${single ? 'single' : ''} ${className ?? ''}`}>
                {!single ? (
                    <Button
                        variant="outlined"
                        disabled={loading}
                        className={`bg-white dialog-actions-button ${loading && 'loading'}`}
                        {...cancelProps}
                        onClick={onCancel}
                    >
                        {loading ? <ButtonSpinner /> : cancelContent}
                    </Button>
                ) : null}

                <Button
                    className="dialog-actions-button"
                    variant={single ? 'outlined' : 'contained'}
                    color="primary"
                    {...submitProps}
                    onClick={onSubmit}
                >
                    {submitContent ?? (
                        <>
                            <ChevronLeftIcon />
                            <Typography component="span">Go back</Typography>
                        </>
                    )}
                </Button>
            </DialogActions>
        );
    },
);

PopupActions.displayName = 'PopupActions';
PopupActions.muiName = 'DialogActions';

export default PopupActions;
