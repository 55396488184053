const palette = {
    primary: {
        light: '#8d84f2',
        main: '#776cef',
        dark: '#3c32a7',
        contrastText: '#fff',
        50: '#eeedfd',
        100: '#cac6f9',
        200: '#b1aaf6',
        300: '#8d84f2',
        400: '#776cef',
        500: '#5547eb',
        600: '#4d41d6',
        700: '#3c32a7',
        800: '#2f2781',
        900: '#241e63',
    },
    error: {
        light: '#F27A72',
        main: '#EB392D',
        dark: '#A72820',
        contrastText: '#fff',
        50: '#fdebea',
        100: '#f9c2be',
        200: '#f6a49e',
        300: '#f27a72',
        400: '#ef6157',
        500: '#eb392d',
        600: '#d63429',
        700: '#a72820',
        800: '#811f19',
        900: '#631813',
    },
    success: {
        main: '#22c55e',
        50: '#e9f9ef',
        100: '#baedcd',
        200: '#99e4b5',
        300: '#6bd893',
        400: '#4ed17e',
        500: '#22c55e',
        600: '#1fb356',
        700: '#188c43',
        800: '#136c34',
        900: '#0e5327',
    },
    warning: {
        50: '#fef7eb',
        100: '#fbe6c1',
        200: '#f9d9a3',
        300: '#f6c878',
        400: '#f5bd5e',
        500: '#f2ad36',
        600: '#dc9d31',
        700: '#ac7b26',
        800: '#855f1e',
        900: '#664917',
    },
    grey: {
        main: '#6c737f',
        50: '#f9fafb',
        100: '#f3f4f6',
        200: '#e5e7eb',
        300: '#d2d6db',
        400: '#9da4ae',
        500: '#6c737f',
        600: '#4d5761',
        700: '#384250',
        800: '#1f2a37',
        900: '#111927',
    },
};

export default palette;
