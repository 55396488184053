import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import PaymentService from 'api/services/PaymentService';
import ServicesService from 'api/services/ServicesService';

import { formatPeriod } from 'helpers';
import { convertToDollar, getPaymentsOptions } from 'helpers/payments';

export const fetchBillingOrders = createAsyncThunk(
    'fetchBillingOrders',
    async params => {
        // @ts-ignore
        const response = await ServicesService.summary({sort_by: 'service_creation_datetime_desc', ...params});
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().billingOrdersState.services;
            return !loading;
        },
    },
);

export const fetchPaymentMethodsForPopup = createAsyncThunk('fetchPaymentMethodsForPopup', async () => {
    const response = await PaymentService.search({
        payment_method_platform: 'stripe',
        payment_method_status: 'attached',
        payment_method_type: 'card',
    });
    return response.data;
});

export const fetchPaymentMethods = async () => {
    const response = await PaymentService.search();
    return response.data;
};

const billingOrdersSlice = createSlice({
    name: 'billingOrdersSlice',
    initialState: {
        services: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
        payment_methods: {
            loading: false,
            data: [],
            error: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBillingOrders.pending, state => {
                state.services.loading = true;
            })
            .addCase(fetchBillingOrders.fulfilled, (state, action) => {
                state.services.loading = false;
                state.services.data = action.payload.data;
                state.services.total = action.payload.total_count;
            })
            .addCase(fetchBillingOrders.rejected, (state, action) => {
                state.services.loading = false;
                state.services.error = action.error;
            });
        builder
            .addCase(fetchPaymentMethodsForPopup.pending, state => {
                state.payment_methods.loading = true;
            })
            .addCase(fetchPaymentMethodsForPopup.fulfilled, (state, action) => {
                state.payment_methods.loading = false;
                state.payment_methods.data = action.payload.data;
            })
            .addCase(fetchPaymentMethodsForPopup.rejected, (state, action) => {
                state.payment_methods.loading = false;
                state.payment_methods.error = action.error;
            });
    },
});

export const selectBillingOrders = createSelector(
    [state => state.billingOrdersState.services],
    services => services.data,
);

export const selectBillingOrdersTable = createSelector([selectBillingOrders], data =>
    data.map(service => ({
        id: service.service_id,
        payment_method_id: service.payment_method_id,
        status: service.service_status,
        service: {
            id: service.service_id,
            country_id: service.country_id,
            type: service.service_type,
            name: service.service_name,
            value: service.service_quantity,
        },
        period: formatPeriod(service.service_cycle),
        date: service.service_creation_datetime,
        service_creation_datetime: service.service_creation_datetime,
        service_expiry_datetime: service.service_expiry_datetime,
        auto_renewal: service.service_is_automatic_collection,
        service_total: convertToDollar(service.service_total),
        type: service.service_type,
        service_price_id: service.service_price_id,
        service_quantity: service.service_quantity,
        service_subscription_id: service.service_subscription_id,
    })),
);

export const selectBillingOrdersLoading = createSelector(
    [state => state.billingOrdersState.services],
    services => services.loading,
);

export const selectBillingOrdersTotal = createSelector(
    [state => state.billingOrdersState.services],
    services => services.total,
);

export const selectPaymentMethods = createSelector(
    [state => state.billingOrdersState.payment_methods],
    payment_methods => payment_methods.data,
);

export const selectPaymentMethodsOptions = createSelector([selectPaymentMethods], data => getPaymentsOptions(data));

export const selectPaymentMethodsLoading = createSelector(
    [state => state.billingOrdersState.payment_methods],
    payment_methods => payment_methods.loading,
);

export default billingOrdersSlice.reducer;
