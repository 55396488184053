import badge from './badge';
import button from './button';
import chip from './chip';
import menuItem from './menuItem';
import paper from './paper';
import tab from './tab';
import tabs from './tabs';
import tooltip from './tooltip';

const components = {
    MuiButton: button,
    MuiTooltip: tooltip,
    MuiPaper: paper,
    MuiBadge: badge,
    MuiTabs: tabs,
    MuiTab: tab,
    MuiButtonBase: {
        defaultProps: {
            // The props to apply
            // disableRipple: false, // No more ripple, on the whole application 💣!
        },
    },
    MuiMenuItem: menuItem,
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                display: 'flex',
                alignItems: 'center',
                color: 'inherit',
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            inputSizeSmall: {
                fontSize: '14px',
            },
            root: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                letterSpacing: 0,
                color: 'inherit',
            },
        },
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                backgroundColor: '#ffffff',
                fontWeight: 500,
                paddingTop: '6px',
                paddingBottom: '6px',
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {},
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                fontSize: '14px',
                color: 'var(--ping-Grey-grey-800)',
                fontWeight: 700,
                letterSpacing: '0.2px',
                marginBottom: '4px',
                display: 'block',
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                paddingTop: 0,
                paddingBottom: 0,
                color: 'var(--ping-Grey-grey-800)',
                fontWeight: 500,
                fontSize: '16px',
                '::placeholder': {
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'var(--ping-Grey-grey-500)',
                },
            },
            inputSizeSmall: {
                height: '28px',
                fontSize: '14px',
                fontWeight: 500,
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
    MuiTextField: {
        styleOverrides: {},
    },
    MuiDialog: {
        styleOverrides: {
            paperWidthMd: '',
            root: {},
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {},
        },
    },
    MuiChip: chip,
};

export default components;
