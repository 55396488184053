const menuItem = {
    styleOverrides: {
        root: {
            alignItems: 'center',
            padding: '8px 12px',
            textTransform: 'inherit',
            fontSize: '14px',
            letterSpacing: '-0.2px',
            fontWeight: 500,
        },
    },
};

export default menuItem;
