import { ReactComponent as UserCheckIcon } from 'icons/user-check-outlined.svg';
import { ReactComponent as UserLightIcon } from 'icons/user-light-outlined.svg';
import { ReactComponent as UserStarIcon } from 'icons/user-star-outlined.svg';

const icons = [UserCheckIcon, UserLightIcon, UserStarIcon];

const colors = [
    'var(--ping-Brand-brand-600)',
    'var(--ping-Green-green-600)',
    'var(--ping-Orange-orange-600)',
    'var(--ping-Blue-blue-600)',
];

export const mapUserToIcons = (users, size = 20) => {
    return users.map((user, idx) => {
        const Icon = icons[idx % icons.length];
        const color = colors[Math.floor(idx / 3) % colors.length];

        return { ...user, icon: <Icon width={size} height={size} color={color} /> };
    });
};
