import './styles.scss';

import { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

const ip_v4_and_v6_regex =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

const MultipleInput = ({ values, setValues }) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event, value, reason) => {
        const isValid = ip_v4_and_v6_regex.test(value[value.length - 1]);
        if (reason === 'createOption' && isValid) setValues(value);
        if (reason === 'blur' && isValid) setValues(value);
        if (reason === 'removeOption') setValues(value);
        if (reason === 'clear') setValues([]);
    };

    const handleInputChange = (event, newInputValue, reason) => {
        if (reason === 'reset') setInputValue('');
        setInputValue(newInputValue);
    };

    const maxLimit = !(values?.length >= 3);

    return (
        <FormControl fullWidth className="custom-autocomplete">
            <FormLabel focused={false}>Whitelisted IP Addresses</FormLabel>
            <Autocomplete
                multiple
                autoSelect
                size="small"
                options={[]}
                limitTags={3}
                disablePortal
                value={values}
                popupIcon={null}
                freeSolo={maxLimit}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                getOptionLabel={option => option}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                            <Chip
                                key={key}
                                label={option}
                                variant="outlined"
                                color="default"
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        marginRight: '0px',
                                        marginLeft: '2px',
                                    },
                                }}
                                {...tagProps}
                            />
                        );
                    })
                }
                renderInput={params => <TextField {...params} placeholder="Add IP address" />}
            />
        </FormControl>
    );
};

export default MultipleInput;
