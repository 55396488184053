import './styles.scss';

import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import SearchableDropdown from 'components/_common/SearchableDropdown';
import { mapUserToIcons } from 'components/ProxyUserDropdown/UserIcon';
import Badge from 'components/_common/Badge';

import { selectUsersID } from 'store/slices/services';

const Label = ({ isEmpty, value, placeholder }) => {
    return (
        <Typography className={`log-chart__users-dropdown__label ${isEmpty ? 'is-empty' : ''}`} component="span">
            {placeholder} {!isEmpty ? <Badge value={value.length} /> : null}
        </Typography>
    );
};

const UsersDropdown = ({ onChange, value }) => {
    const usersID = useSelector(selectUsersID);

    const usersOptions = useMemo(() => {
        return mapUserToIcons(usersID?.map(id => ({ value: id, label: id })));
    }, [usersID]);

    const handleUsersChange = useCallback(
        users => {
            onChange(users.map(user => (typeof user === 'string' ? user : user.value)));
        },
        [onChange],
    );

    return (
        <SearchableDropdown
            id="proxy-user"
            noOptionsPlaceholder="No users found"
            onChange={handleUsersChange}
            value={value}
            renderOption={({ option, selected }) => {
                return (
                    <>
                        {option.icon}
                        <Typography className="user-label">{option.label}</Typography>
                    </>
                );
            }}
            options={usersOptions}
            slotProps={{
                dropdownList: {
                    isOptionEqualToValue: (option, value) => {
                        return option.value === value;
                    },
                    multiple: true,
                    className: 'log-chart__users-dropdown__search',
                },
            }}
            placeholder="Users"
            renderDropdownLabel={Label}
            dropdownClassname="log-chart__users-dropdown"
            optionContainerClassName="proxy-user-option"
        />
    );
};

export default UsersDropdown;
