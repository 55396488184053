import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';

import DropdownFlag from './DropdownFlag';
import DropdownSelectButton from './DropdownSelectButton';
import SearchableDropdownList from 'components/_common/SearchableDropdownList';

import './styles.scss';

const CountryDropdown = ({ value, onChange, options = [] }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback(
        event => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl],
    );

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const id = anchorEl ? 'target-country' : undefined;

    return (
        <>
            <DropdownSelectButton id={id} onClick={handleClick} value={value} />

            <SearchableDropdownList
                anchorEl={anchorEl}
                id={id}
                onClose={handleClose}
                open={!!anchorEl}
                value={value}
                setValue={onChange}
                renderTags={() => null}
                noOptionsText="No countries found"
                renderOption={(props, option, { selected }) => {
                    return (
                        <li
                            {...props}
                            key={option.value}
                            className={`country-option ${props.className} ${selected ? 'selected' : ''}`}
                        >
                            <DropdownFlag country_code={option.value} label={option.label} />
                            <Typography className="country-label">{option.label}</Typography>
                        </li>
                    );
                }}
                options={options}
                getOptionLabel={option => option.label ?? ''}
            />
        </>
    );
};

export default CountryDropdown;
