import './styles.scss';

import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, FormLabel } from '@mui/material';
import isNil from 'lodash/isNil';

import Flag from './Items/Flag';
import TooltipComponent from '../Tooltip';

import { countries } from './constants';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';
import { ReactComponent as Globe } from 'icons/globe.svg';

const OOS = 'Out Of Stock';

const getCountryCodeByName = name => countries.find(item => item.label === name);

const getValues = (value, options) => {
    const code = getCountryCodeByName(value)?.code;
    const item = options.find(item => item.country_id === code?.toLowerCase());
    return { ...item, code };
};

const CountryDropdown = ({ value, onChange, disabled = false, options }) => {
    const handleChange = (event, newValue) => onChange(newValue);
    const [inputValue, setInputValue] = useState('');

    const isSoldOut = options.every(option => !option.product_instock);
    const isWorldWide = isNil(value?.country_id) === null;

    return (
        <div className="custom-coutry-dropdown">
            <FormControl fullWidth>
                <FormLabel focused={false}>Select location</FormLabel>
                <Autocomplete
                    disabled={disabled || isSoldOut}
                    value={isSoldOut ? null : value}
                    onChange={handleChange}
                    inputValue={inputValue}
                    readOnly={isWorldWide}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={options}
                    autoHighlight
                    popupIcon={<Chevron />}
                    isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                    getOptionLabel={option => (isWorldWide ? 'Worldwide Mix' : option.label)}
                    getOptionDisabled={option => !option.product_instock}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{
                                '& > .country-icon': { mr: 2, flexShrink: 0, width: 24 },
                                fontWeight: 500,
                            }}
                            {...props}
                        >
                            {option.country_id ? (
                                <Flag country_id={option.country_id} />
                            ) : (
                                <Globe className="country-icon" />
                            )}
                            <span style={{ marginRight: 'auto' }}>{isWorldWide ? 'Worldwide Mix' : option.label}</span>
                            <span className="custom-coutry-dropdown__status">
                                {option.product_instock ? `${option.product_stock} available` : OOS}
                            </span>
                        </Box>
                    )}
                    renderInput={params => {
                        if (isSoldOut) {
                            return <TextField {...params} placeholder="Sold out" />;
                        }
                        const { product_instock, product_stock, code } = getValues(params.inputProps.value, options);
                        const displayValue = isWorldWide ? 'Worldwide Mix' : params.inputProps.value;

                        return (
                            <div
                                className={`custom-coutry-dropdown-container ${code || isWorldWide ? 'has-value' : ''}`}
                            >
                                {code ? <Flag country_id={code} /> : isWorldWide && <Globe className="country-icon" />}
                                {product_instock && (
                                    <span className="product-stock">
                                        <span>{product_stock}</span>
                                        <span> available</span>
                                    </span>
                                )}
                                {isWorldWide && (
                                    <span className="tooltip">
                                        <TooltipComponent
                                            placement="bottom"
                                            text="Worldwide proxies are delivered at random from our available pool and there is no option to include or exclude specific countries."
                                        />
                                    </span>
                                )}
                                <TextField
                                    {...params}
                                    value={displayValue}
                                    placeholder="Choose country"
                                    inputProps={{
                                        ...params.inputProps,
                                        value: displayValue,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            </div>
                        );
                    }}
                />
            </FormControl>
        </div>
    );
};

export default CountryDropdown;
