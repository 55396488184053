import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ApiRequestChart from 'components/ApiRequestChart';
import Chart from 'components/_common/Chart';
import Filters from './Filters';

import {
    fetchApiRequestsAnalytict,
    selectApiRequestsAnalytictChartData,
    selectApiRequestsAnalytictLoading,
    selectApiRequestsAnalytictMetrics,
} from 'store/slices/apiRequests';

import { convertRequestPresetToInterval } from 'helpers';
import { REQUEST_PRESET_VALUES } from 'helpers/constants';

const RequestsChart = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectApiRequestsAnalytictLoading);
    const metrics = useSelector(selectApiRequestsAnalytictMetrics);
    const { labels, errors, requests, successful } = useSelector(selectApiRequestsAnalytictChartData);

    const [preset, setPreset] = useState(REQUEST_PRESET_VALUES.LAST_DAY);

    const chartData = useMemo(() => {
        return [
            {
                data: errors,
                label: 'errors',
                area: true,
                showMark: false,
                color: '#ff6767',
            },
            {
                data: requests,
                label: 'requests',
                area: true,
                showMark: false,
                color: '#8b8bff',
            },
            {
                data: successful,
                label: 'successful',
                area: true,
                showMark: false,
                color: '#93ff93',
            },
        ].sort((a, b) => Math.max(...b.data) - Math.max(...a.data));
    }, [errors, requests, successful]);

    const formatXAxisLabel = useCallback(
        timestamp => {
            const interval = convertRequestPresetToInterval(preset);

            const formatStorage = {
                minute: 'HH:mm',
                hour: 'HH:mm',
                day: 'MM-DD',
                month: 'MM-DD',
            };

            return dayjs
                .utc(timestamp)
                .local()
                .format(formatStorage[interval] || 'YYYY-MM-DD HH:mm');
        },
        [preset],
    );

    useEffect(() => {
        dispatch(fetchApiRequestsAnalytict({ preset }));
    }, [preset, dispatch]);

    return (
        <ApiRequestChart
            title="API Analytics"
            filters={<Filters preset={preset} submit={setPreset} />}
            metricsData={metrics}
            chart={
                <Chart
                    xLabels={labels}
                    data={chartData}
                    loading={loading}
                    seriesConfig={{ stack: undefined, areaColor: undefined }}
                    xAxisConfig={{
                        valueFormatter: formatXAxisLabel,
                    }}
                    disableAxisOnClick
                />
            }
        />
    );
};

export default RequestsChart;
