import React from 'react';
import { keyframes } from '@emotion/react';
import Box from '@mui/material/Box';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ButtonSpinner = ({ size = '1.5rem' }) => {
    return (
        <Box
            component="span"
            sx={{
                animation: `${spin} 1s linear infinite`,
                display: 'inline-block',
                width: size,
                height: size,
                border: '3px solid rgba(0, 0, 0, 0.2)',
                borderTop: '3px solid var(--ping-Grey-grey-700)',
                borderRadius: '50%',
            }}
        />
    );
};

export default ButtonSpinner;
