import { useEffect } from 'react';

const GoogleAnalytics = () => {
    useEffect(() => {
        const gaScript = document.createElement('script');

        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TAG}`;
        gaScript.async = true;

        const gaInitScript = document.createElement('script');

        gaInitScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GA_TAG}');

            window.gtag = gtag;
        `;
        gaInitScript.async = true;

        document.head.append(gaScript, gaInitScript);

        return () => {
            document.head.removeChild(gaInitScript);
        };
    }, []);

    return null;
};

export default GoogleAnalytics;
