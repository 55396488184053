const tab = {
    styleOverrides: {
        root: {
            minHeight: '36px',
            padding: '0',
            minWidth: 'auto',
            textTransform: 'inherit',
            fontSize: 'inherit',
            letterSpacing: 0,
        },
    },
};

export default tab;
