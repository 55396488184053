import dayjs from 'dayjs';
import _ from 'lodash';
import { GB, REQUEST_DATE_FORMAT, REQUEST_PRESET_VALUES } from './constants';

export const addZeroes = num => num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });

export function deepEqual(x, y) {
    return x && y && typeof x === 'object' && typeof y === 'object'
        ? Object.keys(x).length === Object.keys(y).length &&
              Object.keys(x).reduce(function (isEqual, key) {
                  return isEqual && deepEqual(x[key], y[key]);
              }, true)
        : x === y;
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertBandwidth(value, toUnit = GB, fromUnit = 'KB', fixed = 2) {
    const units = {
        b: 1,
        byte: 1,
        kb: Math.pow(2, 10),
        kilobyte: Math.pow(2, 10),
        mb: Math.pow(2, 20),
        megabyte: Math.pow(2, 20),
        gb: Math.pow(2, 30),
        gigabyte: Math.pow(2, 30),
        tb: Math.pow(2, 40),
        terabyte: Math.pow(2, 40),
    };

    fromUnit = fromUnit.toLowerCase();
    toUnit = toUnit.toLowerCase();

    if (!units.hasOwnProperty(fromUnit)) {
        throw new Error(
            "Invalid 'from' unit. Supported units are b, B, KB, MB, GB, TB, kilobyte, megabyte, gigabyte, terabyte.",
        );
    }
    if (!units.hasOwnProperty(toUnit)) {
        throw new Error(
            "Invalid 'to' unit. Supported units are b, B, KB, MB, GB, TB, kilobyte, megabyte, gigabyte, terabyte.",
        );
    }

    const bytesValue = value * units[fromUnit];

    const convertedValue = bytesValue / units[toUnit];

    return convertedValue.toFixed(fixed);
}

export const isObjectEmpty = objectName => {
    return _.isEmpty(objectName);
};

export const convertDate = date => dayjs.utc(date).format(REQUEST_DATE_FORMAT);

export const calculateInterval = (startDateStr, endDateStr) => {
    const diff = Math.abs(dayjs(startDateStr).diff(dayjs(endDateStr), 'hours'));

    if (diff <= 1) {
        // Less than one hour
        return 'minute';
    } else if (diff <= 24) {
        // Less than one day
        return 'hour';
    } else if (diff <= 24 * 7) {
        // Less than one week
        return 'day';
    } else if (diff <= 24 * 365) {
        // less than one year
        return 'month';
    } else {
        return 'Interval not defined for the given range';
    }
};

// Convert date picker preset to api-friendly string
export const convertDatePreset = datePickerPreset => {
    const mapping = {
        Today: {
            period_start: convertDate(dayjs().set('hours', 0).set('minutes', 0)),
            period_end: convertDate(dayjs()),
            interval: 'hour',
        },
        'Month To Date': {
            period_start: convertDate(dayjs().startOf('month')),
            period_end: convertDate(dayjs()),
            interval: 'day',
        },
        'Last 24 Hours': { preset: REQUEST_PRESET_VALUES.LAST_DAY },
        'Last 7 Days': { preset: REQUEST_PRESET_VALUES.LAST_WEEK },
        'Last 30 Days': { preset: REQUEST_PRESET_VALUES.LAST_30_DAYS },
        'Last 365 Days': { preset: REQUEST_PRESET_VALUES.LAST_YEAR },
    };

    const result = mapping[datePickerPreset];

    if (!result) {
        return null;
    }

    return result;
};

export const convertRequestPresetToInterval = preset => {
    switch (preset) {
        case REQUEST_PRESET_VALUES.LAST_HOUR:
            return 'minute';
        case REQUEST_PRESET_VALUES.LAST_DAY:
            return 'hour';
        case REQUEST_PRESET_VALUES.LAST_WEEK:
        case REQUEST_PRESET_VALUES.LAST_30_DAYS:
            return 'day';
        case REQUEST_PRESET_VALUES.LAST_60_DAYS:
        case REQUEST_PRESET_VALUES.LAST_90_DAYS:
        case REQUEST_PRESET_VALUES.LAST_YEAR:
            return 'month';
        default:
            return 'Interval not defined for the given range';
    }
};

export const getDateParamsFromDatepicker = value => {
    const { start, end, preset } = value;

    const params = {};

    if (preset) {
        Object.assign(params, preset);
    } else {
        const period_start = convertDate(start);
        const period_end = convertDate(end);
        const interval = calculateInterval(period_start, period_end);

        const condition =
            dayjs(period_start).isValid() &&
            dayjs(period_end).isValid() &&
            dayjs(period_start).isBefore(period_end) &&
            dayjs(end).subtract(1, 'hour').isAfter(start);

        if (!condition) return null;

        Object.assign(params, { period_start, period_end, interval });
    }

    return params;
};

export const uppercaseFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

export const getUnique = data => data.filter((value, index, array) => array.indexOf(value) === index);

export const formatPeriod = period => {
    const data = {
        '1:year': '12 Months',
        '1:month': '1 Month',
        '3:month': '3 months',
    };
    return data[period];
};

export const getChangedKeys = (o1, o2) => {
    const keys = _.union(_.keys(o1), _.keys(o2));
    return _.filter(keys, key => o1[key] !== o2[key]);
};

export const getChangedParams = (initial, result) =>
    getChangedKeys(initial, result).reduce((acc, key) => ({ ...acc, [key]: result[key] }), {});

export function withoutEmptyValues(obj) {
    return _.pickBy(obj, value => {
        return value !== '' && !_.isNull(value) && !_.isUndefined(value);
    });
}

export const calculateDiscountPercentage = (beforeDiscountTotal, discount) => {
    if (beforeDiscountTotal === 0) return 0;
    return (discount / beforeDiscountTotal) * 100;
};

export const sortNewFirst = data => data.toSorted((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));

export const metricTypes = {
    bytes: 'bytes',
    megabytes: 'megabytes',
    gigabytes: 'gigabytes',
    requests: 'requests',
    errorRate: 'errorRate',
};

export const metricSelect = [
    { value: metricTypes.bytes, label: 'Bytes' },
    { value: metricTypes.megabytes, label: 'Megabytes' },
    { value: metricTypes.gigabytes, label: 'Gigabytes' },
    { value: metricTypes.requests, label: 'Requests' },
];

export const getOrdinalSuffix = day => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const truncateEmail = (email, { maxLength = 20, offset = 8 } = { maxLength: 20, offset: 8 }) => {
    if (!email || email.length <= maxLength) return email;

    return `${email.slice(0, offset)}...${email.slice(email.length - offset, -1)}`;
};

export const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 30) + 70;
    const lightness = Math.floor(Math.random() * 20) + 40;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const parseBooleanSearchParameter = (value, defaultValue = false) => {
    if (!value) return defaultValue;

    if (value === 'true' || value === 'false') {
        return value === 'true';
    } else if (!isNaN(+value)) {
        return +value === 1;
    } else {
        return defaultValue;
    }
};
