import './styles.scss';

import React, { forwardRef, useRef } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';

import { ReactComponent as SearchIcon } from 'icons/search-outlined.svg';

const PopperComponent = forwardRef(
    (
        {
            // Remove all unnecessary popper props
            className,
            disablePortal,
            open,
            anchorEl,
            slots,
            style,
            slotProps,
            popperRef,
            popperOptions,
            keepMounted,
            ...props
        },
        ref,
    ) => {
        return <Box className={'autocomplete-popper ' + className} {...props} ref={ref} />;
    },
);

const SearchableDropdownList = ({ id, open, anchorEl, onClose, setValue, ...props }) => {
    const popperRef = useRef();

    const onClickAway = e => {
        if (!props.disableCloseOnSelect || !popperRef.current) {
            return onClose();
        }

        const isPopperClick = popperRef.current.contains(e.target);

        if (isPopperClick) {
            return;
        } else {
            onClose();
        }
    };

    return (
        <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="dropdown-root"
            ref={popperRef}
            sx={{ maxWidth: anchorEl?.offsetWidth }}
        >
            <ClickAwayListener onClickAway={onClickAway}>
                <Autocomplete
                    open
                    disablePortal
                    onClose={(_, reason) => {
                        if (reason === 'escape') {
                            onClose();
                        }
                    }}
                    onChange={(_, value) => {
                        setValue(value);
                        if (value && !props.disableCloseOnSelect) {
                            onClose();
                        }
                    }}
                    renderInput={params => (
                        <Box className="autocomplete-input-wrapper">
                            <InputBase
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search"
                                className="autocomplete-input"
                                startAdornment={<SearchIcon width={20} height={20} />}
                            />
                        </Box>
                    )}
                    slotProps={{
                        popper: {
                            component: PopperComponent,
                        },
                    }}
                    getOptionLabel={option => option.label}
                    {...props}
                />
            </ClickAwayListener>
        </Popper>
    );
};

export default SearchableDropdownList;
