import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import Tab from '@mui/material/Tab';

import { billingItems } from 'helpers/constants';

const BillingTabs = ({ children = null }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const getIndex = () => String(billingItems.findIndex(item => item.to === location.pathname));
    const [value, setValue] = useState(getIndex());

    const handleChange = (event, index) => {
        setValue(index);
        navigate(billingItems[index].to);
    };

    return (
        <div className="custom-tabs">
            <TabContext value={value}>
                <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
                    {billingItems.map(({ name }, index) => (
                        <Tab label={name} value={String(index)} key={name} />
                    ))}
                </TabList>
            </TabContext>
            {children ? <div className="child">{children}</div> : null}
        </div>
    );
};

export default BillingTabs;
