import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResidentalProxiesTab from './ActiveServices/Tabs/ResidentalProxiesTab';
import StatisticBar from './StatisticBar';
import DashboardLayout from './Layout';

import ProductCards from 'components/ProductCards';
import Spinner from 'components/_common/Spinner';
import ModalInvoiceDetails from 'components/Modals/ModalInvoiceDetails';

import { customerRetrieve, selectActiveResidentialServiceId } from 'store/slices/customer';
import { selectProductsLoading } from 'store/slices/products';
import ServicesService from 'api/services/ServicesService';

import { fetchOpenedBillingInvoice } from 'store/slices/billingInvoices';

const Dashboard = () => {
    const dispatch = useDispatch();
    const productsLoading = useSelector(selectProductsLoading);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const [isActiveServiceLoading, setIsActiveServiceLoading] = useState(productsLoading);
    const [residentialServiceData, setResidentialServiceData] = useState(null);
    const isLoading = productsLoading || isActiveServiceLoading;

    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
    const [page, setPage] = useState(1);

    const checkInvoice = async (p = page) => {
        if (p > 10) {
            return;
        }
        const invoice = await fetchOpenedBillingInvoice(p);
        const shownInvoices = localStorage.getItem('shownInvoicesIds');
        if (invoice.data?.length) {
            let list = [];
            try {
                list = JSON.parse(shownInvoices) || [];
            } catch (e) {}

            const item = invoice.data[0];

            if (list.includes(item.invoice_id)) {
                setPage(p + 1);
                return checkInvoice(p + 1);
            } else {
                setSelectedInvoice(item);
                setOpenInvoiceDetails(true);
                list.push(item.invoice_id);
                localStorage.setItem('shownInvoicesIds', JSON.stringify(list));
            }
        }
    };

    useEffect(() => {
        dispatch(customerRetrieve());
        if (active_residential_service_id) {
            retrieveResidentialService(active_residential_service_id);
        }
    }, []);

    useEffect(() => {
        if (!openInvoiceDetails) {
            checkInvoice();
        }
    }, [openInvoiceDetails]);

    const retrieveResidentialService = async id => {
        try {
            setIsActiveServiceLoading(true);
            const response = await ServicesService.retrieve(id);
            setIsActiveServiceLoading(false);
            setResidentialServiceData(response?.data?.data);
        } catch (error) {
            return false;
        }
    };

    return (
        <DashboardLayout
            top={<StatisticBar />}
            services={
                isLoading && active_residential_service_id ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 252 }}>
                        <Spinner />
                    </div>
                ) : (
                    <ProductCards
                        residentialServiceData={residentialServiceData}
                        retrieveResidentialService={retrieveResidentialService}
                        small={true}
                    />
                )
            }
        >
            <div className="dashboard-active-services">
                <ResidentalProxiesTab />
            </div>
            <ModalInvoiceDetails data={selectedInvoice} setOpen={setOpenInvoiceDetails} isOpen={openInvoiceDetails} />
        </DashboardLayout>
    );
};

export default Dashboard;
