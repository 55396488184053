import './styles.scss';

import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';

import { GENERAL_DOCUMENTATION_LINK } from 'helpers/constants';

import { ReactComponent as Icon } from 'icons/colored/info-banner.svg';

const InfoBanner = ({
    header = 'Getting started with generating proxies using the API',
    content = 'With just a few simple steps, you can start generating proxies for your specific needs.',
    marginTop = 24,
    marginBottom = 0,
    article = '',
    variant = 'default',
}) => {
    return (
        <div className={`info-banner ${variant}`} style={{ marginTop, marginBottom }}>
            <Icon />

            <div className="info-banner__description">
                <h5>{header}</h5>
                <p className="body2">{content}</p>
            </div>

            <Chip
                label="Read documentation"
                component={Link}
                clickable
                href={article ?? GENERAL_DOCUMENTATION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                color="default"
                size="medium"
            />
        </div>
    );
};

export default InfoBanner;
