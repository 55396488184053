import React, { useCallback, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';

import Tabs from 'components/_common/Tabs';
import ReconfigureTab from './ReconfigureTab';

const ScheduleTabs = ({ data }) => {
    const tabsData = [{ value: '1', label: 'Immediate', content: <ReconfigureTab data={data} /> }];

    const [activeTab, setActiveTab] = useState(tabsData[0].value);

    const onChange = useCallback((_, newValue) => setActiveTab(newValue), [setActiveTab]);

    return (
        <>
            <FormLabel focused={false}>Schedule</FormLabel>
            <Tabs tabs={tabsData} value={activeTab} variant="fullWidth" onChange={onChange} />
        </>
    );
};

export default ScheduleTabs;
