import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import Select from 'components/_common/Select/SimpleSelect';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import CopyButton from 'components/Buttons/CopyButton';
import Spinner from 'components/_common/Spinner';
import ProxyUserDropdown from 'components/ProxyUserDropdown';

import ProxyService from 'api/services/ProxyService';

import { exportProxyAuthTypes, exportProxyAuthTypesOptions, proxyFormatOptions } from 'helpers/constants';
import { withoutEmptyValues } from 'helpers';

const list_protocol_options = [
    { value: 'http', label: 'HTTP' },
    { value: 'socks5', label: 'SOCKS5' },
];

const form_keys = {
    PROXIES: 'proxies',
    USER: 'user',
    AUTH_TYPE: 'list_authentication',
    LIST_PROTOCOL: 'list_protocol',
    LIST_FORMAT: 'list_format',
};

const QuickView = ({ isQuickViewModalOpen, setIsQuickViewModalOpen, service }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({
        [form_keys.USER]: null,
        [form_keys.AUTH_TYPE]: exportProxyAuthTypes.PROXY_USER_AUTH,
        [form_keys.LIST_PROTOCOL]: list_protocol_options[0].value,
        [form_keys.LIST_FORMAT]: proxyFormatOptions[0].value,
        [form_keys.PROXIES]: [],
    });

    const handleChange = useCallback(
        key => {
            return value => {
                switch (key) {
                    case form_keys.LIST_PROTOCOL: {
                        if (!value) {
                            return;
                        }

                        return setParams(p => {
                            const currentFormat = p[form_keys.LIST_FORMAT] ?? '';

                            const standardOption = proxyFormatOptions.at(0).value;

                            if (!currentFormat || currentFormat.includes(value) || currentFormat === standardOption) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case list_protocol_options[0].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_FORMAT]: proxyFormatOptions[1].value,
                                    };
                                // socks case
                                case list_protocol_options[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_FORMAT]: proxyFormatOptions[2].value,
                                    };
                                default:
                                    return { ...p, [key]: value };
                            }
                        });
                    }
                    case form_keys.LIST_FORMAT: {
                        return setParams(p => {
                            const standardOption = proxyFormatOptions.at(0).value;

                            if (value === standardOption) {
                                return { ...p, [key]: value };
                            }

                            const currentProtocol = p[form_keys.LIST_PROTOCOL];

                            if (value.includes(currentProtocol)) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case proxyFormatOptions[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_PROTOCOL]: list_protocol_options[0].value,
                                    };
                                // socks case
                                case proxyFormatOptions[2].value:
                                case proxyFormatOptions[3].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_PROTOCOL]: list_protocol_options[1].value,
                                    };

                                default: {
                                    return { ...p, [key]: value };
                                }
                            }
                        });
                    }

                    default:
                        setParams(p => ({ ...p, [key]: value }));
                }
            };
        },
        [setParams],
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await ProxyService.search({ service_id: service.id, page: 1, per_page: 1000 });
                const proxies = response.data.data;

                handleChange(form_keys.PROXIES)(proxies);
            } catch (e) {
                return e;
            }
        })();
    }, [service]);

    useEffect(() => {
        if (params.proxies.length < 1) return;

        setLoading(true);

        const authTypeParamsMapping = {
            [exportProxyAuthTypes.PROXY_USER_AUTH]: params.user
                ? { list_authentication: 'username_and_password', proxy_user_id: params.user.value }
                : { list_authentication: null },
            [exportProxyAuthTypes.IP_AUTH]: {
                list_authentication: 'ip_address',
            },
            [exportProxyAuthTypes.PROXY_SPECIFIC_AUTH]: {
                list_authentication: 'proxy_specific',
            },
        };

        const formattedParams = {
            ...params,
            ...authTypeParamsMapping[params[form_keys.AUTH_TYPE]],
            proxies: params.proxies.map(proxy => proxy.proxy_id),
        };

        delete formattedParams.user;

        ProxyService.list_by_search(withoutEmptyValues(formattedParams))
            .then(response => {
                setData(response.data.data);
            })
            .catch(() => setData([]))
            .finally(() => {
                setLoading(false);
            });
    }, [params]);

    return (
        <ControledDialog
            setOpen={setIsQuickViewModalOpen}
            open={isQuickViewModalOpen}
            withCancel={false}
            title="Proxy List"
        >
            <div className="modal-container-wrapper">
                <ServiceCell data={service.service} />

                <div className="margin-top-20">
                    <h6>Proxy protocol:</h6>
                    <RadioGroupCards
                        items={list_protocol_options}
                        value={params[form_keys.LIST_PROTOCOL]}
                        onChange={handleChange(form_keys.LIST_PROTOCOL)}
                        horizontal={true}
                    />
                </div>
                <div className="margin-top-20 authentication-wrapper">
                    <h6>Authentication:</h6>
                    <div className="margin-top-8">
                        <Select
                            fullWidth
                            label="Authentication Type"
                            options={exportProxyAuthTypesOptions}
                            value={params[form_keys.AUTH_TYPE]}
                            onChange={handleChange(form_keys.AUTH_TYPE)}
                        />
                    </div>
                    {params[form_keys.AUTH_TYPE] === exportProxyAuthTypes.PROXY_USER_AUTH && (
                        <div className="margin-top-8">
                            <ProxyUserDropdown onChange={handleChange(form_keys.USER)} value={params[form_keys.USER]} />
                        </div>
                    )}
                    <Select
                        label="Format"
                        onChange={handleChange(form_keys.LIST_FORMAT)}
                        options={proxyFormatOptions}
                        value={params[form_keys.LIST_FORMAT]}
                        fullWidth
                        withFormLabel
                        defaultPlaceholder="Select Format"
                    />
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                ) : Boolean(data?.length) ? (
                    <div className="spinner-container">
                        <div className="scrollable-container">
                            <Typography>
                                {data.map((i, index) => (
                                    <span key={`${index}-${i}`}>
                                        {i}
                                        <br />
                                    </span>
                                ))}
                            </Typography>
                        </div>
                        <div className="copy-button-container">
                            <CopyButton copyValue={data.join('\n')} customText="Copy To Clipboard" fullWidth={true} />
                        </div>
                    </div>
                ) : (
                    <div className="spinner-container centered">Data not found</div>
                )}
            </div>
        </ControledDialog>
    );
};

export default QuickView;
