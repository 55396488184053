// @ts-nocheck
import React, { forwardRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';

import './styles.scss';

const PopupContent = forwardRef(({ title = '', description, children, ...props }, ref) => {
    return (
        <Stack className="popup-content">
            {title ? <DialogTitle className="popup-content-title">{title}</DialogTitle> : null}
            <DialogContent {...props} ref={ref}>
                {description ? (
                    <DialogContentText className="popup-content-description">{description}</DialogContentText>
                ) : null}
                {children}
            </DialogContent>
        </Stack>
    );
});

PopupContent.displayName = 'PopupContent';
PopupContent.muiName = 'Stack';

export default PopupContent;
