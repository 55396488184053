import '../styles.scss';

import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';

import { convertBandwidth } from 'helpers';
import { GB, MB } from 'helpers/constants';

const getColor = percentage => {
    if (percentage >= 70) {
        return 'error';
    }

    if (percentage >= 50) {
        return 'warning';
    }

    return 'success';
};

const calcualatePercentage = (value, limit) => {
    return (value * 100) / limit;
};

const ProxyUserBandwidthUsageCell = ({ value, limit }) => {
    const percentage = limit > 0 ? calcualatePercentage(value, limit) : 0;
    const color = limit > 0 ? getColor(percentage) : 'default';

    const isMB = limit < 100000000 && limit !== null;
    const isLimitSmall = limit < 1000000 && limit !== null;

    if (!limit && limit !== null) {
        return <Chip label="No Residential Access" color="error" />;
    }

    return (
        <div className="log-chart-cell">
            <div className={limit === null || isLimitSmall ? 'usage-no-limit' : 'usage'}>
                {isLimitSmall ? (
                    <div className="usage__value">0</div>
                ) : (
                    <>
                        <div className="usage__value">
                            {convertBandwidth(value, isMB ? MB : GB, 'byte', 2)} {isMB ? MB : GB}
                        </div>
                        <span className="usage__divider">/</span>
                        <div className="usage__limit">
                            {limit === null ? (
                                <span>∞</span>
                            ) : (
                                `${convertBandwidth(limit, isMB ? MB : GB, 'byte', 2)} ${isMB ? MB : GB}`
                            )}
                        </div>
                    </>
                )}
            </div>
            {!isLimitSmall && limit !== null && (
                <LinearProgress
                    value={percentage}
                    color={color}
                    variant="determinate"
                    sx={{ backgroundColor: 'grey.200' }}
                />
            )}
        </div>
    );
};

export default ProxyUserBandwidthUsageCell;
