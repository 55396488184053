import React from 'react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';

import { form_keys } from './constants';
import { AI_FEATURE_ENABLED } from 'helpers/constants';

import ProxyUsersDropdown from 'components/ProxyUserDropdown';
import TextInput from 'components/_common/TextInput';

import { ReactComponent as XIcon } from 'icons/x-close.svg';
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg';
import { ReactComponent as StarsIcon } from 'icons/colored/stars-gradient-filled.svg';

const NetworkSettings = ({ params, onChange, loading }) => {
    return (
        <>
            <ProxyUsersDropdown onChange={onChange(form_keys.PROXY_USER_ID)} value={params[form_keys.PROXY_USER_ID]} />
            <TextInput
                label="Quantity"
                value={params[form_keys.QUANTITY]}
                onChange={onChange(form_keys.QUANTITY, val => {
                    if (val === '') {
                        return 1;
                    } else {
                        return val > 10000 || val < 1 ? null : val;
                    }
                })}
                placeholder="1-10000"
                type="number"
                min={1}
                max={10000}
            />
            {AI_FEATURE_ENABLED ? (
                <FormControl fullWidth>
                    <FormLabel
                        id="ai-optimization"
                        sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}
                    >
                        <StarsIcon width={16} height={16} style={{ marginRight: '-4px' }} />
                        AI Optimization <Chip label="Beta" sx={{ fontWeight: 700 }} />
                    </FormLabel>
                    <ToggleButtonGroup
                        className="proxies-form-block-toggle-group"
                        exclusive
                        value={params[form_keys.AI_ENABLED]}
                        onChange={onChange(form_keys.AI_ENABLED, (_, value) => value)}
                        aria-labelledby="ai-optimization"
                        fullWidth
                    >
                        <ToggleButton
                            value={false}
                            aria-label="Disable AI Optimization"
                            fullWidth
                            className="toggle-group-option ai-optimization"
                        >
                            <XIcon width={16} height={16} />
                            Disable
                        </ToggleButton>
                        <ToggleButton
                            value={true}
                            aria-label="Enable AI Optimization"
                            fullWidth
                            className="toggle-group-option ai-optimization"
                        >
                            <CheckIcon width={16} height={16} /> Enable
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormControl>
            ) : null}
            <Button
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
                disableElevation
                size="large"
                sx={{ backgroundColor: 'primary.500', marginTop: '16px' }}
            >
                Generate
            </Button>
        </>
    );
};

export default NetworkSettings;
