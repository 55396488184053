// @ts-nocheck
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { fetchCountries, selectCountriesOptions, selectPopularCountriesOptions } from 'store/slices/customer';
import { POPULAR_COUNTRIES_IDS } from 'helpers/constants';

import Dropdown from './Dropdown';

const CountriesDropdown = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectCountriesOptions);
    const extraOptions = useSelector(selectPopularCountriesOptions);

    useEffect(() => {
        dispatch(fetchCountries({ per_page: 500 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Sort countries alphabetically including extra options and popular countries on top of the list
    const sortedByPopularity = useMemo(() => {
        const popularCountries = new Set(POPULAR_COUNTRIES_IDS.concat(extraOptions.map(country => country.value)));

        return [{ value: 'All', label: 'Worldwide' }].concat(
            options.toSorted((a, b) => {
                if (popularCountries.has(a.value)) {
                    return -1;
                } else {
                    return a.label.localeCompare(b.label);
                }
            }),
        );
    }, [options, extraOptions]);

    return (
        <FormControl fullWidth>
            <FormLabel focused={false}>Country</FormLabel>
            <Dropdown options={sortedByPopularity} onChange={onChange} value={value} />
        </FormControl>
    );
};

export default CountriesDropdown;
