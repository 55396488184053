// @ts-nocheck

const colors = ['primary', 'success', 'error', 'warning'];

const getFilledChipStyle = (color, palette, { color: colorVariant, border }) => {
    return {
        backgroundColor: palette[color][50],
        color: palette[color][colorVariant],
        border: `1px solid ${palette[color][border]}14`,
    };
};

const getClickableChipStyle = (color, palette) => ({
    color: palette[color][800],
    border: `1px solid ${palette[color][400]}`,
});

const chip = {
    defaultProps: {
        component: 'span',
        variant: 'filled',
        color: 'primary',
        className: 'body2',
        size: 'small',
    },
    styleOverrides: { root: { padding: '6px 12px' }, label: { padding: 0 }, icon: { marginRight: '2px' } },
    variants: [
        { props: { size: 'small' }, style: { padding: '2px 6px', fontSize: '12px' } },
        {
            props: { clickable: false },
            style: {
                borderRadius: '24px',
            },
        },
        {
            props: { clickable: true },
            style: { borderRadius: '4px' },
        },
        {
            props: { variant: 'filled', color: 'primary' },
            style: ({ theme: { palette } }) => getFilledChipStyle('primary', palette, { color: 500, border: 'main' }),
        },
        {
            props: { variant: 'filled', color: 'success' },
            style: ({ theme: { palette } }) => getFilledChipStyle('success', palette, { color: 600, border: '600' }),
        },
        {
            props: { variant: 'filled', color: 'error' },
            style: ({ theme: { palette } }) => getFilledChipStyle('error', palette, { color: 500, border: 'main' }),
        },
        {
            props: { variant: 'filled', color: 'warning' },
            style: ({ theme: { palette } }) => getFilledChipStyle('warning', palette, { color: 500, border: 'main' }),
        },
        {
            props: { variant: 'filled', color: 'default' },
            style: ({ theme: { palette } }) => ({
                backgroundColor: palette.grey[50],
                color: palette.common.black,
                border: `1px solid ${palette.grey.main}14`,
                fontWeight: 400,
            }),
        },
        ...colors.map(color => ({
            props: { clickable: true, variant: 'filled', color },
            style: ({ theme: { palette } }) => getClickableChipStyle(color, palette),
        })),
        {
            props: { clickable: true, variant: 'filled', color: 'default' },
            style: ({ theme: { palette } }) => getClickableChipStyle('grey', palette),
        },
        {
            props: { clickable: true, variant: 'filled' },
            style: ({ theme: { palette } }) => ({
                ':hover': {
                    color: palette.common.white,
                },
                ':active': {
                    color: palette.common.white,
                },
                ':focus': {
                    color: palette.common.white,
                },
            }),
        },
    ],
};

export default chip;
