import Button from 'components/_common/Button';
import Table from 'components/_common/Table';
import PaymentCellByID from 'components/_common/TableCells/PaymentCellByID';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import StatusCell from 'components/_common/TableCells/StatusCell';
import TextCell from 'components/_common/TableCells/TextCell';
import ModalPay from '../../../components/Modals/ModalPay';
import ROUTES from 'helpers/routes';
import React, { useState } from 'react';

const InvoicesTable = ({ data, dataLoading, fetchData, total, sortModel, noDataMessage }) => {
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');

    const handlePay = invoiceId => {
        if (!invoiceId) return;

        setInvoiceId(invoiceId);
        setIsPayModalOpen(true);
    };

    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'invoice_id',
                headerName: 'Invoice ID',
                flex: 1,
                minWidth: 300,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'service',
                headerName: 'Service',
                flex: 1,
                minWidth: 350,
                renderCell: ({ value }) => <ServiceCell data={value} />,
            },
            {
                field: 'invoice_creation_datetime',
                headerName: 'Created',
                flex: 1,
                minWidth: 250,
                sortable: true,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'payment_card',
                headerName: 'Payment Method',
                flex: 1,
                minWidth: 160,
                renderCell: ({ value }) => <PaymentCellByID id={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 170,
                renderCell: ({ row }) => (
                    <div className="invoices-table-buttons-wrapper">
                        {row.status === 'open' && (
                            <Button onClick={() => handlePay(row.invoice_id)} variant="outlined">
                                Pay
                            </Button>
                        )}
                        <Button to={ROUTES.billingInvoices + '/' + row.id} variant="outlined">
                            View
                        </Button>
                    </div>
                ),
            },
        ],
        [],
    );

    return (
        <>
            <Table
                columns={columns}
                rows={data}
                rowHeight={52}
                dataLoading={dataLoading}
                fetchData={fetchData}
                total={total}
                sortModel={sortModel}
                noDataMessage={noDataMessage}
            />
            {isPayModalOpen && (
                <ModalPay isPayModalOpen={isPayModalOpen} setIsPayModalOpen={setIsPayModalOpen} invoiceId={invoiceId} />
            )}
        </>
    );
};

export default InvoicesTable;
