import React from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { ReactComponent as Chevron } from 'icons/chevron-down.svg';

const DefaultLabel = ({ isEmpty, value, placeholder }) => {
    return isEmpty ? (
        <Typography component="span">{placeholder}</Typography>
    ) : (
        <Typography component="span" className="searchable-dropdown__label">
            {Array.isArray(value) ? value.map(item => item.label).join(', ') : value.label}
        </Typography>
    );
};

const DropdownSelectButton = ({ value, id, onClick, className, placeholder, renderLabel, ...props }) => {
    const isEmpty = Array.isArray(value) ? value.length === 0 : !value;

    return (
        <ButtonBase
            className={`searchable-dropdown__button ${isEmpty ? 'placeholder' : ''} ${className ?? ''}`}
            disableRipple
            aria-describedby={id}
            onClick={onClick}
            {...props}
        >
            <Box component="span" className="searchable-dropdown__label-container">
                {renderLabel && typeof renderLabel === 'function' ? (
                    renderLabel({ value, placeholder, isEmpty })
                ) : (
                    <DefaultLabel value={value} placeholder={placeholder} isEmpty={isEmpty} />
                )}
            </Box>
            <Chevron width={20} height={20} />
        </ButtonBase>
    );
};

export default DropdownSelectButton;
