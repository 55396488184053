import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import NoData from './NoData';
import ProxyUserSettingsTable from './Table';
import ModalCreateProxyUser from 'components/Modals/ModalCreateProxyUser';
import SearchInput from 'components/_common/SearchInput';
import { Switch } from 'components/_common/Switch';
import FilterBar from 'components/FilterBar';
import InfoBanner from 'components/InfoBanner';
import PageHeader from 'components/PageHeader';

import {
    fetchProxyUsers,
    selectProxyUsersLoading,
    selectProxyUsersTableData,
    selectProxyUsersTotal,
} from 'store/slices/proxyUsers';
import { selectCustomerProxyUsersLimit } from 'store/slices/customer';

import { parseBooleanSearchParameter } from 'helpers';

const ProxyUserSettings = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const data = useSelector(selectProxyUsersTableData);
    const proxyUsersLimit = useSelector(selectCustomerProxyUsersLimit);
    const loading = useSelector(selectProxyUsersLoading);
    const total = useSelector(selectProxyUsersTotal);

    const [modalOpen, setModalOpen] = useState(false);

    const [filters, setFilters] = useState(() => {
        const proxy_user_is_deleted = parseBooleanSearchParameter(searchParams.get('proxy_user_is_deleted'), false);
        const search = searchParams.get('query');
        const pageAsNumber = +(searchParams.get('page') ?? 1);
        const perPageAsNumber = +(searchParams.get('per_page') ?? 10);

        return {
            proxy_user_is_deleted,
            page: isNaN(pageAsNumber) ? 1 : pageAsNumber > 0 ? pageAsNumber : 1,
            per_page: isNaN(perPageAsNumber) ? 10 : [10, 25, 50, 100].includes(perPageAsNumber) ? perPageAsNumber : 10,
            like_proxy_user_id: search ?? '',
        };
    });

    const updateFilter = key => {
        return value => {
            setFilters(prev => ({ ...prev, [key]: value }));
        };
    };

    useEffect(() => {
        const filtersCopy = { ...filters };

        if (filtersCopy.proxy_user_is_deleted) {
            delete filtersCopy.proxy_user_is_deleted;
        }

        setSearchParams(filters);
        dispatch(fetchProxyUsers(filtersCopy));
    }, [filters]);

    const refreshData = useCallback(() => {
        const filtersCopy = { ...filters };

        if (filtersCopy.proxy_user_is_deleted) {
            delete filtersCopy.proxy_user_is_deleted;
        }

        dispatch(fetchProxyUsers(filtersCopy));
    }, [filters]);

    const showCreateButton = !!data.length && !loading;

    return (
        <>
            <PageHeader
                title="Proxy User Settings"
                right={
                    showCreateButton && (
                        <ModalCreateProxyUser setOpen={setModalOpen} open={modalOpen} refreshData={refreshData} />
                    )
                }
            />
            <FilterBar
                left={
                    <>
                        <SearchInput
                            value={filters.like_proxy_user_id}
                            onChange={updateFilter('like_proxy_user_id')}
                            placeholder="Search by Proxy User ID"
                        />
                        <Switch
                            value={filters.proxy_user_is_deleted}
                            onChange={updateFilter('proxy_user_is_deleted')}
                            label="Show Deleted"
                            sx={{ marginLeft: 'auto' }}
                        />
                    </>
                }
                right={null}
            />
            <ProxyUserSettingsTable
                data={data}
                dataLoading={loading}
                setFilters={setFilters}
                refreshData={refreshData}
                filters={filters}
                total={total}
                noDataComponent={<NoData setModalOpen={setModalOpen} modalOpen={modalOpen} />}
            />
            <p className="text-grey-500 mt-8">{`Your Proxy User limit is ${proxyUsersLimit}. Please contact support if you would like to request an increase.`}</p>
            <InfoBanner
                marginBottom={24}
                marginTop={24}
                header="Get started with Proxy Authentication and Proxy Users"
                content="Read our detailed guide which lays out all our authentication types and information needed to set up Proxy Users'"
                article={process.env.REACT_APP_API_DOCUMENTATION_PROXY_USERS_LINK}
            />
        </>
    );
};

export default ProxyUserSettings;
