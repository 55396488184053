// @ts-nocheck
import React from 'react';

import PopupBadge, { BADGE_SIZE } from 'components/_common/PopupBadge';

import { ReactComponent as BillIcon } from 'images/popup/bill.svg';

import './styles.scss';

const BillPreview = ({ badgeProps }) => {
    return (
        <span className="bill-preview">
            <BillIcon width={136} height={180} className="bill-preview-bill" />
            <PopupBadge iconSize={24} size={BADGE_SIZE.md} {...badgeProps} />
        </span>
    );
};

export default BillPreview;
