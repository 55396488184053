import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

import ModalDeletePaymentCard from 'components/Modals/ModalDeletePaymentCard';
import Table from 'components/_common/Table';
import PaymentCell from 'components/_common/TableCells/PaymentCell';
import TextCell from 'components/_common/TableCells/TextCell';
import ModalEditPaymentCard from 'components/Modals/ModalEditPaymentCard';

import { ReactComponent as Icon2 } from 'icons/credit-card.svg';
import { ReactComponent as Trash } from 'icons/trash.svg';

const PaymentSettingsTable = ({ data, dataLoading, fetchData, staticFilters, total, noDataComponent }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [cardData, setCardData] = useState({});

    const columns = React.useMemo(
        () => [
            {
                field: 'cardholder',
                headerName: 'Cardholder',
                flex: 1,
                minWidth: 200,
                renderCell: ({ row }) => <TextCell text={row.name} />,
            },
            {
                field: 'payment_card',
                headerName: 'Payment Card',
                flex: 1,
                minWidth: 200,
                renderCell: ({ row }) => <PaymentCell type={row.card_type} num={row.last_four} />,
            },
            {
                field: 'expiry_date',
                headerName: 'Expiry Date',
                flex: 1,
                minWidth: 200,
                renderCell: ({ row }) => <TextCell text={row.expiry_month + '/' + row.expiry_year} />,
            },
            {
                field: 'type',
                headerName: 'Type',
                minWidth: 100,
                renderCell: ({ row }) =>
                    row.is_default ? <Chip label="Default" color="success" variant="filled" /> : '',
            },
            {
                field: 'actions',
                type: 'actions',
                width: 50,
                getActions: params => [
                    <GridActionsCellItem
                        showInMenu
                        icon={<Icon2 />}
                        label="Edit card"
                        onClick={() => handleEditOpen(params)}
                    />,
                    <GridActionsCellItem
                        icon={<Trash />}
                        showInMenu
                        className="item-danger"
                        label="Delete card"
                        onClick={() => handleDeleteOpen(params)}
                    />,
                ],
            },
        ],
        [],
    );
    const handleEditOpen = params => {
        setCardData(params.row);
        setEditOpen(true);
    };

    const handleDeleteOpen = params => {
        setCardData(params.row);
        setDeleteOpen(true);
    };

    return (
        <>
            <Table
                lastColSticky
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={fetchData}
                staticFilters={staticFilters}
                total={total}
                noDataComponent={noDataComponent}
            />
            <ModalDeletePaymentCard setOpen={setDeleteOpen} open={deleteOpen} data={cardData} />
            <ModalEditPaymentCard data={cardData} setOpen={setEditOpen} open={editOpen} />
        </>
    );
};

export default PaymentSettingsTable;
