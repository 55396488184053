import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { removeAuthToken } from 'api/helpers';
import CustomerService from 'api/services/CustomerService';
import UtilitesService from 'api/services/UnilitesService';
import showMessage from 'components/NoticeProvider/showMessage';
import { isObjectEmpty } from 'helpers';
import { POPULAR_COUNTRIES_IDS } from 'helpers/constants';
import _ from 'lodash';

export const customerRetrieve = createAsyncThunk(
    'customerRetrieve',
    async () => {
        const response = await CustomerService.retrieve();
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            const { loading } = getState().customerState.customer;
            return !loading;
        },
    },
);

export const editCustomer = createAsyncThunk('editCustomer', async params => {
    const response = await CustomerService.edit(params);
    showMessage(response.data.message, response.status);
    await customerRetrieve();
    return response.data;
});

export const fetchLanguages = createAsyncThunk(
    'fetchLanguages',
    async () => {
        const response = await UtilitesService.languages();
        return response.data;
    },
    {
        condition: (__, { getState }) => {
            const { data } = getState().customerState.languages;
            return _.isEmpty(data);
        },
    },
);

export const fetchCountries = createAsyncThunk(
    'fetchCountries',
    async params => {
        const response = await UtilitesService.countries(params);
        return response.data;
    },
    {
        condition: (__, { getState }) => {
            const { data } = getState().customerState.countries;
            return !data.length;
        },
    },
);

export const fetchCountriesDropdown = async params => {
    const response = await UtilitesService.countries(params);
    return response.data.data.map(item => ({ value: item.country_id, label: item.country_name }));
};

export const fetchCitiesDropdown = async params => {
    const response = await UtilitesService.cities(params);
    return response.data.data.map(item => ({
        value: item.city_id,
        label: item.city_name,
        showLabel: item.city_is_populous,
        labelColor: 'warning',
        labelText: 'Low Availability',
    }));
};

export const fetchCities = createAsyncThunk('fetchCities', async params => {
    const response = await UtilitesService.cities(params);
    return response.data;
});

const CustomerSlice = createSlice({
    name: 'CustomerSlice',
    initialState: {
        customer: {
            data: null,
            loading: false,
            error: null,
        },
        languages: {
            data: {},
            loading: false,
        },
        countries: {
            data: [],
            loading: false,
        },
        cities: {
            data: [],
            loading: false,
        },
    },
    reducers: {
        clearUser: state => {
            state.customer.data = null;
            removeAuthToken();
            localStorage.removeItem('shownInvoicesIds');
        },
    },
    extraReducers: builder => {
        builder
            .addCase(customerRetrieve.pending, state => {
                state.customer.loading = true;
            })
            .addCase(customerRetrieve.fulfilled, (state, action) => {
                state.customer.loading = false;
                state.customer.data = action.payload.data;
            })
            .addCase(customerRetrieve.rejected, (state, action) => {
                state.customer.loading = false;
                state.customer.error = action.error;
            });
        builder
            .addCase(fetchLanguages.pending, state => {
                state.languages.loading = true;
            })
            .addCase(fetchLanguages.fulfilled, (state, action) => {
                state.languages.loading = false;
                state.languages.data = action.payload.data;
            });
        builder
            .addCase(fetchCountries.pending, state => {
                state.countries.loading = true;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.countries.loading = false;
                state.countries.data = action.payload.data;
            });
        builder
            .addCase(fetchCities.pending, state => {
                state.cities.loading = true;
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                state.cities.loading = false;
                state.cities.data = action.payload.data;
            });
    },
});

export const selectCustomer = createSelector([state => state.customerState.customer], customer => customer?.data || {});
export const selectCustomerLoading = createSelector(
    [state => state.customerState.customer],
    customer => customer?.loading,
);
export const selectIsUser = createSelector(
    [state => state.customerState.customer],
    customer => !isObjectEmpty(customer?.data),
);
export const selectActiveResidentialServiceId = createSelector(
    [selectCustomer],
    data => data.active_residential_service_id,
);

export const selectLanguages = createSelector([state => state.customerState.languages], languages => languages.data);
export const selectLanguagesOptions = createSelector([selectLanguages], data => {
    const result = [];
    for (const [key, value] of Object.entries(data)) {
        result.push({ value: value, label: key });
    }
    return result;
});

export const selectCountries = createSelector([state => state.customerState.countries], countries => countries.data);
export const selectCountriesOptions = createSelector([selectCountries], data =>
    data.map(item => ({ value: item.country_id, label: item.country_name })),
);
export const selectPopularCountriesOptions = createSelector([selectCountriesOptions], data =>
    data.filter(item => POPULAR_COUNTRIES_IDS.includes(item.value)),
);

export const selectCities = createSelector([state => state.customerState.cities], cities => cities.data);
export const selectCitiesOptions = createSelector([selectCities], data =>
    data.map(item => ({
        value: item.city_id,
        label: item.city_name,
        showLabel: !item.city_is_populous,
        labelColor: 'warning',
        labelText: 'Low Availability',
    })),
);

export const selectCustomerProxyUsersLimit = createSelector(
    [state => state.customerState.customer],
    customer => customer?.data?.customer_proxy_user_limit || 0,
);

export const { clearUser } = CustomerSlice.actions;

export default CustomerSlice.reducer;
