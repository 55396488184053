//  @ts-nocheck
import { createTheme } from '@mui/material';
import palette from './palette';
import components from './components';

const theme = createTheme({
    typography: {
        fontFamily: ['Space Grotesk', 'sans-serif'].join(','),
    },
    palette,
    components,
});

export default theme;
