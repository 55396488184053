import React from 'react';
import Box from '@mui/material/Box';

import { ReactComponent as MapPinIcon } from 'icons/map-pin.svg';

const pins = [
    { top: 23, right: 27 },
    { left: 12, top: 65 },
    { right: 41, bottom: 20 },
];

const LocationPreview = () => {
    return (
        <Box component="span" className="pin-wrapper">
            {pins.map((position, idx) => (
                <Box key={idx} className="pin-wrapper-pin" component="span" {...position}>
                    <MapPinIcon width={28} height={28} />
                </Box>
            ))}
        </Box>
    );
};

export default LocationPreview;
