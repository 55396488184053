const Flag = ({ country_id, size = 24 }) => {
    if (!country_id) return null;

    return (
        <img
            className="country-icon"
            loading="lazy"
            width={size}
            height={size * 0.7}
            src={`https://flagcdn.com/${country_id}.svg`}
            style={{
                borderRadius: '4px',
                objectFit: 'cover',
                width: size,
                height: size * 0.7,
            }}
            alt=""
        />
    );
};

export default Flag;
