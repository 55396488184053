import React from 'react';
import Box from '@mui/material/Box';

import PopupBadge, { BADGE_VARIANTS } from 'components/_common/PopupBadge';

const Mastercard = () => {
    return (
        <Box className="mastercard-preview">
            <PopupBadge rounded variant={BADGE_VARIANTS.success} />
        </Box>
    );
};

export default Mastercard;
