import React from 'react';

import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';
import StatusCell from 'components/_common/TableCells/StatusCell';

const minWidth = 150;

const ApiRequestsTable = ({ data, dataLoading, fetchData, total, noDataMessage }) => {
    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'method',
                headerName: 'Method',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'api_request_path',
                headerName: 'Request path',
                flex: 1,
                minWidth: 350,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'ip_address',
                headerName: 'IP Address',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'user_agent',
                headerName: 'User Agent',
                flex: 1,
                minWidth: 350,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'time',
                headerName: 'Time',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            total={total}
            noDataMessage={noDataMessage}
        />
    );
};

export default ApiRequestsTable;
