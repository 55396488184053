import './styles.scss';
import MetricItem from './MetricItem';

const ApiRequestChart = ({ title, filters, metricsData, chart }) => {
    return (
        <div className="api-request-chart box">
            <div className="api-request-chart__header">
                <h5>{title}</h5>
                {filters}
            </div>
            <div className="api-request-chart__info">
                {metricsData?.map(({ title, value }) => (
                    <MetricItem key={title} title={title} value={value} />
                ))}
            </div>
            <div className="api-request-chart__chart">{chart}</div>
        </div>
    );
};

export default ApiRequestChart;
