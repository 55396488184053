import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';

import ConfirmButton from 'components/Buttons/ConfirmButton';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import TextInput from 'components/_common/TextInput';
import showMessage from 'components/NoticeProvider/showMessage';
import ServiceDataLine from 'components/ServiceDataLine';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import MultipleInput from 'components/_common/MultipleInput';
import { gb_max_real, gb_min } from 'pages/Product/constants';
import ProductDropdown from './ProductDropdown';

import ProxyService from 'api/services/ProxyService';

import { fetchActiveServices } from 'store/slices/services';

import { proxyEditUserValidationSchema } from 'helpers/validations';
import { service_types } from 'helpers/services';
import { convertBandwidth } from 'helpers';
import { GB } from 'helpers/constants';

const optionsResidentialPermission = [
    {
        value: 'allow',
        label: 'Allow',
    },
    {
        value: 'disallow',
        label: 'Disallow',
    },
];

const optionsOtherServicesPermission = [
    {
        value: 'allowAll',
        label: 'Allow All',
    },
    {
        value: 'restrict',
        label: 'Restrict',
    },
    {
        value: 'noAccess',
        label: 'No Access',
    },
];

const ModalEditProxyUser = ({ setOpen, open, data, refreshData }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [ips, setIps] = useState([]);
    const [products, setProducts] = useState([]);
    const [residentialServicePermission, setResidentialServicePermission] = useState('allow');
    const [initialResidentialServicePermission, setInitialResidentialServicePermission] = useState('allow');
    const [residentialServiceLimitValue, setResidentialServiceLimitValue] = useState(0);
    const [otherServicesPermission, setOtherServicesPermission] = useState('allowAll');

    const isResidentialServiceAllowed = residentialServicePermission === 'allow';
    const isResidentialServiceLimited = residentialServiceLimitValue > 0;
    const isOtherServicesRestricted = otherServicesPermission === 'restrict';

    const {
        whitelisted_ip,
        restricted_service_ids,
        id,
        password,
        name,
        residential_bandwidth_usage,
        proxy_user_is_service_restricted,
    } = data || {};

    const initialValues = {
        id: id,
        proxy_user_password: password,
        products: restricted_service_ids,
        limit: residential_bandwidth_usage,
    };

    const handleChangeOtherServicesRestriction = value => {
        setOtherServicesPermission(value);
        if (value !== 'restrict') {
            setProducts([]);
        }
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: proxyEditUserValidationSchema,
        onSubmit: async params => {
            const newParams = { id: params.id };
            if (params.proxy_user_password !== data?.password)
                newParams.proxy_user_password = params.proxy_user_password;

            if (!isEqual(ips, whitelisted_ip)) newParams.ip_address_authentications = ips;

            if (isResidentialServiceAllowed) {
                newParams.proxy_user_residential_bytes_limit = isResidentialServiceLimited
                    ? +convertBandwidth(residentialServiceLimitValue, 'B', GB)
                    : null;
            } else {
                newParams.proxy_user_residential_bytes_limit = data.residential_bandwidth_usage?.value;
            }

            switch (otherServicesPermission) {
                case 'allowAll':
                    newParams.proxy_user_is_service_restricted = false;
                    newParams.restricted_service_ids = [];
                    break;
                case 'noAccess':
                    newParams.proxy_user_is_service_restricted = true;
                    newParams.restricted_service_ids = [];
                    break;
                case 'restrict':
                    newParams.proxy_user_is_service_restricted = true;
                    newParams.restricted_service_ids = products;
                    break;

                default:
                    return;
            }

            try {
                setLoading(true);
                await ProxyService.editUser(newParams)
                    .then(response => {
                        showMessage(response.data.message, response.status);
                        setLoading(false);
                    })
                    .then(() => {
                        setOpen(false);
                        refreshData();
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    useEffect(() => {
        if (!open || !data) {
            return;
        }

        setIps(whitelisted_ip);
        if (restricted_service_ids.length) {
            setProducts(restricted_service_ids);
            setOtherServicesPermission('restrict');
        } else {
            setOtherServicesPermission(proxy_user_is_service_restricted ? 'noAccess' : 'allowAll');
        }

        const residentialPermission = residential_bandwidth_usage?.limit === 0 ? 'disallow' : 'allow';
        setResidentialServicePermission(residentialPermission);
        setInitialResidentialServicePermission(residentialPermission);
        setResidentialServiceLimitValue(+convertBandwidth(residential_bandwidth_usage?.limit, 'GB', 'B'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        dispatch(fetchActiveServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const wasFieldChanged = [
        formik.values.proxy_user_password !== password,
        !isEqual(ips, whitelisted_ip),
        proxy_user_is_service_restricted
            ? isEqual(products, restricted_service_ids)
            : otherServicesPermission !== 'allowAll',
        +convertBandwidth(residential_bandwidth_usage?.limit ?? 0) !== residentialServiceLimitValue,
        initialResidentialServicePermission !== residentialServicePermission,
    ];

    const disabled = !wasFieldChanged.some(el => el);

    return (
        <ControledDialog
            title="Edit proxy user"
            Submit={<ConfirmButton onClick={formik.handleSubmit} disabled={disabled} loading={loading} />}
            setOpen={setOpen}
            open={open}
            minHeight={540}
        >
            <h6>Authentication</h6>
            <TextInput disabled label="Proxy User ID" value={name} onChange={undefined} />
            <TextInput
                withFormik
                name="proxy_user_password"
                label="Password"
                value={formik.values.proxy_user_password ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.proxy_user_password && Boolean(formik.errors.proxy_user_password)}
                helperText={formik.touched.proxy_user_password && formik.errors.proxy_user_password}
            />
            <MultipleInput values={ips} setValues={setIps} />
            <h6>Authorization:</h6>
            <ServiceDataLine type={service_types.residential} name={'Residential Bandwidth'} />
            <div style={{ marginTop: '-35px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsResidentialPermission}
                    value={residentialServicePermission}
                    onChange={setResidentialServicePermission}
                />
                {isResidentialServiceAllowed && (
                    <div className="mt-8">
                        <TextInput
                            type="number"
                            min={gb_min}
                            max={gb_max_real}
                            value={residentialServiceLimitValue}
                            onChange={value => setResidentialServiceLimitValue(value)}
                            endAdornment={<InputAdornment position="end">GB</InputAdornment>}
                        />
                        <div className="service-line-title mt-8">Enter 0 for unlimited bandwidth</div>
                    </div>
                )}
            </div>
            <FormLabel>Static Proxies</FormLabel>
            <div style={{ marginTop: '-15px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsOtherServicesPermission}
                    value={otherServicesPermission}
                    onChange={handleChangeOtherServicesRestriction}
                />
            </div>
            {isOtherServicesRestricted && <ProductDropdown onChange={setProducts} value={products} />}
        </ControledDialog>
    );
};

export default ModalEditProxyUser;
