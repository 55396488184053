import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectMetrics } from 'store/slices/services';

import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';

import MessageCard from 'components/MessageCard';

const BandwidthWarnings = () => {
    const metrics = useSelector(selectMetrics);

    const usage = useMemo(() => {
        return metrics.find(metric => metric.title === 'usage');
    }, [metrics]);

    return (
        <>
            {usage?.isNoBandwidth ? (
                <MessageCard
                    buttonText="Buy Now"
                    customStyles={{ marginBottom: '24px' }}
                    customButtonStyles={{ display: 'flex', width: 200, marginLeft: 'auto' }}
                    type="bad"
                    message="You don’t have any residential bandwidth."
                    to={`${ROUTES.products}/${service_types.residential}`}
                />
            ) : null}
            {usage?.isNeedTopUp ? (
                <MessageCard
                    buttonText="Top Up"
                    customStyles={{ marginBottom: '24px' }}
                    customButtonStyles={{ display: 'flex', width: 200, marginLeft: 'auto' }}
                    type="bad"
                    message="You are close to running out of bandwidth, top up now to continue using Residential proxies."
                    to={`${ROUTES.products}/${service_types.residential}`}
                />
            ) : null}
        </>
    );
};

export default BandwidthWarnings;
