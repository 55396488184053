import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectProxyFilterOptions } from 'store/slices/proxy';
import { uppercaseFirst } from 'helpers';
import { proxyFilters } from 'helpers/constants';
import { getServiceIcon } from 'helpers/services';

import Select from 'components/_common/Select/SimpleSelect';

const FilterType = ({ value, onChange }) => {
    const options = useSelector(selectProxyFilterOptions);

    const formattedOptions = useMemo(() => {
        return options[proxyFilters.PROXY_TYPE]?.map(option => {
            return { value: option, label: uppercaseFirst(option), icon: () => getServiceIcon(option) };
        });
    }, [options]);

    return <Select label="Type" options={formattedOptions || []} value={value} onChange={onChange} withIcon={true} />;
};

export default FilterType;
