import { useMemo } from 'react';

import { getPaymentPlatformIcon } from 'helpers/payments';
import { getHighestPriorityLineItem } from 'pages/Product/helpers';

import SidebarRow from 'components/Sidebars/Items/SidebarRow';

import './styles.scss';

const SuccessfulSidebar = ({ line_items, total, payment_platform }) => {
    const item = useMemo(() => getHighestPriorityLineItem(line_items), [line_items]);

    return (
        <div className="order-summary-sidebar-container">
            <div className="order-summary box successful-sidebar">
                <h5>Order summary</h5>
                <SidebarRow name="Quantity">{item?.quantity}</SidebarRow>
                <SidebarRow name="Total">${total?.toFixed(2)}</SidebarRow>
                <SidebarRow name="Payment platform">
                    {getPaymentPlatformIcon(payment_platform) || payment_platform}
                </SidebarRow>
            </div>
        </div>
    );
};

export default SuccessfulSidebar;
