import './styles.scss';

import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';

import RadioGroupCards from 'components/_common/RadioGroupCards';
import Button from 'components/_common/Button';
import CreditCardLogos from 'components/CreditCardLogos';
import CryptoLogos from 'components/CryptoLogos';

import {
    getPaymentPlatformIcon,
    PAYMENT_METHODS,
    payment_platform_types,
    VISIBLE_PAYMENT_PLATFORMS,
} from 'helpers/payments';

import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg';

const AllowedPaymentPlatforms = ({
    allowed_payment_platforms,
    credit_only_checkout = false,
    value,
    onChange,
    disabled = false,
}) => {
    const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

    const stripe = VISIBLE_PAYMENT_PLATFORMS.find(platform => platform === payment_platform_types.stripe);

    const mainOptions = credit_only_checkout
        ? [
              {
                  value: PAYMENT_METHODS.CREDIT,
                  label: 'Store Credit',
              },
              {
                  value: stripe,
                  label: 'Stripe',
                  additional: <span className="radio-card-icon">{getPaymentPlatformIcon(stripe)}</span>,
              },
          ]
        : [
              {
                  value: PAYMENT_METHODS.CARD,
                  label: 'Credit card',
                  additional: <CreditCardLogos />,
              },
              {
                  value: PAYMENT_METHODS.CRYPTO,
                  label: 'Cryptocurrency',
                  additional: <CryptoLogos withNumber />,
              },
          ];

    const additionalOptions = allowed_payment_platforms
        .filter(i => VISIBLE_PAYMENT_PLATFORMS.includes(i))
        .map(name => ({
            value: name,
            label: capitalize(name),
            additional: <span className="radio-card-icon">{getPaymentPlatformIcon(name)}</span>,
        }));

    const handleToggleMorePaymentOptions = () => {
        setShowAdditionalOptions(prevState => !prevState);
    };

    useEffect(() => {
        onChange(mainOptions[0].value);
    }, []);

    return (
        <>
            <div className="options-container">
                <RadioGroupCards
                    key={value}
                    disabled={disabled}
                    label="Select payment method"
                    items={mainOptions}
                    value={value}
                    onChange={onChange}
                />
                <div className={`additional-options ${showAdditionalOptions ? 'show' : 'hide'}`}>
                    <div className="mt-8">
                        <RadioGroupCards
                            disabled={disabled}
                            items={additionalOptions}
                            value={value}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
            {credit_only_checkout || (
                <Button variant="text" containerStyle="mt-8" onClick={handleToggleMorePaymentOptions}>
                    {!showAdditionalOptions ? 'Show' : 'Hide'} More Payment Options
                    <ChevronDownIcon className={`dropdown-icon-${showAdditionalOptions ? 'down' : 'up'}`} />
                </Button>
            )}
        </>
    );
};

export default AllowedPaymentPlatforms;
