import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';

import Layout from 'Layout';
import MainLayout from 'Layout/MainLayout';
import AuthLayout from 'Layout/AuthLayout';

import Dashboard from 'pages/Dashboard';
import Summary from 'pages/Summary';
import Residential from 'pages/Residential';
import ApiKeys from 'pages/ApiKeys';
import ApiRequests from 'pages/ApiRequests';
import ProxyList from 'pages/ProxyList';
import Replacements from 'pages/Replacements';
import ProxyUserSettings from 'pages/ProxyUserSettings';
import AllProducts from 'pages/AllProducts';
import Product from 'pages/Product';
import Invoices from 'pages/Billing/Invoices';
import Subscriptions from 'pages/Billing/Subscriptions';
import Transactions from 'pages/Billing/Transactions';
import PaymentSettings from 'pages/Billing/PaymentSettings';
import AccountSettings from 'pages/AccountSettings';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import PasswordRecovery from 'pages/PasswordRecovery';
import Page404 from 'pages/Page404';
import SingleInvoicePage from 'pages/SingleInvoicePage';
import OrderConfirmation from 'pages/OrderConfirmation';
import Address from 'pages/Billing/Address';
import ErrorBoundary from 'pages/ErrorPage/ErrorBoundary';
import ErrorPage from 'pages/ErrorPage';

import ROUTES from 'helpers/routes';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path={ROUTES.base}
            element={
                <ErrorBoundary fallback={ErrorPage}>
                    <Layout />
                </ErrorBoundary>
            }
        >
            <Route path={ROUTES.base} element={<MainLayout />}>
                <Route path={ROUTES.base} element={<Dashboard />} />
                <Route path={ROUTES.dashboard} element={<Dashboard />} />
                <Route path={ROUTES.summary} element={<Summary />} />
                <Route path={ROUTES.proxyList} element={<ProxyList />} />
                <Route path={ROUTES.replacements} element={<Replacements />} />
                <Route path={ROUTES.proxyUsers} element={<ProxyUserSettings />} />
                <Route path={ROUTES.products} element={<AllProducts />} />
                <Route path={ROUTES.products + '/:slug'} element={<Product />} />
                <Route path={ROUTES.residential} element={<Residential />} />
                <Route path={ROUTES.apiKeys} element={<ApiKeys />} />
                <Route path={ROUTES.apiRequests} element={<ApiRequests />} />
                <Route path={ROUTES.billingSubscriptions} element={<Subscriptions />} />
                <Route path={ROUTES.billingInvoices} element={<Invoices />} />
                <Route path={ROUTES.billingInvoices + '/:slug'} element={<SingleInvoicePage />} />
                <Route path={ROUTES.billingTransactions} element={<Transactions />} />
                <Route path={ROUTES.billingPaymentSettings} element={<PaymentSettings />} />
                <Route path={ROUTES.billingAddress} element={<Address />} />
                <Route path={ROUTES.accountSettings} element={<AccountSettings />} />
                <Route path={ROUTES.orderConfirmation} element={<OrderConfirmation />} />
                <Route path="*" element={<Page404 />} />
            </Route>
            <Route element={<AuthLayout />}>
                <Route path={ROUTES.login} element={<SignIn />} />
                <Route path={ROUTES.signUp} element={<SignUp />} />
                <Route path={ROUTES.recovery} element={<PasswordRecovery />} />
            </Route>
        </Route>,
    ),
);

const App = () => <RouterProvider router={router} />;

export default App;
