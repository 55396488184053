import './styles.scss';

import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import SearchableDropdown from 'components/_common/SearchableDropdown';

import { fetchProxyUsersDropdown } from 'store/slices/proxyUsers';

import { mapUserToIcons } from './UserIcon';

const ProxyUsersDropdown = ({ value, onChange }) => {
    const [usersOptions, setUsersOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const users = await fetchProxyUsersDropdown();
            const options = mapUserToIcons(users);

            setUsersOptions(options);
            const defaultUser = options.find(user => user.showLabel);

            if (defaultUser?.value) {
                onChange(defaultUser);
            }
        })();
    }, []);

    return (
        <FormControl fullWidth className="form-users-dropdown">
            <FormLabel focused={false}>Proxy User</FormLabel>

            <SearchableDropdown
                value={value}
                onChange={onChange}
                options={usersOptions}
                placeholder="Select Proxy User"
                id="proxy-user"
                renderDropdownLabel={({ value, placeholder }) => {
                    return value ? (
                        <>
                            {value.icon}
                            <Typography component="span" className="user-label">
                                {value.label}
                            </Typography>
                        </>
                    ) : (
                        <Typography component="span">{placeholder}</Typography>
                    );
                }}
                renderOption={({ option }) => {
                    return (
                        <>
                            {option.icon}
                            <Typography className="user-label">{option.label}</Typography>
                        </>
                    );
                }}
                optionContainerClassName="user-option"
            />
        </FormControl>
    );
};

export default ProxyUsersDropdown;
