const button = {
    styleOverrides: {
        sizeLarge: {
            height: '40px',
            fontSize: '16px',
            fontWeight: 700,
            justifyContent: 'center',
        },
        sizeMedium: {
            height: '32px',
            fontSize: '14px',
            fontWeight: 700,
            justifyContent: 'center',
        },
        sizeSmall: {
            height: '28px',
            fontSize: '14px',
            fontWeight: 700,
            justifyContent: 'center',
        },
        root: {
            fontSize: 'inherit',
            letterSpacing: 0,
            minHeight: 'auto',
            textTransform: 'inherit',
            justifyContent: 'flex-start',
            color: '#1F2A37',
            minWidth: 'auto',
            lineHeight: 1,
            padding: '0 16px',
        },
    },
    variants: [
        { props: { color: 'error' }, style: { color: '#FFFFFF' } },
        {
            props: { color: 'primary', variant: 'contained' },
            style: { color: '#FFFFFF' },
        },
    ],
};

export default button;
