import React from 'react';

import CityDropdown from 'components/CityDropdown';
import CountriesDropdown from 'components/CountryDropdown';
import IspAsnDropdown from 'components/IspAsnDropdown';

import { form_keys } from './constants';

const TargetingSettings = ({ params, onChange }) => {
    const country = params[form_keys.COUNTRY];
    const country_id = country?.value === 'All' ? '' : country?.value;

    return (
        <>
            <CountriesDropdown value={country} onChange={onChange(form_keys.COUNTRY)} />
            <CityDropdown
                disabled={!country_id}
                value={params[form_keys.CITY]}
                onChange={onChange(form_keys.CITY)}
                countryCode={country_id}
            />
            <IspAsnDropdown value={params[form_keys.ASN]} countryCode={country_id} onChange={onChange(form_keys.ASN)} />
        </>
    );
};

export default TargetingSettings;
