import './styles.scss';

import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'icons/x-close.svg';
import StrapiService from 'api/services/StrapiService';
import CustomLink from 'components/_common/Link';

const Banner = () => {
    const [isHeaderBannerClosed, setIsHeaderBannerClosed] = useState(false);
    const [banner, setBanner] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await StrapiService.getBanner();
                setBanner(response.data?.data?.attributes?.banner ?? null);
            } catch (error) {
                setBanner(null);
            }
        })();
    }, []);

    if (isHeaderBannerClosed || !(banner?.is_active ?? false)) return null;

    return (
        <div className="cta">
            {banner.link ? (
                <>
                    <CustomLink to={banner.link} blank>
                        <span className="cta-title">{banner.text}</span>
                    </CustomLink>
                    <button
                        onClick={() => setIsHeaderBannerClosed(true)}
                        aria-label="Close header banner"
                        className="cta-btn"
                    >
                        <CloseIcon />
                    </button>
                </>
            ) : (
                <>
                    <span>{banner.text}</span>
                    <button
                        onClick={() => setIsHeaderBannerClosed(true)}
                        aria-label="Close header banner"
                        className="cta-btn"
                    >
                        <CloseIcon />
                    </button>
                </>
            )}
        </div>
    );
};

export default Banner;
