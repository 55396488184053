import React from 'react';

const Dots = ({ quantity = 3 }) => {
    return (
        <span className="dots-container">
            {Array.from({ length: quantity }).map((_, idx) => (
                <span key={idx} className="dot" />
            ))}
        </span>
    );
};

export default Dots;
