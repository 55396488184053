import ROUTES from './routes';

export const API_DOCUMENTATION_LINK = process.env.REACT_APP_API_DOCUMENTATION_LINK;
export const GENERAL_DOCUMENTATION_LINK = process.env.REACT_APP_GENERAL_DOCUMENTATION_LINK;
export const API_FAQ_LINK = process.env.REACT_APP_API_FAQ_LINK;
export const TERMS_OF_USE_LINK = process.env.REACT_APP_TERMS_OF_USE_LINK;
export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK;
export const GUIDES_HOW_TO_LINK = process.env.REACT_APP_GUIDES_HOW_TO_LINK;
export const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;
export const BLOG_LINK = process.env.REACT_APP_BLOG_LINK;
export const PROXY_TESTER_LINK = process.env.REACT_APP_PROXY_TESTER_LINK;
export const AI_FEATURE_ENABLED = process.env.REACT_APP_AI_FEATURE_ENABLED === 'true';

export const billingItems = [
    { name: 'Subscriptions', to: ROUTES.billingSubscriptions },
    { name: 'Invoices', to: ROUTES.billingInvoices },
    { name: 'Transactions', to: ROUTES.billingTransactions },
    { name: 'Payment settings', to: ROUTES.billingPaymentSettings },
    { name: 'Billing Address', to: ROUTES.billingAddress },
];

export const staticProxyItems = [
    { name: 'List', to: ROUTES.proxyList },
    { name: 'Replacements', to: ROUTES.replacements },
];

export const helpItems = [
    { text: 'Documentation', icon: 'book', to: GENERAL_DOCUMENTATION_LINK, blank: true },
    {
        text: 'Contact Support',
        onClick: data => {
            window.Mava.initialize();
            window.Mava.identify({ customAttributes: data });
            window.MavaWebChatToggle();
        },
    },
];

export const myProxyItems = [
    { to: ROUTES.residential, text: 'Residential' },
    { to: ROUTES.proxyList, text: 'Static Proxies' },
    { to: ROUTES.proxyUsers, text: 'Proxy Users' },
    { to: PROXY_TESTER_LINK, text: 'Proxy Tester', icon: 'tool', blank: true },
];

export const servicesItems = [
    { to: ROUTES.summary, text: 'Summary' },
    { to: ROUTES.billingSubscriptions, text: 'Billing' },
];

export const developerItems = [
    {
        to: API_DOCUMENTATION_LINK,
        text: 'API Documentation',
        blank: true,
        className: 'fake',
    },
    { to: ROUTES.apiKeys, text: 'API Keys' },
    { to: ROUTES.apiRequests, text: 'API Analytics' },
];

export const MB = 'MB';
export const GB = 'GB';
export const IP = 'IP';
export const IPs = 'IPs';
export const TB = 'TB';

export const INPUT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const REQUEST_PRESET_VALUES = {
    LAST_HOUR: 'last_hour',
    LAST_DAY: 'last_day',
    LAST_WEEK: 'last_week',
    LAST_30_DAYS: 'last_30_days',
    LAST_60_DAYS: 'last_60_days',
    LAST_90_DAYS: 'last_90_days',
    LAST_YEAR: 'last_year',
};

export const proxyFilters = {
    ASN_ID: 'asn_id',
    COUNTRY_ID: 'country_id',
    PROXY_TYPE: 'proxy_type',
    SERVICE_ID: 'service_id',
};

export const billingDates = {
    NOW: 'now',
};

export const invoiceStatuses = {
    OPEN: 'open',
    PAID: 'paid',
    VOID: 'void',
};

export const exportProxyAuthTypes = {
    PROXY_USER_AUTH: 'Proxy User Authentication',
    IP_AUTH: 'IP Authentication',
    PROXY_SPECIFIC_AUTH: 'Proxy Specific Authentication',
};

export const exportProxyAuthTypesOptions = [
    { value: exportProxyAuthTypes.PROXY_USER_AUTH, label: exportProxyAuthTypes.PROXY_USER_AUTH },
    { value: exportProxyAuthTypes.IP_AUTH, label: exportProxyAuthTypes.IP_AUTH },
    { value: exportProxyAuthTypes.PROXY_SPECIFIC_AUTH, label: exportProxyAuthTypes.PROXY_SPECIFIC_AUTH },
];

export const proxyFormatOptions = [
    { label: 'ip:port:username:password', value: 'standard' },
    { label: 'http://username:password@ip:port', value: 'http' },
    { label: 'socks5://username:password@ip:port', value: 'socks5' },
    { label: 'socks5h://username:password@ip:port', value: 'socks5h' },
];

export const graphPresetOptions = [
    {
        value: REQUEST_PRESET_VALUES.LAST_HOUR,
        label: 'Last Hour',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_DAY,
        label: 'Last Day',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_WEEK,
        label: 'Last Week',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_30_DAYS,
        label: 'Last 30 Days',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_60_DAYS,
        label: 'Last 60 Days',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_90_DAYS,
        label: 'Last 90 Days',
    },
    {
        value: REQUEST_PRESET_VALUES.LAST_YEAR,
        label: 'Last Year',
    },
];

export const balanceTransactionReasons = {
    applied_to_invoice: 'Applied to invoice',
    top_up: 'Top up',
    adjustment: 'Adjustment',
};

export const POPULAR_COUNTRIES_IDS = process.env.REACT_APP_POPULAR_COUNTRIES_IDS?.split?.(',') || [];
