import React from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';

import { form_keys, protocol_options, type_options } from './constants';
import { proxyFormatOptions } from 'helpers/constants';

import Select from 'components/_common/Select/SimpleSelect';

const ButtonGroup = ({ label, form_key, value, onChange, options }) => {
    return (
        <FormControl>
            <FormLabel id={form_key}>{label}</FormLabel>
            <ToggleButtonGroup
                className="proxies-form-block-toggle-group"
                exclusive
                value={value}
                onChange={onChange(form_key, (_, value) => value)}
                aria-labelledby={form_key}
            >
                {options.map(option => {
                    const Icon = option.icon;

                    return (
                        <ToggleButton
                            className="toggle-group-option"
                            key={option.value}
                            value={option.value}
                            aria-label={option.label}
                        >
                            <Icon width={16} height={16} />
                            {option.label}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </FormControl>
    );
};

const FormatSettings = ({ params, onChange }) => {
    return (
        <>
            <Stack className="xl-column" justifyContent="space-between" alignItems="flex-start" width="100%" gap="16px">
                <ButtonGroup
                    form_key={form_keys.TYPE}
                    label="Type"
                    onChange={onChange}
                    options={type_options}
                    value={params[form_keys.TYPE]}
                />
                <ButtonGroup
                    form_key={form_keys.PROXY_PROTOCOL}
                    label="Protocol"
                    onChange={onChange}
                    options={protocol_options}
                    value={params[form_keys.PROXY_PROTOCOL]}
                />
            </Stack>
            <Select
                label="Format"
                onChange={onChange(form_keys.PROXY_FORMAT)}
                options={proxyFormatOptions}
                value={params[form_keys.PROXY_FORMAT]}
                fullWidth
                withFormLabel
                defaultPlaceholder="Select Format"
            />
        </>
    );
};

export default FormatSettings;
