import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalTopUp from 'components/Modals/ModalTopUp';
import UserActions from './UserActions';

import { customerRetrieve, selectCustomer } from 'store/slices/customer';

import { convertToDollar } from 'helpers/payments';
import { truncateEmail } from 'helpers';

const UserInfo = () => {
    const dispatch = useDispatch();
    const { customer_first_name, customer_last_name, customer_email_address, credit_balance } =
        useSelector(selectCustomer);

    const convertedBalance = convertToDollar(credit_balance);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(customerRetrieve());
        }, 15000);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    return (
        <div>
            <div className="user-balance">
                <div>{credit_balance === undefined ? '' : convertedBalance}</div>
                <ModalTopUp labelButton labelColor="primary" />
            </div>
            <div className="user-info">
                <div className="user-info__data">
                    <p className="subtitle2">
                        {customer_first_name} {customer_last_name}
                    </p>
                    <p className="body2 text-grey-500">
                        {truncateEmail(customer_email_address, { maxLength: 25, offset: 10 })}
                    </p>
                </div>
                <UserActions />
            </div>
        </div>
    );
};

export default UserInfo;
