import './styles.scss';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import InfoBanner from 'components/InfoBanner';
import PageHeader from 'components/PageHeader';
import NoData from './NoData';
import ProxyListTable from './Table';
import ProxyTableBar from './ProxyTableBar';
import ProxyListFilters from './ProxyListFilters';

import {
    selectProxyListLoading,
    fetchProxyList,
    selectProxyListTableData,
    selectProxyListTotal,
    fetchProxyFilterOptions,
    selectProxyFilterOptions,
} from 'store/slices/proxy';
import { fetchServicesSummary } from 'store/slices/services';

import { proxyFilters } from 'helpers/constants';

const initialFiltersState = {
    service: '',
    type: '',
    country: '',
    search: '',
};

const ProxyList = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const data = useSelector(selectProxyListTableData);
    const loading = useSelector(selectProxyListLoading);
    const total = useSelector(selectProxyListTotal);
    const filterOptions = useSelector(selectProxyFilterOptions);
    const [initialTableLoading, setInitialTableLoading] = useState(true);

    const [filters, setFilters] = useState(initialFiltersState);

    const service_id = searchParams.get('service_id');

    useEffect(() => {
        if (service_id) {
            setFilters(prevFilters => ({ ...prevFilters, service: service_id }));
        }

        dispatch(fetchProxyFilterOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const serviceIds = filterOptions[proxyFilters.SERVICE_ID];

        if (!serviceIds || serviceIds.length === 0) {
            return;
        }

        dispatch(fetchServicesSummary({ service_id: serviceIds, per_page: serviceIds.length, page: 1 }));
    }, [filterOptions, dispatch]);

    useEffect(() => {
        if (loading) {
            setInitialTableLoading(true);
        } else if (initialTableLoading) {
            setTimeout(() => setInitialTableLoading(false), 450);
        } else {
            return;
        }
    }, [loading]);

    const handleFilterChange = useCallback((key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }, []);

    const handleFiltersReset = useCallback(() => {
        setFilters(initialFiltersState);
    }, []);

    const props = {
        ...filters,
        onChange: handleFilterChange,
        reset: handleFiltersReset,
    };

    return (
        <>
            <PageHeader title="Static Proxy List" />
            <ProxyTableBar filters={<ProxyListFilters {...props} />} />
            <ProxyListTable
                data={data}
                dataLoading={initialTableLoading || loading}
                fetchData={filters => dispatch(fetchProxyList(filters))}
                filters={{
                    service_id: filters.service,
                    country_id: filters.country,
                    proxy_type: filters.type,
                    like_ip_address_id_v4: filters.search ? `${filters.search}%` : '',
                }}
                total={total}
                noDataComponent={<NoData />}
            />
            <InfoBanner
                marginBottom={24}
                marginTop={24}
                header="Need help understanding Static Lists and Exporting?"
                content="Read our detailed guide on proxy lists and exporting."
                article={process.env.REACT_APP_API_DOCUMENTATION_PROXY_LIST_LINK}
            />
        </>
    );
};

export default ProxyList;
