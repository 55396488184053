import { useState } from 'react';

import Button from 'components/_common/Button';
import ModalUnpauseService from 'components/Modals/ModalPauseUnpauseService';

import { serviceStatuses } from 'helpers/services';
import ROUTES from 'helpers/routes';

import { getServiceIcon, service_types } from 'helpers/services';
import { ReactComponent as CheckIcon } from 'icons/circle-check-filled.svg';

const PRODUCT_TYPE_ICON_SIZE = 48;

const ProductCardItem = ({ data, retrieveResidentialService, small }) => {
    const [unpauseOpen, setUnpauseOpen] = useState(false);

    const { title, product_description, price, list, product_type, residentialServiceData } = data;

    const isResidential = product_type === service_types.residential;

    const price_joined = price.map(item => <span key={item}>{item}</span>);

    const list_items = list.map(item => (
        <div key={item}>
            <CheckIcon />
            <span>{item}</span>
        </div>
    ));

    const serviceData = {
        due_date: residentialServiceData?.service_expiry_datetime,
        id: residentialServiceData?.service_id,
        payment_method_id: residentialServiceData?.payment_method_id,
        service: {
            id: residentialServiceData?.service_id,
            type: residentialServiceData?.service_type,
            name: residentialServiceData?.service_name,
            value: residentialServiceData?.service_quantity,
        },
        service_cycle: residentialServiceData?.service_cycle,
        service_price_id: residentialServiceData?.service_price_id,
        service_quantity: residentialServiceData?.service_quantity,
        status: residentialServiceData?.service_status,
        total: residentialServiceData?.service_total,
        type: residentialServiceData?.service_type,
    };

    const matchButtonText = () => {
        if (!isResidential) {
            return 'Buy Now';
        }
        switch (residentialServiceData?.service_status) {
            case serviceStatuses.ACTIVE:
                return 'Top Up Subscription';
            case serviceStatuses.PAUSED:
                return 'Unpause Subscription';
            default:
                return 'Buy Now';
        }
    };

    const matchButtonHandler = () => {
        if (!isResidential) {
            return null;
        }
        switch (residentialServiceData?.service_status) {
            case serviceStatuses.PAUSED:
                return () => setUnpauseOpen(true);
            default:
                return null;
        }
    };

    return (
        <div className={`product-card product-card--${product_type}`}>
            {getServiceIcon(product_type, PRODUCT_TYPE_ICON_SIZE)}
            <div className="product-card__title">{title}</div>
            {!small && <div className="product-card__desc">{product_description}</div>}
            {!small && <div className="product-card__price">{price_joined}</div>}
            <div className="product-card__list">{list_items}</div>
            <Button
                size="large"
                fullWidth
                to={!matchButtonHandler() ? ROUTES.products + '/' + product_type : null}
                onClick={matchButtonHandler()}
            >
                {matchButtonText()}
            </Button>
            <ModalUnpauseService
                setOpen={setUnpauseOpen}
                open={unpauseOpen}
                service_id={serviceData?.id}
                onClick={() => retrieveResidentialService(serviceData?.id)}
            />
        </div>
    );
};

export default ProductCardItem;
