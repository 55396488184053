import '../styles.scss';

import Chip from '@mui/material/Chip';

const TextCell = ({ text, capitalize = false, labelText = '', labelColor = '', onClick = null }) => (
    <div
        className={`text-cell ${capitalize ? 'capitalize' : ''} ${onClick ? 'pointer' : ''}`}
        title={text}
        onClick={onClick}
    >
        <div>{text}</div>
        {labelText && <Chip label={labelText} color={labelColor ?? 'default'} />}
    </div>
);

export default TextCell;
