import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';

const CreateAccountButton = ({ queryParams }) => {
    return (
        <Button
            fullWidth
            size="large"
            to={`${ROUTES.signUp}${queryParams}`}
            variant="outlined"
            iconName="plus"
            className="bg-white"
            containerStyle="mt-24"
        >
            Create an account
        </Button>
    );
};
export default CreateAccountButton;
