import './styles.scss';

const Label = ({ color = 'grey', text, onClick = null, override = '' }) => (
    <div
        className={`label-component label-component--${color} ${onClick ? 'pointer' : ''} ${override}`}
        onClick={onClick ? onClick : null}
    >
        {text}
    </div>
);

export default Label;
