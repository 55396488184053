import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class ServicesService {
    static async summary(params) {
        if (!isArray(params?.service_id)) {
            const response = $api.get(urls.services.search, {
                params: params || { sort_by: 'service_creation_datetime_desc' },
            });

            return response;
        } else {
            // If service_id is an array, get only services from it
            const ids = params.service_id;

            const result = await Promise.all(
                ids.map(service_id =>
                    $api.get(urls.services.search, { params: merge(omit(params, '[service_id'), { service_id }) }),
                ),
            );

            const data = result.reduce(
                (acc, res) => {
                    acc.data.push(...res.data.data);
                    acc.total_count = res.data.total_count;

                    return acc;
                },
                { data: [], total_count: 0 },
            );

            return { data };
        }
    }
    static async retrieve(id) {
        return $api.get(urls.services.retrieve + id);
    }
    static async activeServices() {
        return $api.get(urls.services.active);
    }
    static async edit(params) {
        return $api.patch(urls.services.edit + params.service_id, { ...omit(params, ['service_id']) });
    }
    static async cancelReconfiguration(params) {
        return $api.delete(urls.services.reconfigure_cancel + params.subscription_schedule_id, {
            ...omit(params, ['service_id']),
        });
    }
    static async cancel(params) {
        return $api.delete(urls.services.cancel + params.service_id, { data: omit(params, ['service_id']) });
    }
    static async reconfigureOption(service_id) {
        return $api.get(urls.services.reconfigure_option + service_id);
    }
    static async reconfigureCalculate(params) {
        return $api.post(urls.services.reconfigure_calculate + params.service_id, {
            ...omit(params, ['service_id', 'disabled']),
        });
    }
    static async reconfigure(params) {
        return $api.post(urls.services.reconfigure + params.service_id, {
            ...omit(params, ['service_id', 'disabled']),
        });
    }
}
