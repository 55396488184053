import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingLayout from '../Layout';
import OrdersTable from './Table';
import FilterBar from 'components/FilterBar';
import Select from 'components/_common/Select/SimpleSelect';

import {
    selectBillingOrdersLoading,
    selectBillingOrdersTable,
    selectBillingOrdersTotal,
    fetchBillingOrders,
} from 'store/slices/billingOrders';
import { fetchPaymentMethods } from 'store/slices/billingOrders';

import { service_statuses } from 'helpers/services';

const Subscriptions = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectBillingOrdersTable);
    const loading = useSelector(selectBillingOrdersLoading);
    const total = useSelector(selectBillingOrdersTotal);

    const [paymentMethods, setPaymentMethods] = useState({});
    const [status, setStatus] = useState('');

    useEffect(() => {
        (async () => {
            const payments = await fetchPaymentMethods();
            const storage = {};
            payments?.data.forEach(i => {
                storage[i.payment_method_id] = i;
            });

            setPaymentMethods(storage);
        })();
    }, []);

    return (
        <BillingLayout>
            <div className="subscriptions-filter-bar-wrapper">
                <FilterBar
                    left={
                        <Select
                            label="Statuses"
                            options={service_statuses}
                            value={status}
                            onChange={value => setStatus(value)}
                        />
                    }
                    right={null}
                />
            </div>
            <OrdersTable
                data={data.map(i => ({
                    ...i,
                    payment_method_network: paymentMethods[i.payment_method_id]?.payment_method_network,
                    payment_method_last_four: paymentMethods[i.payment_method_id]?.payment_method_last_four,
                }))}
                dataLoading={loading}
                fetchData={filters => dispatch(fetchBillingOrders(filters))}
                filters={{ service_status: status }}
                total={total}
                noDataMessage={'Explore proxy products'}
            />
        </BillingLayout>
    );
};

export default Subscriptions;
