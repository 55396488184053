import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class ApiRequestsService {
    static async search(params) {
        return $api.get(urls.api_request.search, { params });
    }
    static async analytics(params) {
        return $api.get(urls.api_request.analytics, { params });
    }
}
