import '../styles.scss';

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const TooltipList = ({ items, variant }) => {
    return (
        <List disablePadding>
            {items.map((item, idx) => (
                <ListItem
                    key={item + '-' + idx}
                    disablePadding
                    sx={{
                        justifyContent: 'center',
                        marginTop: '8px',
                        ':first-of-type': {
                            marginTop: 0,
                        },
                    }}
                >
                    <Chip label={item} color={variant} />
                </ListItem>
            ))}
        </List>
    );
};

const LabelledListCell = ({ data, max_size = 3, variant = 'default' }) => {
    const items = data.slice(0, max_size);
    const truncatedItems = data.slice(max_size);

    const body_text = `+${truncatedItems.length}`;
    const isListEmpty = !!truncatedItems.length;

    return (
        <div className="list-cell">
            <div className="list-cell__main">
                {items.map((item, index) => (
                    <Chip key={item + '-' + index} label={item} color={variant} />
                ))}
            </div>
            {isListEmpty ? (
                <Tooltip
                    title={<TooltipList items={truncatedItems} variant={variant} />}
                    arrow
                    placement="bottom"
                    sx={{
                        padding: 0,
                        backgroundColor: 'tomato',
                    }}
                >
                    <Chip
                        label={body_text}
                        variant="outlined"
                        color="default"
                        size="small"
                        sx={{ marginLeft: '8px', borderRadius: '4px', cursor: 'pointer' }}
                    />
                </Tooltip>
            ) : null}
        </div>
    );
};

export default LabelledListCell;
