import './styles.scss';

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GridActionsCellItem } from '@mui/x-data-grid';

import showMessage from 'components/NoticeProvider/showMessage';
import Popup from 'components/_common/Popup';
import CodeSnippet from './CodeSnippet/CodeSnippet';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';
import { BADGE_VARIANTS } from 'components/_common/PopupBadge';

import ApiKeysService from 'api/services/ApiKeysService';
import { fetchApiKeys } from 'store/slices/apiKeys';

import { ReactComponent as Trash } from 'icons/trash.svg';

const ModalDeleteApiKey = ({ id }) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await ApiKeysService.delete(id)
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoading(false);
                })
                .then(() => {
                    const showDeleted = searchParams.get('api_key_status') === 'active';
                    const pageAsNumber = +(searchParams.get('page') ?? 1);
                    const perPageAsNumber = +(searchParams.get('per_page') ?? 10);

                    const params = {
                        page: isNaN(pageAsNumber) ? 1 : pageAsNumber > 0 ? pageAsNumber : 1,
                        per_page: isNaN(perPageAsNumber)
                            ? 10
                            : [10, 25, 50, 100].includes(perPageAsNumber)
                            ? perPageAsNumber
                            : 10,
                    };

                    if (showDeleted) {
                        params.api_key_status = 'active';
                    }

                    dispatch(fetchApiKeys(params));
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const toggle = useCallback(() => setOpen(p => !p), [setOpen]);

    return (
        <>
            <GridActionsCellItem
                title="Delete"
                icon={<Trash />}
                color="error"
                label="Delete"
                onClick={() => setOpen(true)}
            />
            <Popup open={open} setOpen={setOpen}>
                <CodeSnippet badgeVariant={BADGE_VARIANTS.delete} />
                <PopupContent
                    title="Are you sure you want to delete this API?"
                    description="This action is irreversible and any integrations relying on this API will stop functioning. If you're certain, please confirm your choice below."
                />
                <PopupActions
                    onCancel={toggle}
                    onSubmit={handleDelete}
                    submitProps={{ color: 'error' }}
                    loading={loading}
                    submitContent="Delete"
                />
            </Popup>
        </>
    );
};

export default ModalDeleteApiKey;
