import { ReactComponent as Stars } from 'images/Stars.svg';
import { ReactComponent as TrustLogo } from 'images/Trustpilot logo.svg';

const AuthBanner = () => {
    return (
        <aside className="auth-banner">
            <div className="auth-banner__top">
                <div className="trustpilot-label body3">
                    <div className="opacity-60">Excellent</div>
                    <Stars />
                    <TrustLogo />
                </div>
                <h1>Gather Data, Effortlessly</h1>
                <p className="subtitle1">
                    Tailored to meet all your Static ISP, Residential and Datacenter proxy needs.
                </p>
            </div>
        </aside>
    );
};

export default AuthBanner;
