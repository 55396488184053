import './styles.scss';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import WithSidebarLayout from 'Layout/WithSidebarLayout';
import Button from 'components/_common/Button';
import PlansInfoCard from 'components/PlansInfoCard';
import ProductBox from './ProductBox';

import ROUTES from 'helpers/routes';
import { ReactComponent as Icon } from 'icons/chevron-left.svg';
import OrderSummarySidebar from 'components/Sidebars/OrderSummarySidebar';
import BillingInfoCard from '../../components/BillingInfoCard';
import ModalSuccessfullyPaidInvoice from 'components/Modals/ModalSuccessfullyPaidInvoice';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import ModalReconfigurationTopUp from 'components/Modals/ModalReconfigurationTopUp';
import showMessage from 'components/NoticeProvider/showMessage';

import {
    selectCountriesOptionsDatacenter,
    selectCountriesOptionsISP,
    selectProducts,
    selectProductsIsLoading,
    setCalculationInfo,
} from 'store/slices/products';
import { selectActiveResidentialServiceId, selectCustomer } from 'store/slices/customer';
import { fetchReconfigureOptions, selectImmediateData, setReconfigureParams } from 'store/slices/reconfigureService';
import CheckoutService from 'api/services/CheckoutService';
import PaymentService from 'api/services/PaymentService';
import ServicesService from 'api/services/ServicesService';

import { gb_max_real, ip_max, residential_marks } from './constants';
import {
    convertToDollar,
    getPeriodName,
    getPriceIDByCoutryCode,
    getPriceTierAmount,
    getPeriodOptions,
    sortPrices,
} from './helpers';
import { filterPaymentParamsByPlatform, service_types } from 'helpers/services';
import { withoutEmptyValues } from 'helpers';
import { PAYMENT_METHODS } from 'helpers/payments';
import useMediaQuery from 'hooks/useMediaQuery';
import { trackSession } from 'trackers/UtmTracker';

const ProductPage = ({ currentProduct }) => {
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery('desktop');
    const [searchParams] = useSearchParams();
    const isLoading = useSelector(selectProductsIsLoading);
    const allProducts = useSelector(selectProducts);
    const isp_options = useSelector(selectCountriesOptionsISP);
    const datacenter_options = useSelector(selectCountriesOptionsDatacenter);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const { credit_balance } = useSelector(selectCustomer);
    const basicReconfigureData = useSelector(selectImmediateData);

    const [validatedData, setValidatedData] = useState({});

    const [loadingCode, setLoadingCode] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [discountError, setDiscountError] = useState(false);
    const [state, setState] = useState(1);
    const { product_type, product_prices, title } = currentProduct;
    const period_options = getPeriodOptions(product_prices);
    const sorted_product_prices = sortPrices(product_prices);

    const isResidential = product_type === service_types.residential;

    const valueQuery = searchParams.get('value');
    const isReconfigure = searchParams.get('reconfigure');
    const isValueQueryValid = valueQuery && !Number.isNaN(+valueQuery);

    const countries_options = useMemo(() => {
        return product_type === service_types.isp
            ? isp_options
            : product_type === service_types.datacenter
            ? datacenter_options
            : [];
    }, [product_type, isp_options, datacenter_options]);

    const [sliderValue, setSliderValue] = useState(
        isResidential
            ? isValueQueryValid
                ? residential_marks.find(mark => mark.realValue === +valueQuery) || residential_marks[0]
                : residential_marks[0]
            : isValueQueryValid
            ? +valueQuery
            : 1,
    );
    const [period, setPeriod] = useState(period_options[0].value);
    const [country, setCountry] = useState(countries_options[0]);

    const isNumber = typeof sliderValue === 'number';
    const value = isNumber ? sliderValue : sliderValue.realValue;

    const [pricePerUnit, setPricePerUnit] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [quantityText, setQuantityText] = useState(isNumber ? value : sliderValue.label);
    const [currentPriceTiers, setCurrentPriceTiers] = useState(sorted_product_prices?.[period]?.price_tiers);
    const [periodName, setPeriodName] = useState(getPeriodName(sorted_product_prices?.[period]));

    const [discountCode, setDiscountCode] = useState('');
    const [discount, setDiscount] = useState(1);

    const [paymentPlatform, setPaymentPlatform] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentCoin, setPaymentCoin] = useState('');

    const [isSuccessfullyPaidModalOpen, setIsSuccessfullyPaidModalOpen] = useState(false);
    const [succesfullyPaidModalRedirectUrl, setSuccesfullyPaidModalRedirectUrl] = useState(null);
    const [isReconfigureModalOpen, setIsReconfigureModalOpen] = useState(false);
    const [isReconfigureLoading, setIsReconfigureLoading] = useState(false);
    const [reconfigureData, setReconfigureData] = useState(null);
    const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

    const initialPrice = convertToDollar(currentPriceTiers?.[0]?.price_tier_amount);
    const selected_price_id = getPriceIDByCoutryCode(
        allProducts,
        sorted_product_prices[period],
        country?.country_id || null,
        product_type,
    );

    const prefferedCardPlatform = process.env.REACT_APP_PREFFERED_CARD_PROCESSOR;
    const prefferedCryptoPlatform = process.env.REACT_APP_PREFFERED_CRYPTO_PROCESSOR;
    const disabled = !country?.product_instock && product_type !== service_types.residential;
    const isResidentialReconfigure = isReconfigure && product_type === service_types.residential;
    const balanceDifference = credit_balance - reconfigureData?.total;

    const params = withoutEmptyValues({
        line_items: [{ item_price_id: selected_price_id, item_quantity: value }],
        promotional_code: discountCode,
        payment_platform:
            paymentPlatform === PAYMENT_METHODS.CARD
                ? prefferedCardPlatform
                : paymentPlatform === PAYMENT_METHODS.CRYPTO
                ? prefferedCryptoPlatform
                : paymentPlatform,
        payment_method_id: paymentMethod,
        coinpayments_currency: paymentCoin,
    });

    const handleSubmitCode = async () => {
        try {
            setLoadingCode(true);
            await CheckoutService.calculate(params)
                .then(response => {
                    const newData = response.data.data;
                    const { before_discount_total, discount } = newData;
                    setValidatedData(newData);
                    setDiscountError(false);
                    setDiscount(1 - discount / before_discount_total);
                })
                .catch(() => {
                    setDiscountError(true);
                })
                .finally(() => {
                    setLoadingCode(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleSubmit = async () => {
        try {
            if (state === 1) {
                setLoadingSubmit(true);
                await CheckoutService.calculate(params)
                    .then(response => {
                        const newData = response.data.data;
                        if (!newData?.credit_only_checkout && newData?.total_after_applied_credit < 500) {
                            return setIsTopUpModalOpen(true);
                        }
                        const { before_discount_total, discount } = newData;
                        setValidatedData(newData);
                        setDiscountError(false);
                        setDiscount(1 - discount / before_discount_total);
                        dispatch(setCalculationInfo(newData));
                        setState(2);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .finally(() => {
                        setLoadingSubmit(false);
                    });
            }
            if (state === 2) {
                setLoadingSubmit(true);
                const newParams = filterPaymentParamsByPlatform(params.payment_platform, params);
                const utm_information = trackSession();
                await CheckoutService.create({ ...newParams, utm_information })
                    .then(response => {
                        const redirect_url = response?.data?.data?.redirect_url;
                        if (response.data?.data?.invoice_is_paid) {
                            setIsSuccessfullyPaidModalOpen(true);
                            setSuccesfullyPaidModalRedirectUrl(redirect_url);
                        } else {
                            window?.open(redirect_url, '_self');
                        }
                    })
                    .finally(() => {
                        setLoadingSubmit(false);
                    });
            }
        } catch (e) {
            return false;
        }
    };

    const handleSubmitReconfigure = async () => {
        const residentialReconfigureParams = {
            service_id: active_residential_service_id,
            end_of_period: false,
            service_price_id: sorted_product_prices[period]?.price_id,
            service_quantity: value,
            disabled:
                value < basicReconfigureData?.min_service_quantity ||
                value > basicReconfigureData?.max_service_quantity,
        };

        try {
            setLoadingSubmit(true);
            setIsReconfigureLoading(true);
            ServicesService.reconfigureCalculate(residentialReconfigureParams)
                .then(response => {
                    setReconfigureData(response.data);
                    const balanceDifference = credit_balance - response.data?.total;
                    if (balanceDifference < 0) {
                        return setIsTopUpModalOpen(true);
                    }
                    dispatch(setReconfigureParams(residentialReconfigureParams));
                    // @ts-ignore
                    dispatch(fetchReconfigureOptions(active_residential_service_id));
                    setIsReconfigureModalOpen(true);
                    showMessage(response.data.message, 'success');
                })
                .finally(() => {
                    setIsReconfigureLoading(false);
                    setLoadingSubmit(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleBackToFirstState = () => {
        setState(1);
        setValidatedData(null);
        setPaymentCoin('');
        dispatch(setCalculationInfo({}));
    };

    useEffect(() => {
        dispatch(setCalculationInfo({}));
        PaymentService.search();
    }, []);

    useEffect(() => {
        setCurrentPriceTiers(sorted_product_prices?.[period]?.price_tiers);
        setPeriodName(getPeriodName(sorted_product_prices?.[period]));
    }, [isLoading, period, sorted_product_prices]);

    useEffect(() => {
        if (isNumber) {
            if (value > ip_max) {
                setSliderValue(ip_max);
            }
        } else {
            if (value > gb_max_real) {
                setSliderValue(gb_max_real);
            }
        }
    }, [value]);

    useEffect(() => {
        if (currentPriceTiers) {
            setPricePerUnit(convertToDollar(getPriceTierAmount(value, currentPriceTiers)));
            setFinalPrice(Number((pricePerUnit * value).toFixed(2)));
            setQuantityText(isNumber ? `${value} IP` : sliderValue.label);
        }
    }, [value, pricePerUnit, isNumber, sliderValue, currentPriceTiers]);

    useEffect(() => {
        const US = countries_options.find(country => country.country_id === 'us' && country.product_instock);
        const inStockCountry = countries_options.find(country => country.product_instock);
        setCountry(US || inStockCountry || countries_options[0] || null);
    }, [countries_options]);

    return (
        <>
            {state === 1 && (
                <Button
                    variant="outlined"
                    containerStyle="mb-24"
                    className="bg-white"
                    to={ROUTES.products}
                    Icon={<Icon />}
                >
                    Back to all
                </Button>
            )}
            {state === 2 && (
                <Button
                    variant="outlined"
                    className="bg-white"
                    containerStyle="mb-24"
                    Icon={<Icon />}
                    onClick={handleBackToFirstState}
                >
                    Back
                </Button>
            )}
            <WithSidebarLayout
                bottom={!isDesktop && <PlansInfoCard isResidentialReconfigure={isResidentialReconfigure} />}
                aside={
                    <OrderSummarySidebar
                        state={state}
                        loadingCode={loadingCode}
                        loadingSubmit={loadingSubmit}
                        disabled={disabled}
                        location={country}
                        name={title}
                        finalPrice={finalPrice}
                        onSubmit={isResidentialReconfigure ? handleSubmitReconfigure : handleSubmit}
                        periodName={periodName}
                        quantityText={quantityText}
                        pricePerUnit={pricePerUnit}
                        initialPrice={initialPrice}
                        productType={product_type}
                        discountCode={discountCode}
                        onChangeDiscount={setDiscountCode}
                        discountError={discountError}
                        onDiscountSubmit={handleSubmitCode}
                        discount={discount}
                        isResidentialReconfigure={isResidentialReconfigure}
                    />
                }
            >
                {state === 1 && (
                    <>
                        <ProductBox
                            loadingSubmit={loadingSubmit}
                            period={period}
                            country={country}
                            changePeriod={setPeriod}
                            sliderValue={sliderValue}
                            changeCountry={setCountry}
                            productData={currentProduct}
                            sliderChange={setSliderValue}
                            periodOptions={period_options}
                            countriesOptions={countries_options}
                            isResidentialReconfigure={isResidentialReconfigure}
                            serviceId={active_residential_service_id}
                        />
                        {isDesktop && <PlansInfoCard isResidentialReconfigure={isResidentialReconfigure} />}
                    </>
                )}
                {state === 2 && (
                    <BillingInfoCard
                        disabled={loadingSubmit}
                        data={validatedData}
                        paymentPlatform={paymentPlatform}
                        setPaymentPlatform={setPaymentPlatform}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        paymentCoin={paymentCoin}
                        setPaymentCoin={setPaymentCoin}
                    />
                )}
            </WithSidebarLayout>
            <ModalSuccessfullyPaidInvoice
                open={isSuccessfullyPaidModalOpen}
                setOpen={setIsSuccessfullyPaidModalOpen}
                redirectUrl={succesfullyPaidModalRedirectUrl}
                closeTo={ROUTES.products}
            />
            <ModalReconfigureService
                isResidential
                setOpen={setIsReconfigureModalOpen}
                open={isReconfigureModalOpen}
                initialReconfigureData={reconfigureData}
                isExistingLoading={isReconfigureLoading}
            />
            <ModalReconfigurationTopUp
                description={
                    isResidentialReconfigure
                        ? `This residential bandwidth top up will cost $${
                              reconfigureData?.total / 100
                          } and exceeds your account balance. Please top up your account credit balance to proceed`
                        : 'Please top up your store credit prior to this purchase.'
                }
                value={isResidentialReconfigure ? -balanceDifference / 100 : 5}
                open={isTopUpModalOpen}
                setOpen={setIsTopUpModalOpen}
            />
        </>
    );
};

export default ProductPage;
