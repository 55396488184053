import { useState } from 'react';
import { useSelector } from 'react-redux';

import Tabs from 'components/_common/Tabs';
import PageHeader from 'components/PageHeader';
import StatisticTab from './Tabs/StatisticTab';
import ConfiguratorTab from './Tabs/ConfiguratorTab';
import NoData from './NoData';

import { selectResidentialSummary, selectResidentialSummaryLoading } from 'store/slices/services';

const tabsData = [
    { value: '1', label: 'Configurator', content: <ConfiguratorTab /> },
    { value: '2', label: 'Statistics', content: <StatisticTab /> },
];

const Residential = () => {
    const [value, setActiveTab] = useState(tabsData[0].value);

    const handleChange = (_, newValue) => setActiveTab(newValue);

    const residentialSummary = useSelector(selectResidentialSummary);
    const residentialSummaryLoading = useSelector(selectResidentialSummaryLoading);

    return (
        <>
            <PageHeader title="Generate Residential Proxies" />
            {!residentialSummaryLoading && residentialSummary.residential_bytes == 0 ? (
                <NoData />
            ) : (
                <Tabs tabs={tabsData} onChange={handleChange} value={value} />
            )}
        </>
    );
};

export default Residential;
