import ExportButton from 'components/Buttons/ExportButton';
import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import { useState } from 'react';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import { selectServicesTableData } from 'store/slices/services';
import { useSelector } from 'react-redux';

const options = [{ value: 'CSV', label: 'CSV' }];
const header = `Status,Service,Total,Due Date`;

const ModalExportServiceList = () => {
    const [open, setOpen] = useState(false);
    const data = useSelector(selectServicesTableData);
    const [value, setValue] = useState(options[0].value);
    const handleClick = () => {
        try {
            const fileName = `Export ${dayjs().format('DD-MM-YYYY (HH-mm)')}.csv`;
            const blob = new Blob(
                [header, ...data.map(item => `\n ${item.status},${item.service.name},${item.total},${item.service_expiry_datetime}`)],
                { type: 'text/csv;charset=utf-8' },
            );
            FileSaver.saveAs(blob, fileName);
        } finally {
            setOpen(false);
        }
    };

    return (
        <ControledDialog
            title="Export"
            Submit={<Button onClick={handleClick}>Export</Button>}
            setOpen={setOpen}
            open={open}
            Trigger={<ExportButton />}
        >
            <p className="body2">Export proxies attached to each service</p>
            <RadioGroup label="Output format" items={options} value={value} onChange={setValue} />
        </ControledDialog>
    );
};

export default ModalExportServiceList;
