import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchResidentialSummary } from 'store/slices/services';
import { selectActiveResidentialServiceId } from 'store/slices/customer';

import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';

import ModalPauseUnpauseService from 'components/Modals/ModalPauseUnpauseService';
import InfoBanner from 'components/InfoBanner';
import GenerateProxyForm from '../items/GenerateProxyForm';
import TerminalProxies from '../items/TerminalProxies';
import BandwidthWarnings from '../items/BandwidthWarnings';

import '../styles.scss';

const ConfiguratorTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchResidentialSummary());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BandwidthWarnings />

            <section className="configurator-tab">
                <GenerateProxyForm />
                <TerminalProxies />
                <InfoBanner
                    article={process.env.REACT_APP_API_DOCUMENTATION_RESIDENTIAL_LINK}
                    marginTop={0}
                    variant="small"
                />

                <ModalPauseUnpauseService
                    open={isUnpauseModalOpen}
                    setOpen={setIsUnpauseModalOpen}
                    customContent={<p>Service is paused. Please unpause the service to reconfigure.</p>}
                    service_id={active_residential_service_id}
                    onClick={() => navigate(`${ROUTES.products}/${service_types.residential}`)}
                />
            </section>
        </>
    );
};

export default ConfiguratorTab;
