import '../styles.scss';

import Chip from '@mui/material/Chip';

import TooltipComponent from 'components/_common/Tooltip';

const ListCell = ({ data, max_size = 3 }) => {
    const items = data.slice(0, max_size);
    const last = data.slice(max_size);

    const others = last.join('\n');

    const body_text = `+${last.length}`;
    const show_label = !!last.length;

    return (
        <div className="list-cell">
            <div className="list-cell__main">
                {items.map((item, index) => (
                    <div key={`${item}-${index}`} title={item}>
                        {item}
                    </div>
                ))}
            </div>
            {show_label && (
                <TooltipComponent
                    placement="bottom"
                    text={others}
                    body={
                        <Chip
                            label={body_text}
                            variant="outlined"
                            color="default"
                            size="small"
                            sx={{ marginLeft: '8px', borderRadius: '4px' }}
                        />
                    }
                />
            )}
        </div>
    );
};

export default ListCell;
