import React from 'react';
import Box from '@mui/material/Box';

import { ReactComponent as Globe } from 'icons/globe.svg';

const DropdownFlag = ({ label, country_code, size = 20 }) => {
    if (!country_code) return null;

    return country_code === 'All' ? (
        <Globe width={size} height={size} />
    ) : (
        <Box component="span" borderRadius="4px" overflow="hidden" display="inline-flex">
            <img
                loading="lazy"
                width={size}
                height={size}
                srcSet={`https://flagcdn.com/w80/${country_code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w40/${country_code.toLowerCase()}.png`}
                style={{
                    objectFit: 'cover',
                    width: size,
                    height: size,
                }}
                alt={label}
            />
        </Box>
    );
};

export default DropdownFlag;
