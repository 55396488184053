import Chip from '@mui/material/Chip';

import '../styles.scss';

const statuses = {
    active: 'success',
    inactive: 'default',
    paid: 'success',
    200: 'success',
    201: 'success',
    400: 'error',
    402: 'warning',
    422: 'warning',
    404: 'error',
    500: 'error',
    canceled: 'error',
    overdue: 'error',
    deleted: 'error',
    open: 'warning',
};

const ScheduleStatusCell = ({ status }) => {
    const color = statuses[status] || 'default';

    return <Chip color={color} variant="filled" label={status} size="small" sx={{ textTransform: 'capitalize' }} />;
};

export default ScheduleStatusCell;
