import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/_common/Button';
import AccountSettingsCard from './AccountSettingsCard';

import GoogleAuthService, { authorization_type } from 'api/services/GoogleAuthService';
import { extractToltReferralUUID } from 'api/helpers';

import { selectCustomer } from 'store/slices/customer';
import { customerRetrieve } from 'store/slices/customer';

const GoogleConnect = () => {
    const dispatch = useDispatch();
    const { customer_google_oauth_id } = useSelector(selectCustomer);

    const [loading, setLoading] = useState(false);

    const text = `${customer_google_oauth_id ? 'Disconnect' : 'Connect'} Google Account`;

    const handleClick = async () => {
        try {
            setLoading(true);
            const params = {
                authorization_type: authorization_type.connect,
                redirect_url: process.env.REACT_APP_DASHBOARD_URL,
            };
            const toltReferralUUID = extractToltReferralUUID();
            if (toltReferralUUID) {
                params.customer_tolt_referral_id = toltReferralUUID;
            }

            const redirect_url = GoogleAuthService.generate_url(params);
            window.open(redirect_url, '_self');
            dispatch(customerRetrieve());
        } catch (e) {
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <AccountSettingsCard
            title="Google Account"
            desc="Stay in control of your account's active sessions, ensuring a secure and personalized online experience."
        >
            <Button
                size="large"
                onClick={handleClick}
                loading={loading}
                variant="outlined"
                iconName="google"
                className="bg-white"
            >
                {text}
            </Button>
        </AccountSettingsCard>
    );
};

export default GoogleConnect;
