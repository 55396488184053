// @ts-nocheck
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import CheckoutService from 'api/services/CheckoutService';
import { trackSession } from 'trackers/UtmTracker';
import { PAYMENT_METHODS, VISIBLE_PAYMENT_PLATFORMS } from 'helpers/payments';
import { withoutEmptyValues } from 'helpers';

import Popup from 'components/_common/Popup';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';
import BillPreview from 'components/BillPreview';
import { BADGE_VARIANTS } from 'components/_common/PopupBadge';
import AllowedPaymentPlatforms from 'components/AllowedPaymentPlatforms';
import TextInput from 'components/_common/TextInput';
import ModalSuccessfullyPaidInvoice from '../ModalSuccessfullyPaidInvoice';

import './styles.scss';

const ModalReconfigurationTopUp = ({ open, setOpen, value, description = '' }) => {
    const [internalValue, setInternalValue] = useState(0);
    const [isSuccessfullyPaidModalOpen, setIsSuccessfullyPaidModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allowed_payment_platforms, setAllowedPaymentPlatforms] = useState([]);
    const [platform, setPlatform] = useState(null);
    const [succesfullyPaidModalRedirectUrl, setSuccesfullyPaidModalRedirectUrl] = useState(null);

    const disableNext = !internalValue || internalValue < value || loading;
    const hasPlatforms = !!allowed_payment_platforms.length;
    const prefferedCardPlatform = process.env.REACT_APP_PREFFERED_CARD_PROCESSOR;
    const prefferedCryptoPlatform = process.env.REACT_APP_PREFFERED_CRYPTO_PROCESSOR;

    const params = withoutEmptyValues({
        is_top_up: true,
        top_up_amount: internalValue * 100,
        payment_platform:
            platform === PAYMENT_METHODS.CARD
                ? prefferedCardPlatform
                : platform === PAYMENT_METHODS.CRYPTO
                ? prefferedCryptoPlatform
                : platform,
    });

    const handleNext = async () => {
        try {
            setLoading(true);
            await CheckoutService.calculate(params)
                .then(response => {
                    setAllowedPaymentPlatforms(
                        response.data.data.allowed_payment_platforms.filter(i => VISIBLE_PAYMENT_PLATFORMS.includes(i)),
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const utm_information = trackSession();
            await CheckoutService.create({ ...params, utm_information })
                .then(response => {
                    const redirect_url = response?.data?.data?.redirect_url;
                    if (response.data?.data?.invoice_is_paid) {
                        setSuccesfullyPaidModalRedirectUrl(redirect_url);
                        setIsSuccessfullyPaidModalOpen(true);
                    } else {
                        window?.open(redirect_url, '_self');
                    }
                    setOpen(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        setAllowedPaymentPlatforms([]);
        setInternalValue(value);
    }, [open]);

    return (
        <>
            <Popup open={open} setOpen={setOpen}>
                <BillPreview badgeProps={{ variant: BADGE_VARIANTS.money_wavy }} />
                <PopupContent title="Out of money" description={description}>
                    <Stack mt={2} spacing={3}>
                        <TextInput
                            type="number"
                            min={value}
                            disabled={hasPlatforms}
                            label="Enter amount $"
                            value={internalValue}
                            onChange={setInternalValue}
                        />
                        {hasPlatforms && (
                            <AllowedPaymentPlatforms
                                allowed_payment_platforms={allowed_payment_platforms}
                                value={platform}
                                onChange={setPlatform}
                            />
                        )}
                    </Stack>
                </PopupContent>
                <PopupActions
                    submitContent={hasPlatforms ? 'Confirm' : 'Next'}
                    loading={loading}
                    onSubmit={hasPlatforms ? handleSubmit : handleNext}
                    onCancel={() => setOpen(false)}
                    submitProps={{ disabled: hasPlatforms && disableNext, sx: { backgroundColor: 'primary.500' } }}
                />
            </Popup>

            <ModalSuccessfullyPaidInvoice
                open={isSuccessfullyPaidModalOpen}
                setOpen={setIsSuccessfullyPaidModalOpen}
                redirectUrl={succesfullyPaidModalRedirectUrl}
            />
        </>
    );
};

export default ModalReconfigurationTopUp;
