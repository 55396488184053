import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectResidentialList } from 'store/slices/residentialLedger';

import CopyButton from 'components/Buttons/CopyButton';
import ModalExportTerminalInfo from 'components/Modals/ModalExportTerminalInfo';

const TerminalProxies = () => {
    const data = useSelector(selectResidentialList);
    const [open, setOpen] = useState(false);
    const disabled = !((data?.length ?? 0) > 0);

    return (
        <div className="terminal-proxies">
            <div className="terminal-proxies__screen">
                {disabled ? (
                    <>
                        <p># Your proxy configuration will be displayed here</p>
                        <p># Select parameters and click "Generate" to see the output</p>
                    </>
                ) : (
                    data?.map((item, index) => <p key={index}>{item}</p>)
                )}
            </div>
            {disabled ? null : (
                <div className="terminal-proxies__footer">
                    <CopyButton copyValue={data?.join('\n')} disabled={disabled} />
                    <ModalExportTerminalInfo data={data} setOpen={setOpen} open={open} disabled={disabled} />
                </div>
            )}
        </div>
    );
};

export default TerminalProxies;
