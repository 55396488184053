// @ts-nocheck
import Popup from 'components/_common/Popup';
import LocationPreview from './LocationPreview';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';

import './styles.scss';

const ModalGenerateProxyWarning = ({ setOpen, open }) => {
    return (
        <Popup open={open} setOpen={setOpen}>
            <LocationPreview />
            <PopupContent
                title="Warning"
                description="We recommend leaving City and Internet Provider set to Automatic unless required by your use-case. Using ASN and City selection can restrict available pool size which could lead todegraded performance."
            />
            <PopupActions
                single
                onSubmit={() => setOpen(false)}
                submitContent="Continue"
                submitProps={{
                    variant: 'contained',
                    sx: { backgroundColor: 'primary.500' },
                }}
            />
        </Popup>
    );
};

export default ModalGenerateProxyWarning;
