import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ASNService from 'api/services/ASNService';

export const fetchASN = createAsyncThunk(
    'fetchASN',
    async params => {
        const response = await ASNService.search(params);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().ASNState.ASN;
            return !loading;
        },
    },
);

export const fetchASNDropdown = async params => {
    const response = await ASNService.search(params);
    return response.data.data.map(item => ({
        value: item.asn_id,
        label: `${item.asn_name} [${item.asn_id}]`,
        showLabel: item.asn_ip_address_count < 250000,
        labelColor: 'warning',
        labelText: 'Low Availability',
    }));
};

const ASNSlice = createSlice({
    name: 'ASNSlice',
    initialState: {
        ASN: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchASN.pending, state => {
                state.ASN.loading = true;
            })
            .addCase(fetchASN.fulfilled, (state, action) => {
                state.ASN.loading = false;
                state.ASN.data = action.payload.data;
                state.ASN.total = action.payload.total_count;
            })
            .addCase(fetchASN.rejected, (state, action) => {
                state.ASN.loading = false;
                state.ASN.error = action.error;
            });
    },
});

export const selectASN = createSelector([state => state.ASNState.ASN], ASN => ASN.data);

export const selectASNOptions = createSelector([selectASN], data =>
    data.map(item => ({
        value: item.asn_id,
        label: item.asn_name,
        showLabel: item.asn_ip_address_count < 250000,
        labelColor: 'warning',
        labelText: 'Low Availability',
    })),
);

export const selectASNLoading = createSelector([state => state.ASNState.ASN], ASN => ASN.loading);

export const selectASNTotal = createSelector([state => state.ASNState.ASN], ASN => ASN.total);

export default ASNSlice.reducer;
