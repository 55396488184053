import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ApiRequestsService from 'api/services/ApiRequestsService';

export const fetchApiRequests = createAsyncThunk('fetchApiRequests', async params => {
    const response = await ApiRequestsService.search(params);
    return response.data;
});

export const fetchApiRequestsAnalytict = createAsyncThunk(
    'fetchApiRequestsAnalytict',
    async params => {
        const response = await ApiRequestsService.analytics(params);
        return response.data;
    },
    {
        condition: (_, api) => {
            return !api.getState().apiRequestsState.analytics.loading;
        },
    },
);

const ApiRequestsSlice = createSlice({
    name: 'ApiRequestsSlice',
    initialState: {
        apiRequests: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
        analytics: {
            loading: false,
            data: [],
            error: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchApiRequests.pending, state => {
                state.apiRequests.loading = true;
            })
            .addCase(fetchApiRequests.fulfilled, (state, action) => {
                state.apiRequests.loading = false;
                state.apiRequests.data = action.payload.data;
                state.apiRequests.total = action.payload.total_count;
            })
            .addCase(fetchApiRequests.rejected, (state, action) => {
                state.apiRequests.loading = false;
                state.apiRequests.error = action.error;
            });
        builder
            .addCase(fetchApiRequestsAnalytict.pending, state => {
                state.analytics.loading = true;
            })
            .addCase(fetchApiRequestsAnalytict.fulfilled, (state, action) => {
                state.analytics.loading = false;
                state.analytics.data = action.payload.data;
            })
            .addCase(fetchApiRequestsAnalytict.rejected, (state, action) => {
                state.analytics.loading = false;
                state.analytics.error = action.error;
            });
    },
});

export const selectApiRequestsAnalytict = createSelector(
    [state => state.apiRequestsState.analytics],
    analytics => analytics.data,
);
export const selectApiRequestsAnalytictChartData = createSelector([selectApiRequestsAnalytict], analytics => {
    const chartData = { labels: [], errors: [], requests: [], successful: [] };

    analytics?.intervals?.forEach(item => {
        const { interval, error, requests, successful } = item;
        chartData.labels.push(interval);
        chartData.errors.push(error);
        chartData.requests.push(requests);
        chartData.successful.push(successful);
    });

    return chartData;
});
export const selectApiRequestsAnalytictMetrics = createSelector([selectApiRequestsAnalytict], data => {
    const { total_error, total_requests, total_successful } = data;
    return [
        { title: 'total requests', value: total_requests || 0 },
        { title: 'total successful', value: total_successful || 0 },
        { title: 'total errors', value: total_error || 0 },
    ];
});

export const selectApiRequestsAnalytictLoading = createSelector(
    [state => state.apiRequestsState.analytics],
    analytics => analytics.loading,
);

export const selectApiRequests = createSelector(
    [state => state.apiRequestsState.apiRequests],
    apiRequests => apiRequests.data,
);
export const selectApiRequestsTableData = createSelector([selectApiRequests], data =>
    data.map(item => ({
        id: item.api_request_id,
        status: item.api_request_status_code,
        method: item.api_request_method,
        api_request_path: item.api_request_path,
        ip_address: item.api_request_client_ip_address,
        user_agent: item.api_request_client_user_agent,
        time: item.api_request_creation_datetime,
    })),
);

export const selectApiRequestsLoading = createSelector(
    [state => state.apiRequestsState.apiRequests],
    apiRequests => apiRequests.loading,
);

export const selectApiRequestsTotal = createSelector(
    [state => state.apiRequestsState.apiRequests],
    apiRequests => apiRequests.total,
);

export default ApiRequestsSlice.reducer;
