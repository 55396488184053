import './styles.scss';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Dropdown from './Dropdown';

const ProductDropdown = ({ value, onChange }) => {
    return (
        <FormControl fullWidth>
            <FormLabel focused={false}>Link To Product</FormLabel>

            <Dropdown value={value} onChange={onChange} />
        </FormControl>
    );
};

export default ProductDropdown;
