import './styles.scss';
import SwitchComponent from '@mui/material/Switch';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, FormLabel } from '@mui/material';

const IOSSwitch = styled(props => (
    <SwitchComponent focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--ping-Brand-brand-500)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'var(--ping-Brand-brand-500)',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'var(--ping-Gray-grey-100)',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const Switch = ({ value, onChange, disabled = false, label = '', sx = {} }) => {
    const handleChange = event => onChange(event.target.checked);

    return (
        <FormControl sx={{ flexDirection: 'row', gap: '8px', ...sx }}>
            {label && <FormLabel focused={false}>{label}</FormLabel>}
            <IOSSwitch disabled={disabled} checked={value} onChange={handleChange} className="custom-switch" />
        </FormControl>
    );
};
