import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, FormLabel } from '@mui/material';

import useMediaQuery from 'hooks/useMediaQuery';

import './styles.scss';

const ExpiryDatePicker = ({ value, onChange, label = '', onError }) => {
    const handleChange = newValue => onChange(newValue);
    const handleOnError = error => onError(error);
    const isMobile = useMediaQuery('mobile');

    return (
        <FormControl fullWidth>
            {label && <FormLabel focused={false}>{label}</FormLabel>}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    className="custom-date-picker"
                    views={['year', 'month']}
                    value={value ? value : null}
                    onChange={handleChange}
                    minDate={dayjs()}
                    format="MM / YYYY"
                    disableOpenPicker={!isMobile}
                    onError={handleOnError}
                    slotProps={{
                        textField: {
                            variant: 'outlined',
                            size: 'small',
                        },
                    }}
                />
            </LocalizationProvider>
        </FormControl>
    );
};

export default ExpiryDatePicker;
