// @ts-nocheck
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Popup from 'components/_common/Popup';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';
import Mastercard from './Mastercard';

import './styles.scss';

const ModalSuccessfullyPaidInvoice = ({ open, setOpen, redirectUrl, closeTo = null }) => {
    const navigate = useNavigate();

    return (
        <Popup open={open} setOpen={setOpen}>
            <Mastercard />

            <PopupContent
                title="Paid successfully"
                description="Your payment has been successful and your service will be processed shortly. "
            />
            <PopupActions
                cancelContent="Close"
                onCancel={() => navigate(closeTo)}
                onSubmit={() => window?.open(redirectUrl, '_blank', 'noopener')}
                submitContent="View invoice"
                submitProps={{
                    variant: 'contained',
                    sx: { backgroundColor: 'primary.500' },
                }}
            />
        </Popup>
    );
};

export default ModalSuccessfullyPaidInvoice;
