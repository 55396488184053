import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import ROUTES from 'helpers/routes';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import { getHighestPriorityLineItem, getParsedLineItems } from 'pages/Product/helpers';

import WithSidebarLayout from 'Layout/WithSidebarLayout';
import MessageCard from 'components/MessageCard';
import SuccessfulSidebar from './SuccessfulSidebar';

const OrderConfirmation = () => {
    const [searchParams] = useSearchParams();

    const lineItemsParam = searchParams.get('line_items');
    const line_items = getParsedLineItems(lineItemsParam);
    const checkout_id = searchParams.get('checkout_id');
    const totalParam = searchParams.get('total');
    const total = totalParam ? parseFloat(totalParam) / 100 : 0;
    const payment_platform = searchParams.get('payment_platform');

    const highestQuantityItem = useMemo(() => getHighestPriorityLineItem(line_items), [line_items]);

    const eventTracker = useAnalyticsEventTracker({
        name: 'purchase',
        params: {
            transaction_id: checkout_id || 'unknown',
            value: total,
            payment_platform: payment_platform || 'unknown',
            items: line_items.map(item => ({
                item_id: item.item,
                item_name: item.item,
                quantity: item.quantity,
            })),
        },
    });

    useEffect(() => {
        eventTracker();
    }, []);

    return (
        <WithSidebarLayout
            aside={<SuccessfulSidebar line_items={line_items} total={total} payment_platform={payment_platform} />}
        >
            <MessageCard
                type="good"
                buttonText="Go to your plan"
                message="Thank you for your purchase!"
                title="Order Confirmation"
                to={ROUTES.summary}
                desc="Ready to take control? Manage your plan now and explore the full potential of your enhanced connectivity."
            >
                <Typography className="price-id-label mb-0" marginBottom={0}>
                    {highestQuantityItem?.price_id}
                </Typography>
                <Typography className="checkout-id-label">{checkout_id}</Typography>
            </MessageCard>
        </WithSidebarLayout>
    );
};

export default OrderConfirmation;
