import Button from '@mui/material/Button';

import MessageText from 'components/MessageText';

import './styles.scss';

const MessageCard = ({
    title = '',
    desc = '',
    buttonText,
    customStyles = {},
    customButtonStyles = {},
    type,
    message,
    to = undefined,
    onClick = undefined,
    isLoading = false,
    children,
}) => (
    <div className="message-card box" style={customStyles}>
        <MessageText type={type} text={message} />
        <h4>{title}</h4>
        {desc ? <p className="body2">{desc}</p> : null}
        {children}
        <Button
            href={to}
            onClick={onClick}
            variant="contained"
            disabled={isLoading}
            size="large"
            sx={{ backgroundColor: 'primary.500', ...customButtonStyles }}
            disableElevation
        >
            {buttonText}
        </Button>
    </div>
);

export default MessageCard;
