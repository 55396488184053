import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import SearchableDropdownList from 'components/_common/SearchableDropdownList';
import DropdownSelectButton from './DropdownSelectButton';

import './styles.scss';

const AsnDropdown = ({ value, onChange, options = [] }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback(
        event => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl],
    );

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const id = anchorEl ? 'target-asn' : undefined;

    return (
        <>
            <DropdownSelectButton id={id} onClick={handleClick} value={value} />

            <SearchableDropdownList
                anchorEl={anchorEl}
                id={id}
                onClose={handleClose}
                open={!!anchorEl}
                value={value}
                setValue={onChange}
                renderTags={() => null}
                noOptionsText="No ISP / ASN found"
                renderOption={(props, option, { selected }) => {
                    return (
                        <li
                            {...props}
                            key={option.value}
                            className={`asn-option ${props.className} ${selected ? 'selected' : ''}`}
                        >
                            <Typography className="asn-label">
                                {option.label}&nbsp;
                                <Typography className="asn-label-id" component="span">
                                    &#40;{option.value}&#41;
                                </Typography>
                            </Typography>
                            {option.showLabel ? (
                                <Chip label={option.labelText} color={option.labelColor} size="small" />
                            ) : null}
                        </li>
                    );
                }}
                options={options}
                getOptionLabel={option => option.label ?? ''}
            />
        </>
    );
};

export default AsnDropdown;
