import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'components/_common/Table';
import CopyCell from 'components/_common/TableCells/CopyCell';
import ListCell from 'components/_common/TableCells/ListCell';
import CopyTextCell from 'components/_common/TableCells/CopyTextCell';
import Flag from 'components/_common/CountryDropdown/Items/Flag';
import TextCell from 'components/_common/TableCells/TextCell';

import { selectSelectedIDs, setIDs } from 'store/slices/proxy';
import { getServiceIcon } from 'helpers/services';

const minWidth = 140;

const ProxyListTable = ({ data, dataLoading, fetchData, total, noDataComponent, filters }) => {
    const ids = useSelector(selectSelectedIDs);
    const dispatch = useDispatch();

    const columns = React.useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Proxy ID',
                flex: 1,
                minWidth,
                renderCell: ({ value, row }) => (
                    <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: 8 }}>
                        {getServiceIcon(row.type, 25) ?? row.type}
                        <CopyTextCell text={value} />
                    </div>
                ),
            },
            {
                field: 'service_id',
                headerName: 'Service ID',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'ip_address',
                headerName: 'IP Address',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'proxy_http_port',
                headerName: 'HTTP Port',
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'proxy_socks5_port',
                headerName: 'SOCKS Port',
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'proxy_username',
                headerName: 'Username',
                minWidth: 190,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'proxy_password',
                headerName: 'Password',
                minWidth: 190,
                renderCell: ({ value }) => <CopyTextCell text={value} alignment="start" />,
            },
            {
                field: 'users',
                headerName: 'Users',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value }) => (
                    <ListCell data={value ? [...value].sort((a, b) => a.localeCompare(b)) : []} max_size={1} />
                ),
            },
            {
                field: 'country',
                headerName: 'Country',
                headerAlign: 'center',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => (
                    <CopyTextCell
                        text={value?.name}
                        hoverText={value?.name}
                        content={<Flag country_id={value.id} />}
                        alignment="center"
                    />
                ),
            },
            {
                field: 'carrier',
                headerName: 'Carrier',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value?.id} hoverText={value?.name} alignment="start" />,
            },
            {
                field: 'proxy_creation_datetime',
                headerName: 'Created',
                flex: 1,
                minWidth: 180,
                sortable: true,
                renderCell: ({ value }) => <TextCell text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />,
            },
            {
                field: 'copy',
                headerName: '',
                flex: 1,
                minWidth: 90,
                renderCell: ({ value }) => <CopyCell value={value} />,
            },
        ],
        [],
    );

    const handleSelect = value => dispatch(setIDs(value));

    return (
        <Table
            withSelection
            onRowSelectionModelChange={handleSelect}
            rowSelectionModel={ids}
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            filters={filters}
            total={total}
            noDataComponent={noDataComponent}
        />
    );
};

export default ProxyListTable;
