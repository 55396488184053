// @ts-nocheck
import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';

import { getIcon } from 'components/_common/Button/helpers';
import CreateApiKeyButton from 'components/Buttons/CreateApiKeyButton';
import TextInput from 'components/_common/TextInput';
import Popup from 'components/_common/Popup';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';
import CodeSnippet from '../ModalDeleteApiKey/CodeSnippet/CodeSnippet';

import './styles.scss';

const ModalCreateApiKey = ({ loading, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [apiKeyLabel, setApiKeyLabel] = useState('');

    const toggle = useCallback(() => setOpen(p => !p), [setOpen]);

    const submit = async () => {
        const result = await onCreate(apiKeyLabel);

        if (result === false) return;

        toggle();
    };

    if (loading) return <CreateApiKeyButton />;

    return (
        <>
            <Button
                variant="contained"
                sx={{ backgroundColor: 'primary.500' }}
                disabled={loading}
                startIcon={getIcon('plus')}
                onClick={toggle}
            >
                Create API key
                {loading && <span className="custom-button__loading-spinner" />}
            </Button>

            <Popup open={open} setOpen={setOpen}>
                <CodeSnippet />
                <PopupContent
                    title="Create API key"
                    description={'Choose the desired settings and click "Create" to generate your API key.'}
                >
                    <TextInput
                        value={apiKeyLabel}
                        onChange={setApiKeyLabel}
                        placeholder="Enter API key name"
                        maxLength={64}
                        className="api-key-input"
                    />
                </PopupContent>
                <PopupActions
                    onCancel={toggle}
                    onSubmit={submit}
                    loading={loading}
                    submitContent="Create"
                    submitProps={{ variant: 'contained', sx: { backgroundColor: 'primary.500' } }}
                />
            </Popup>
        </>
    );
};

export default ModalCreateApiKey;
