import SimpleSelect from 'components/_common/Select/SimpleSelect';
import UsersDropdown from './UsersDropdown';

import { networksSelect } from 'helpers/services';
import { metricSelect } from 'helpers';
import { graphPresetOptions } from 'helpers/constants';

export const FILTER_KEYS = {
    NETWORK: 'network',
    USERS: 'users',
    METRICS: 'metrics',
    DATE_RANGE: 'date_range',
};

const Filters = ({ users, network, preset, metricFilter, onChange }) => {
    return (
        <>
            <SimpleSelect options={graphPresetOptions} onChange={onChange(FILTER_KEYS.DATE_RANGE)} value={preset} />
            <SimpleSelect
                label="Network"
                options={networksSelect}
                value={network}
                onChange={onChange(FILTER_KEYS.NETWORK)}
                width={130}
                withIcon={true}
            />

            <UsersDropdown onChange={onChange(FILTER_KEYS.USERS)} value={users} />

            <SimpleSelect
                label="Metric"
                options={metricSelect}
                value={metricFilter}
                onChange={onChange(FILTER_KEYS.METRICS)}
                width={130}
            />
        </>
    );
};
export default Filters;
