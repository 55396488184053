import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';
import ModalDeleteApiKey from 'components/Modals/ModalDeleteApiKey/ModalDeleteApiKey';
import StatusCell from 'components/_common/TableCells/StatusCell';
import { Switch } from 'components/_common/Switch';

const ApiKeysTable = ({ data, dataLoading, fetchData, total, noDataComponent }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState(() => {
        const showOnlyActive = (searchParams.get('api_key_status') ?? 'active') === 'active';
        const pageAsNumber = +(searchParams.get('page') ?? 1);
        const perPageAsNumber = +(searchParams.get('per_page') ?? 10);

        const params = {
            page: isNaN(pageAsNumber) ? 1 : pageAsNumber > 0 ? pageAsNumber : 1,
            per_page: isNaN(perPageAsNumber) ? 10 : [10, 25, 50, 100].includes(perPageAsNumber) ? perPageAsNumber : 10,
        };

        if (showOnlyActive) {
            params.api_key_status = 'active';
        }

        return params;
    });

    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'status',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'label',
                headerName: 'Label',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'preview',
                headerName: 'Private key preview',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'api_key',
                headerName: 'API Key',
                flex: 1.2,
                minWidth: 300,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'created__sort',
                headerName: 'Created',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 80,
                renderCell: ({ row }) => {
                    return row.status !== 'deleted' && <ModalDeleteApiKey id={row.id} />;
                },
            },
        ],
        [],
    );

    useEffect(() => {
        const filtersCopy = { ...filters };

        if (filtersCopy.api_key_status !== 'active') {
            delete filtersCopy.api_key_status;
        }

        setSearchParams(filtersCopy);
        fetchData(filtersCopy);
    }, [filters]);

    return (
        <>
            <Switch
                value={filters.api_key_status !== 'active'}
                onChange={shouldShowDeleted => {
                    setFilters(prev => ({ ...prev, api_key_status: shouldShowDeleted ? '' : 'active' }));
                }}
                label="Show Deleted"
                sx={{ marginLeft: 'auto', marginBottom: '8px' }}
            />

            <Table
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={filters => setFilters(prev => ({ ...prev, ...filters }))}
                total={total}
                noDataComponent={noDataComponent}
            />
        </>
    );
};

export default ApiKeysTable;
