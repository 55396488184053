import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import './styles.scss';

const TextInput = ({
    label = '',
    onChange,
    placeholder = '',
    size = 'medium',
    type = 'text',
    maxLength = 255,
    className = undefined,
    withFormik = false,
    min = null,
    max = null,
    endAdornment = null,
    ...props
}) => {
    const handleChange = event => {
        const v = event.target.value;
        onChange(type === 'number' ? (v == '' ? v : Number(v)) : v);
    };

    return (
        <FormControl className={`custom-text-input ${className ?? ''}`} fullWidth>
            {label && <FormLabel focused={false}>{label}</FormLabel>}
            <TextField
                // @ts-ignore
                size={size}
                type={type}
                placeholder={placeholder || label}
                onChange={withFormik ? onChange : handleChange}
                inputProps={{
                    maxLength,
                    min,
                    max,
                }}
                InputProps={{
                    endAdornment,
                }}
                {...props}
            />
        </FormControl>
    );
};
export default TextInput;
