import './styles.scss';

import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ModalGenerateProxyWarning from 'components/Modals/ModalGenerateProxyWarning';
import FormatSettings from './FormatSettings';
import TargetingSettings from './TargetingSettings';
import NetworkSettings from './NetworkSettings';

import { selectResidentialListLoading, fetchResidentialList } from 'store/slices/residentialLedger';

import { withoutEmptyValues } from 'helpers';
import { proxyFormatOptions } from 'helpers/constants';
import { form_keys, protocol_options, type_options } from './constants';

const defaultValues = {
    list_count: 100,
    list_format: 'standard',
    proxy_user_id: 'default_proxy_user',
};

const initialParams = {
    [form_keys.TYPE]: type_options[0].value,
    [form_keys.COUNTRY]: '',
    [form_keys.CITY]: '',
    [form_keys.ASN]: '',
    [form_keys.PROXY_PROTOCOL]: protocol_options[0].value,
    [form_keys.PROXY_FORMAT]: '',
    [form_keys.PROXY_USER_ID]: '',
    [form_keys.QUANTITY]: undefined,
    [form_keys.AI_ENABLED]: false,
};

const formBlocks = [
    {
        title: 'Format Settings',
        content: FormatSettings,
    },
    {
        title: 'Targeting Settings',
        content: TargetingSettings,
    },
    {
        title: 'Network Settings',
        content: NetworkSettings,
    },
];

const GenerateProxyForm = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectResidentialListLoading);

    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [wasWarningModalOpen, setWasWarningModalOpen] = useState(false);
    const [params, setParams] = useState(initialParams);

    const handleChange = useCallback(
        (key, extractor) => {
            return (...args) => {
                const value = extractor ? extractor(...args) : args[0];

                switch (key) {
                    case form_keys.CITY:
                    case form_keys.ASN: {
                        return setParams(p => {
                            if (!p[key] && value && !wasWarningModalOpen) {
                                setOpenWarningModal(true);
                                setWasWarningModalOpen(true);
                            }

                            return { ...p, [key]: value };
                        });
                    }
                    case form_keys.PROXY_PROTOCOL: {
                        if (!value) {
                            return;
                        }

                        return setParams(p => {
                            const currentFormat = p[form_keys.PROXY_FORMAT] ?? '';

                            const standardOption = proxyFormatOptions.at(0).value;

                            if (!currentFormat || currentFormat.includes(value) || currentFormat === standardOption) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case protocol_options[0].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.PROXY_FORMAT]: proxyFormatOptions[1].value,
                                    };
                                // socks case
                                case protocol_options[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.PROXY_FORMAT]: proxyFormatOptions[2].value,
                                    };
                                default:
                                    return { ...p, [key]: value };
                            }
                        });
                    }
                    case form_keys.TYPE: {
                        if (!value) {
                            return;
                        } else {
                            break;
                        }
                    }
                    case form_keys.PROXY_FORMAT: {
                        const standardOption = proxyFormatOptions.at(0).value;

                        if (value === standardOption) {
                            break;
                        }

                        return setParams(p => {
                            const currentProtocol = p[form_keys.PROXY_PROTOCOL];

                            if (value.includes(currentProtocol)) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case proxyFormatOptions[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.PROXY_PROTOCOL]: protocol_options[0].value,
                                    };
                                // socks case
                                case proxyFormatOptions[2].value:
                                case proxyFormatOptions[3].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.PROXY_PROTOCOL]: protocol_options[1].value,
                                    };

                                default: {
                                    return { ...p, [key]: value };
                                }
                            }
                        });
                    }
                    case form_keys.QUANTITY:
                    case form_keys.AI_ENABLED:
                    case form_keys.COUNTRY:
                    case form_keys.PROXY_USER_ID:
                    default: {
                        break;
                    }
                }

                setParams(p => ({ ...p, [key]: value }));
            };
        },
        [wasWarningModalOpen],
    );

    const handleSubmit = e => {
        e.preventDefault();

        const filters = withoutEmptyValues(Object.assign(defaultValues, params));

        // Format object to single id, delete if the worldwide is selected
        if (!filters[form_keys.COUNTRY] || filters[form_keys.COUNTRY]?.value === 'All') {
            delete filters[form_keys.COUNTRY];
        } else {
            filters[form_keys.COUNTRY] = filters[form_keys.COUNTRY].value;
        }

        if (filters[form_keys.PROXY_USER_ID]) {
            filters[form_keys.PROXY_USER_ID] = filters[form_keys.PROXY_USER_ID].value;
        }

        if (filters[form_keys.CITY]) {
            filters[form_keys.CITY] = filters[form_keys.CITY].value;
        }

        if (filters[form_keys.ASN]) {
            filters[form_keys.ASN] = filters[form_keys.ASN].value;
        }

        dispatch(fetchResidentialList(withoutEmptyValues(filters)));
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit} className="generate-proxies-form">
            {formBlocks.map((block, idx) => (
                <Box key={idx} className="proxies-form-block">
                    <Typography className="proxies-form-block-title">{block.title}</Typography>
                    {block.content({ params, onChange: handleChange, loading })}
                </Box>
            ))}

            <ModalGenerateProxyWarning open={openWarningModal} setOpen={setOpenWarningModal} />
        </Box>
    );
};

export default GenerateProxyForm;
