import { convertToDollar } from 'helpers/payments';
import { GB, IP } from 'helpers/constants';

import { ReactComponent as CheckIcon } from 'icons/colored/circle-check-filled.svg';

import '../styles.scss';

const reconfigureDataItems = [
    {
        label: 'New quantity:',
        value: ({ data, isResidential }) => (
            <span>
                {data.service_quantity} {isResidential ? GB : IP}
            </span>
        ),
    },
    {
        label: 'New Billing date:',
        value: ({ data }) => <span>{data.due}</span>,
    },
    {
        label: 'Subscription Period:',
        value: ({ data }) => <span>{data.end_of_period ? '-' : 'Monthly'}</span>,
    },
];

const bulletListItems = [
    'Discounts are automatically applied',
    'Bandwidth will be added immediately',
    'Payment will be taken immediately',
    'Your subscription billing date will be altered immediately',
];

const BulletList = () => {
    return (
        <ul className="reconfigure-popup-data-bullet-list">
            {bulletListItems.map(item => (
                <li key={item} className="bullet-item">
                    <CheckIcon width={20} height={20} />
                    <p>{item}</p>
                </li>
            ))}
        </ul>
    );
};

const ReconfigureData = ({ data, isResidential }) => {
    return (
        <>
            <p className="reconfigure-popup-data-title">Details</p>
            <ul className="reconfigure-popup-data-list">
                {reconfigureDataItems.map(item => (
                    <li key={item.label} className="reconfigure-popup-data-item">
                        <p>{item.label}</p>
                        {item.value({ data, isResidential })}
                    </li>
                ))}
            </ul>
            <BulletList />
            <p className="reconfigure-popup-data-total h4">
                <span>Total amount:&nbsp;</span>
                <span>{convertToDollar(data.total)}</span>
            </p>
        </>
    );
};

export default ReconfigureData;
