import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { selectCalculationInfo } from 'store/slices/products';
import { service_types } from 'helpers/services';
import { getOrdinalSuffix } from 'helpers';

import List from 'components/_common/List';
import Button from 'components/_common/Button';
import DiscountCodeForm from 'components/DiscountCodeForm';
import PaymentsMethods from './Items/PaymentsMethods';
import SidebarRow from './Items/SidebarRow';
import SidebarDivider from './Items/SidebarDivider';
import DiscountLabel from './DiscountLabel';

import './styles.scss';

const items = ['A* Customer Support', 'Clean IP Addresses'];

const scrollTop = () =>
    window.scrollTo({
        top: document?.querySelector('main')?.getBoundingClientRect().top,
        behavior: 'smooth',
    });

const OrderSummarySidebar = ({
    onSubmit,
    state,
    quantityText,
    initialPrice,
    finalPrice,
    pricePerUnit,
    productType,
    location,
    loadingCode,
    loadingSubmit,
    name,
    periodName,
    discountCode,
    onChangeDiscount,
    discount,
    discountError,
    onDiscountSubmit,
    disabled,
    isResidentialReconfigure,
}) => {
    const {
        total_after_applied_credit,
        customer_credit_balance_applied_amount,
        customer_credit_balance_applied,
        credit_balance,
        has_data,
        before_discount_total,
        discounted,
    } = useSelector(selectCalculationInfo);

    const isResidential = productType === service_types.residential;

    const handleSubmit = () => {
        scrollTop();
        onSubmit();
    };

    const residentialNextRenewalDate = useMemo(() => {
        const nextMonthDatetime = dayjs().add(1, 'month');
        const nextMonthDay = nextMonthDatetime.date();
        const nextMonth = nextMonthDatetime.format('MMMM');
        return `${nextMonthDay}${getOrdinalSuffix(nextMonthDay)} ${nextMonth}`;
    }, []);

    const discountPerUnit = useMemo(() => {
        const percentage = 100 - (pricePerUnit * 100) / initialPrice;

        return Math.ceil(percentage).toFixed(0) + '%';
    }, [initialPrice, pricePerUnit]);

    const total = useMemo(() => {
        const total = discount * finalPrice;

        return '$' + total.toFixed(2);
    }, [discount, finalPrice]);

    return (
        <div className="order-summary-sidebar-container">
            <div className="order-summary box">
                <h5>{isResidentialReconfigure ? 'Reconfiguration Summary' : 'Order summary'}</h5>
                <div className="subtitle1 pb-8">{name}</div>
                <List items={items} className="pb-16" />
                {[
                    { value: residentialNextRenewalDate, name: 'Next Renewal Date', includeWhenResidential: true },
                    { value: quantityText, name: 'Quantity' },
                    { value: discountPerUnit, name: 'Quantity Discount' },
                    {
                        value: '$' + pricePerUnit?.toFixed(2),
                        name: 'Monthly price per IP',
                        includeWhenResidential: false,
                    },
                    { value: location?.label, name: 'Location', includeWhenResidential: false },
                    { value: periodName, name: 'Subscription period' },
                ].map((row, idx) => {
                    if (
                        typeof row.includeWhenResidential === 'boolean' &&
                        row.includeWhenResidential !== isResidential
                    ) {
                        return null;
                    }

                    return (
                        <SidebarRow key={idx} name={row.name}>
                            {row.value}
                        </SidebarRow>
                    );
                })}
                {state === 1 && !isResidentialReconfigure && (
                    <DiscountCodeForm
                        loading={loadingCode}
                        value={discountCode}
                        onChange={onChangeDiscount}
                        error={discountError}
                        onSubmit={onDiscountSubmit}
                        disabled={discount < 1 || loadingSubmit}
                    />
                )}
                <SidebarDivider />
                {discounted && <SidebarRow name="Before discount total">{before_discount_total}</SidebarRow>}
                {discount < 1 && (
                    <SidebarRow name={<DiscountLabel discount={discount} discountCode={discountCode} />}>
                        -${(finalPrice - finalPrice * discount).toFixed(2)}
                    </SidebarRow>
                )}
                {has_data && <SidebarRow name="Credit balance">{credit_balance}</SidebarRow>}
                {customer_credit_balance_applied && (
                    <SidebarRow name="Credit balance applied amount">
                        {customer_credit_balance_applied_amount}
                    </SidebarRow>
                )}
                {!isResidential && <SidebarRow name="Total">{has_data ? total : <h4>{total}</h4>}</SidebarRow>}
                {total_after_applied_credit && has_data && (
                    <SidebarRow name="Total after applied credit">
                        <h4>{total_after_applied_credit}</h4>
                    </SidebarRow>
                )}
            </div>
            {state < 3 && (
                <div className="order-submit-container">
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleSubmit}
                        loading={loadingSubmit}
                        disabled={loadingCode || disabled}
                    >
                        {isResidentialReconfigure ? 'Calculate Top Up' : 'Continue to checkout'}
                    </Button>
                    <PaymentsMethods />
                </div>
            )}
        </div>
    );
};

export default OrderSummarySidebar;
