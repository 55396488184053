import './styles.scss';
import PageHeader from 'components/PageHeader';
import BillingTabs from './_Items/Tabs';

const BillingLayout = ({ children, headerContent = null }) => {
    return (
        <div className="billing-layout">
            <PageHeader title="Billing" />
            <BillingTabs>{headerContent}</BillingTabs>
            <div className="content-container">{children}</div>
        </div>
    );
};
export default BillingLayout;
