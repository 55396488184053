const tooltip = {
    styleOverrides: {
        tooltip: {
            fontSize: 'inherit',
            backgroundColor: 'var(--ping-Brand-brand-900)',
            padding: 0,
        },
        arrow: {
            color: 'var(--ping-Brand-brand-900)',
        },
    },
};

export default tooltip;
