import React from 'react';

const Code = () => {
    return (
        <code>
            <pre>
                {`import pingproxies

proxy_type = "ISP"
quantity = 10
username = "SteveJobs"

client = pingproxies.client

new_order = client.new_order(proxy_type=proxy_type,
quantity=quantity)

proxy_user = client.get_user(username)`}
            </pre>
        </code>
    );
};

export default Code;
