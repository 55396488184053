import ClearFiltersButton from 'components/Buttons/ClearFilters';
import SearchInput from '../../components/_common/SearchInput';
import FilterType from './Items/FilterType';
import ServicesFilter from './Items/ServicesFilter';
import CountryFilter from './Items/CountryFilter';

export const PROXY_LIST_FILTER_KEYS = {
    TYPE: 'type',
    SERVICE: 'service',
    COUNTRY: 'country',
    SEARCH: 'search',
};

const ProxyListFilters = ({ type, service, country, search, onChange, reset }) => {
    const showClear = Boolean(type.length || service.length || country.length || !!search);

    const handleChange = key => value => {
        onChange(key, value);
    };

    return (
        <>
            <ServicesFilter value={service} onChange={handleChange(PROXY_LIST_FILTER_KEYS.SERVICE)} />
            <CountryFilter value={country} onChange={handleChange(PROXY_LIST_FILTER_KEYS.COUNTRY)} />
            <FilterType value={type} onChange={handleChange(PROXY_LIST_FILTER_KEYS.TYPE)} />
            <SearchInput
                value={search}
                onChange={handleChange(PROXY_LIST_FILTER_KEYS.SEARCH)}
                placeholder="Search by IP"
            />
            <ClearFiltersButton onClick={reset} isShow={showClear} />
        </>
    );
};

export default ProxyListFilters;
