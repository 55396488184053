import Chip from '@mui/material/Chip';

import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import showMessage from 'components/NoticeProvider/showMessage';

import '../styles.scss';

const TextCell = ({ text, capitalize = false, labelText = '', hoverText, content, alignment = 'end' }) => {
    const [, copy] = useCopyToClipboard();

    const handleCopy = () => {
        copy(text)
            .then(() => {
                showMessage('Copied!', 'success');
            })
            .catch(error => {
                console.error('Failed to copy!', error);
            });
    };

    return (
        <div
            onClick={handleCopy}
            className={`text-cell copy-cell pointer ${capitalize ? 'capitalize' : ''} ${alignment}`}
            title={text}
        >
            <span className="copy-cell-content">{content ?? text}</span>
            {hoverText ? <span className="copy-cell-hover-content">{hoverText}</span> : null}
            {labelText ? <Chip label={labelText} color="default" variant="outlined" size="small" /> : null}
        </div>
    );
};

export default TextCell;
