import './styles.scss';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import showMessage from 'components/NoticeProvider/showMessage';
import ExportButton from 'components/Buttons/ExportButton';
import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import MessageText from 'components/MessageText';
import ProxyUserDropdown from 'components/ProxyUserDropdown';
import Select from 'components/_common/Select/SimpleSelect';

import { selectSelectedIDs, setIDs } from 'store/slices/proxy';
import { fetchProxyUsers } from 'store/slices/proxyUsers';
import ProxyService from 'api/services/ProxyService';

import { exportProxyAuthTypes, exportProxyAuthTypesOptions, proxyFormatOptions } from 'helpers/constants';
import { withoutEmptyValues } from 'helpers';

const list_protocol_options = [
    { value: 'http', label: 'HTTP' },
    { value: 'socks5', label: 'SOCKS5' },
];

const export_ext_options = [{ value: 'TXT', label: 'TXT' }];

const form_keys = {
    LIST_PROTOCOL: 'list_protocol',
    LIST_FORMAT: 'list_format',
    EXT: 'ext',
    AUTH_TYPE: 'list_authentication',
    USER: 'user',
};

const ModalExportProxyList = ({ filterProps }) => {
    const dispatch = useDispatch();
    const ids = useSelector(selectSelectedIDs);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        [form_keys.LIST_PROTOCOL]: list_protocol_options[0].value,
        [form_keys.LIST_FORMAT]: proxyFormatOptions[0].value,
        [form_keys.EXT]: export_ext_options[0].value,
        [form_keys.AUTH_TYPE]: exportProxyAuthTypes.PROXY_USER_AUTH,
        [form_keys.USER]: null,
    });

    const { country, search, type, service } = filterProps;

    useEffect(() => {
        handleChange(form_keys.USER)(null);
    }, [open]);

    useEffect(() => {
        dispatch(fetchProxyUsers());

        return () => {
            dispatch(setIDs([]));
        };
    }, []);

    const handleClick = async () => {
        try {
            setLoading(true);

            const authTypeParamsMapping = {
                [exportProxyAuthTypes.PROXY_USER_AUTH]: params.user
                    ? { list_authentication: 'username_and_password', proxy_user_id: params.user.value }
                    : { list_authentication: null },
                [exportProxyAuthTypes.IP_AUTH]: {
                    list_authentication: 'ip_address',
                },
                [exportProxyAuthTypes.PROXY_SPECIFIC_AUTH]: {
                    list_authentication: 'proxy_specific',
                },
            };

            const formattedParams = {
                ...params,
                ...authTypeParamsMapping[params[form_keys.AUTH_TYPE]],
            };

            delete formattedParams.user;

            if (!ids.length) {
                formattedParams.country_id = country;
                formattedParams.like_ip_address_id_v4 = `${search}%`;
                formattedParams.proxy_type = type;
                formattedParams.service_id = service;
            } else {
                formattedParams.proxy_ids = ids;
            }

            const handleExport = async method => {
                await method(withoutEmptyValues(formattedParams))
                    .then(response => {
                        const data = response.data.data;
                        const fileName = `Export ${dayjs().format('DD-MM-YYYY (HH-mm)')}.txt`;
                        const blob = new Blob(
                            data.map(row => `${row}\n`),
                            { type: 'text/plain;charset=utf-8' },
                        );
                        FileSaver.saveAs(blob, fileName);
                        setOpen(false);
                        return response;
                    })
                    .then(response => {
                        showMessage(response.data.message, response.status);
                        dispatch(setIDs([]));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            };

            if (ids.length) {
                await handleExport(ProxyService.list_by_id);
            } else {
                await handleExport(ProxyService.list_by_search);
            }
        } catch (e) {
            return false;
        } finally {
            setLoading(false);
        }
    };

    const handleChange = useCallback(
        key => {
            return value => {
                switch (key) {
                    case form_keys.LIST_FORMAT: {
                        const standardOption = proxyFormatOptions.at(0).value;

                        if (value === standardOption) {
                            return setParams(p => ({ ...p, [key]: value }));
                        }

                        return setParams(p => {
                            const currentProtocol = p[form_keys.LIST_PROTOCOL];

                            if (value.includes(currentProtocol)) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case proxyFormatOptions[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_PROTOCOL]: list_protocol_options[0].value,
                                    };
                                // socks case
                                case proxyFormatOptions[2].value:
                                case proxyFormatOptions[3].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_PROTOCOL]: list_protocol_options[1].value,
                                    };

                                default: {
                                    return { ...p, [key]: value };
                                }
                            }
                        });
                    }
                    case form_keys.LIST_PROTOCOL: {
                        if (!value) {
                            return;
                        }

                        return setParams(p => {
                            const currentFormat = p[form_keys.LIST_FORMAT] ?? '';

                            const standardOption = proxyFormatOptions.at(0).value;

                            if (!currentFormat || currentFormat.includes(value) || currentFormat === standardOption) {
                                return { ...p, [key]: value };
                            }

                            switch (value) {
                                // http case
                                case list_protocol_options[0].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_FORMAT]: proxyFormatOptions[1].value,
                                    };
                                // socks case
                                case list_protocol_options[1].value:
                                    return {
                                        ...p,
                                        [key]: value,
                                        [form_keys.LIST_FORMAT]: proxyFormatOptions[2].value,
                                    };
                                default:
                                    return { ...p, [key]: value };
                            }
                        });
                    }
                    default: {
                        setParams(p => ({ ...p, [key]: value }));
                    }
                }
            };
        },
        [setParams],
    );

    return (
        <ControledDialog
            title="Export proxy list"
            Submit={
                <Button onClick={handleClick} loading={loading}>
                    Export
                </Button>
            }
            setOpen={setOpen}
            open={open}
            Trigger={<ExportButton />}
            minHeight={284}
        >
            <p className="body2">Setup and export all the proxies</p>
            <div className="proxy-list-radio-group">
                <RadioGroup
                    label="Proxy protocol"
                    items={list_protocol_options}
                    value={params[form_keys.LIST_PROTOCOL]}
                    onChange={handleChange(form_keys.LIST_PROTOCOL)}
                />
                <RadioGroup
                    label="Output format"
                    items={export_ext_options}
                    value={params[form_keys.EXT]}
                    onChange={handleChange(form_keys.EXT)}
                />
            </div>
            <SimpleSelect
                fullWidth
                withFormLabel
                label="Authentication Option"
                options={exportProxyAuthTypesOptions}
                value={params[form_keys.AUTH_TYPE]}
                onChange={handleChange(form_keys.AUTH_TYPE)}
            />
            {params[form_keys.AUTH_TYPE] === exportProxyAuthTypes.PROXY_USER_AUTH && (
                <ProxyUserDropdown value={params[form_keys.USER]} onChange={handleChange(form_keys.USER)} />
            )}
            <Select
                label="Format"
                onChange={handleChange(form_keys.LIST_FORMAT)}
                options={proxyFormatOptions}
                value={params[form_keys.LIST_FORMAT]}
                fullWidth
                withFormLabel
                defaultPlaceholder="Select Format"
            />
            <div className="box mt-8">
                <MessageText
                    small
                    noIcon
                    type="good"
                    text={
                        <ul
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                                listStyle: 'disc',
                                color: 'var(--ping-Grey-grey-500)',
                            }}
                        >
                            <li style={{ listStyle: 'disc' }}>Use Proxy User Authentication To Track Analytics</li>
                            <li style={{ listStyle: 'disc' }}>
                                IP Authentication can be set up on the Proxy User page
                            </li>
                            <li style={{ listStyle: 'disc' }}>
                                Proxy Specific Authentication activity will be attributed to your default Proxy User
                            </li>
                        </ul>
                    }
                />
            </div>
        </ControledDialog>
    );
};

export default ModalExportProxyList;
