import React, { useState } from 'react';
import dayjs from 'dayjs';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

import ModalEditProxyUser from 'components/Modals/ModalEditProxyUser';
import ModalDeleteUserProxy from 'components/Modals/ModalDeleteUserProxy';
import Table from 'components/_common/Table';
import ProxyUserBandwidthUsageCell from 'components/_common/TableCells/ProxyUserBandwidthUsageCell';
import ListCell from 'components/_common/TableCells/ListCell';
import PasswordCell from 'components/_common/TableCells/PasswordCell';
import TextCell from 'components/_common/TableCells/TextCell';
import LabelledListCell from 'components/_common/TableCells/ListCell/LabelledListCell';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/trash.svg';

const ProxyUserSettingsTable = ({ data, dataLoading, setFilters, total, noDataComponent, filters, refreshData }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [id, setID] = useState('');

    const columns = React.useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Proxy User ID',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value, row }) => (
                    <TextCell
                        text={value}
                        labelText={row.proxy_user_is_default ? 'Default' : ''}
                        labelColor="success"
                    />
                ),
            },
            {
                field: 'is_deleted',
                headerName: 'Status',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => (
                    <Chip label={value ? 'Deleted' : 'Active'} color={value ? 'error' : 'success'} size="small" />
                ),
            },
            {
                field: 'password',
                headerName: 'Password',
                flex: 1,
                minWidth: 170,
                renderCell: ({ value }) => <PasswordCell value={value} />,
            },
            {
                field: 'whitelisted_ip',
                headerName: 'Whitelisted IP',
                flex: 1,
                minWidth: 120,
                renderCell: ({ value }) => <ListCell data={value} max_size={1} />,
            },
            {
                field: 'residential_bandwidth_usage',
                headerName: 'Residential Bandwidth Usage',
                flex: 0.5,
                minWidth: 230,
                renderCell: ({ value }) => <ProxyUserBandwidthUsageCell value={value.value} limit={value.limit} />,
            },
            {
                field: 'restricted_service_ids',
                headerName: 'Linked Static Services',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value, row }) => {
                    const isRestricted = row.proxy_user_is_service_restricted;

                    if (!isRestricted) {
                        return <Chip label="All" color="success" size="small" />;
                    } else if (isRestricted && value?.length > 0) {
                        return <LabelledListCell data={value} max_size={1} variant="default" />;
                    } else {
                        return <Chip label="None" color="error" size="small" />;
                    }
                },
            },
            {
                field: 'proxy_user_creation_datetime',
                headerName: 'Created',
                flex: 1,
                minWidth: 180,
                sortable: true,
                renderCell: ({ value }) => <TextCell text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />,
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 50,
                width: 50,
                getActions: ({ row, id }) => [
                    <GridActionsCellItem
                        showInMenu
                        label="Edit"
                        icon={<Edit />}
                        onClick={() => handleEditOpen(row)}
                        disabled={row.is_deleted}
                    />,
                    <GridActionsCellItem
                        disabled={row.proxy_user_is_default || row.is_deleted}
                        showInMenu
                        className="item-danger"
                        label="Remove"
                        icon={<Delete />}
                        onClick={() => handleDeleteOpen(id)}
                    />,
                ],
            },
        ],
        [],
    );

    const handleDeleteOpen = id => {
        setID(id);
        setDeleteOpen(true);
    };

    const handleEditOpen = userData => {
        setUserData(userData);
        setEditOpen(true);
    };

    const fetchTableData = filters => {
        setFilters(prevFilters => {
            const copyFilters = { ...prevFilters, ...filters };

            if (!filters.sort_by) {
                delete copyFilters.sort_by;
            }

            return copyFilters;
        });
    };

    return (
        <>
            <Table
                lastColSticky
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={fetchTableData}
                filters={filters}
                total={total}
                noDataComponent={noDataComponent}
            />
            {deleteOpen && (
                <ModalDeleteUserProxy setOpen={setDeleteOpen} open={deleteOpen} id={id} refreshData={refreshData} />
            )}
            {editOpen && userData ? (
                <ModalEditProxyUser setOpen={setEditOpen} open={editOpen} data={userData} refreshData={refreshData} />
            ) : null}
        </>
    );
};

export default ProxyUserSettingsTable;
