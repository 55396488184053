// @ts-nocheck
import React, { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { ReactComponent as CloseIcon } from 'icons/x-close.svg';

import './styles.scss';

const Popup = forwardRef(
    ({ children, closeButton = false, onCancel = null, open, setOpen, spacing = 3, className, ...props }, ref) => {
        const handleCancel = () => {
            setOpen(false);
            onCancel?.();
        };

        return (
            <Dialog className={'popup ' + className} open={open} onClose={handleCancel} {...props} ref={ref}>
                {closeButton && (
                    <IconButton aria-label="close" onClick={handleCancel} className="popup-close-button">
                        <CloseIcon />
                    </IconButton>
                )}

                <Stack className="popup-container" mt={spacing * 1.5} gap={spacing}>
                    {children}
                </Stack>
            </Dialog>
        );
    },
);

Popup.displayName = 'Popup';
Popup.muiName = 'Dialog';

export default Popup;
