import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';

import ROUTES from 'helpers/routes';

import { ReactComponent as DashboardIcon } from 'icons/layout-dashboard.svg';
import { ReactComponent as SummaryIcon } from 'icons/select-all.svg';
import { ReactComponent as ProxyListIcon } from 'icons/clipboard-list.svg';
import { ReactComponent as ProxySettingsIcon } from 'icons/users.svg';
import { ReactComponent as ProductsIcon } from 'icons/shop.svg';
import { ReactComponent as ApiKeysIcon } from 'icons/key.svg';
import { ReactComponent as ApiRequestsIcon } from 'icons/code-circle.svg';
import { ReactComponent as BillingIcon } from 'icons/billing.svg';
import { ReactComponent as HelpIcon } from 'icons/help-circle.svg';
import { ReactComponent as ResidentialIcon } from 'icons/service-logo-2-grey.svg';
import { ReactComponent as BookIcon } from 'icons/book.svg';
import { ReactComponent as BugIcon } from 'icons/bug.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';

export const getIcon = to => {
    const icons = {
        [ROUTES.dashboard]: <DashboardIcon />,
        [ROUTES.summary]: <SummaryIcon />,
        [ROUTES.proxyList]: <ProxyListIcon />,
        [ROUTES.proxyUsers]: <ProxySettingsIcon />,
        [ROUTES.products]: <ProductsIcon />,
        [ROUTES.residential]: <ResidentialIcon />,
        [ROUTES.apiKeys]: <ApiKeysIcon />,
        [ROUTES.apiRequests]: <ApiRequestsIcon />,
        [ROUTES.billingSubscriptions]: <BillingIcon />,
        [ROUTES.billingInvoices]: <BillingIcon />,
        [ROUTES.billingPaymentSettings]: <BillingIcon />,
        [ROUTES.billingTransactions]: <BillingIcon />,
        [ROUTES.billingAddress]: <BillingIcon />,
        book: <BookIcon />,
        bug: <BugIcon />,
        tool: <SettingsIcon />,
        undefined: <HelpIcon />,
    };
    return icons[to] || <HelpIcon />;
};

const SidebarNavLink = ({ to, icon = undefined, text, className = '', onClick = undefined, blank = false }) => {
    const commonProps = {
        to,
        fullWidth: true,
        size: 'small',
        onClick: onClick,
        component: to ? NavLink : undefined,
        startIcon: getIcon(icon || to),
        className: `sidebar-nav-link ${className}`,
    };

    if (blank) {
        commonProps.target = '_blank';
        commonProps.rel = 'noopener noreferrer';
    }

    return <Button {...commonProps}>{text}</Button>;
};

export default SidebarNavLink;
