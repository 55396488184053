import { extractToltReferralUUID } from 'api/helpers';
import GoogleAuthService, { authorization_type } from 'api/services/GoogleAuthService';
import Button from 'components/_common/Button';
import { useState } from 'react';

const GoggleButton = ({ isLogin = false, to = null, queryToltId = null }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const params = {
                authorization_type: isLogin ? authorization_type.login : authorization_type.signup,
                redirect_url: to ? `${process.env.REACT_APP_DASHBOARD_URL}${to}` : process.env.REACT_APP_DASHBOARD_URL,
            };
            const toltReferralUUID = extractToltReferralUUID();
            if (toltReferralUUID) {
                params.customer_tolt_referral_id = toltReferralUUID;
            }
            if (queryToltId) {
                params.customer_tolt_referral_id = queryToltId;
            }
            const redirect_url = GoogleAuthService.generate_url(params);
            window.open(redirect_url, '_self');
        } catch (e) {
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            fullWidth
            size="large"
            onClick={handleClick}
            loading={loading}
            variant="outlined"
            iconName="google"
            className="bg-white"
            containerStyle="google-button"
        >
            Continue with Google
        </Button>
    );
};
export default GoggleButton;
