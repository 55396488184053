// @ts-nocheck
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { fetchCities, selectCitiesOptions } from 'store/slices/customer';

import Dropdown from './Dropdown';

const CityDropdown = ({ value, onChange, countryCode = '', disabled }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectCitiesOptions);

    useEffect(() => {
        dispatch(fetchCities());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onChange(null);

        if (!countryCode || countryCode === 'All') {
            return;
        }

        dispatch(fetchCities({ like_subdivision_id: `${countryCode}-%` }));
    }, [countryCode, dispatch]);

    return (
        <FormControl fullWidth>
            <FormLabel focused={false}>Country</FormLabel>
            <Dropdown disabled={disabled} onChange={onChange} value={value} options={options} />
        </FormControl>
    );
};

export default CityDropdown;
