import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { fetchASN, selectASNOptions } from 'store/slices/ASN';

import Dropdown from './Dropdown';

import { withoutEmptyValues } from 'helpers';

const IspAsnDropdown = ({ value, onChange, countryCode = '' }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectASNOptions);

    const params = withoutEmptyValues({
        min_asn_ip_address_count: 100000,
        asn_type: 'isp',
        country_id: countryCode,
    });

    useEffect(() => {
        onChange(null);
        // @ts-ignore
        dispatch(fetchASN(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    return (
        <FormControl fullWidth>
            <FormLabel focused={false}>ISP&nbsp;&#47;&nbsp;ASN</FormLabel>
            <Dropdown onChange={onChange} value={value} options={options} />
        </FormControl>
    );
};

export default IspAsnDropdown;
