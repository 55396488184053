import './styles.scss';
import MetricItem from './MetricItem';

const LogChart = ({ title, filters, metricsData, chart, network }) => {
    return (
        <div className="log-chart box">
            <div className="log-chart__header">
                <h5>{title}</h5>
                <div className="log-chart-filters">{filters}</div>
            </div>
            <div className="log-chart__info">
                {metricsData
                    ?.filter(i => i?.networks?.includes(network))
                    .map(({ title, value }) => (
                        <MetricItem key={title} title={title} value={value} />
                    ))}
            </div>
            <div className="log-chart__chart">{chart}</div>
        </div>
    );
};

export default LogChart;
