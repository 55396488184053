import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { fetchResidentialSummary, selectMetrics } from 'store/slices/services';
import { fetchAnalytict, selectAnalytics, selectAnalyticsLoading } from 'store/slices/logSlice';
import { REQUEST_PRESET_VALUES } from 'helpers/constants';
import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';
import { metricTypes, getRandomColor, convertRequestPresetToInterval } from 'helpers';

import LogChart from 'components/LogChart';
import Chart from 'components/_common/Chart';
import Filters, { FILTER_KEYS } from './Filters';

const colorOptions = [
    'rgba(161, 146, 236, 1)',
    'rgba(40, 54, 128, 1)',
    'rgba(217, 147, 247, 1)',
    'rgba(138, 189, 249, 1)',
    'rgba(0, 182, 122, 1)',
    'rgba(255, 107, 107, 1)',
    'rgba(254, 202, 87, 1)',
    'rgba(84, 160, 255, 1)',
    'rgba(255, 159, 243, 1)',
    'rgba(95, 39, 205, 1)',
    'rgba(29, 209, 161, 1)',
    'rgba(255, 159, 243, 1)',
    'rgba(84, 160, 255, 1)',
    'rgba(255, 107, 107, 1)',
    'rgba(0, 182, 122, 1)',
];

const areUsersEqual = (prevUsers, currentUsers) => {
    if (prevUsers.length !== currentUsers.length) return false;

    return isEqual(prevUsers, currentUsers);
};

const DashboardBandwidth = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const metrics = useSelector(selectMetrics);
    const data = useSelector(selectAnalytics);
    const loading = useSelector(selectAnalyticsLoading);

    const [searchParams, setSearchParams] = useState({
        preset: REQUEST_PRESET_VALUES.LAST_WEEK,
        network: pathname === ROUTES.residential ? service_types.residential : '',
    });
    const [metricFilter, setMetricFilter] = useState(metricTypes.megabytes);
    const [users, setUsers] = useState([]);
    const [userColors, setUserColors] = useState({});

    const userColorsArr = Object.values(userColors);

    const getColorForUser = useCallback(
        userId => {
            if (userColors[userId]) {
                return userColors[userId];
            }

            let newColor;
            if (Object.keys(userColors).length < colorOptions.length - 1) {
                const availableColors = colorOptions
                    .slice(1)
                    .filter(color => !Object.values(userColors).includes(color));
                newColor = availableColors[0];
            } else {
                newColor = getRandomColor();
            }

            setUserColors(prev => ({ ...prev, [userId]: newColor }));
            return newColor;
        },
        [userColors],
    );

    const chartData = useMemo(() => {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        const processData = intervals => {
            return intervals.map(interval => {
                switch (metricFilter) {
                    case metricTypes.bytes:
                        return interval.bytes;
                    case metricTypes.megabytes:
                        return interval.bytes / Math.pow(1024, 2);
                    case metricTypes.gigabytes:
                        return interval.bytes / Math.pow(1024, 3);
                    case metricTypes.requests:
                        return interval.requests;
                    case metricTypes.errorRate:
                        return interval.requests ? (1 - interval.successful / interval.requests) * 100 : 0;
                    default:
                        return interval.bytes;
                }
            });
        };

        const createChartData = (d, label) => {
            const color = label === 'All Users' ? colorOptions[0] : getColorForUser(label);

            return {
                data: processData(d.intervals),
                label: label,
                area: true,
                color: color,
            };
        };

        const chartDataAllUsers = data.filter(d => d.proxy_user_id === null).map(d => createChartData(d, 'All Users'));

        const chartDataFiltered = users.flatMap(id => {
            const userData = data.find(d => d.proxy_user_id === id);
            return userData ? [createChartData(userData, id)] : [];
        });
        return users.length ? [...chartDataAllUsers, ...chartDataFiltered] : chartDataAllUsers;
    }, [data, users, getColorForUser, metricFilter]);

    const yAxisMax = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.errorRate:
                return 100;
            default:
                return false;
        }
    }, [metricFilter]);

    const yAxisValueFormatter = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.errorRate:
                return value => `${value.toLocaleString()} %`;
            default:
                return false;
        }
    }, [metricFilter]);

    const seriesValueFormatter = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.bytes:
                return value => `${value.toLocaleString()} Bytes`;
            case metricTypes.errorRate:
                return value => `${value.toLocaleString()} % Error Rate`;
            case metricTypes.gigabytes:
                return value => `${value.toLocaleString()} GB`;
            case metricTypes.megabytes:
                return value => `${value.toLocaleString()} MB`;
            case metricTypes.requests:
                return value => `${value.toLocaleString()} Requests`;
            default:
                return value => value;
        }
    }, [metricFilter]);

    const labels = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return [];
        }

        const firstDataItem = data[0];
        if (!firstDataItem || !Array.isArray(firstDataItem.intervals)) {
            return [];
        }
        // transform data from backend (UTC) to user local time
        const interval = convertRequestPresetToInterval(searchParams.preset);

        const formatStorage = {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'MM-DD',
            month: 'MM-DD',
        };

        return firstDataItem.intervals.map(item =>
            dayjs
                .utc(item.interval)
                .local()
                .format(formatStorage[interval] || 'YYYY-MM-DD HH:mm'),
        );
    }, [data]);

    const handleSetUsers = useCallback(
        newUsers => {
            if (areUsersEqual(users, newUsers)) return;

            setUsers(newUsers);
        },
        [users],
    );

    const handleFilterChange = useCallback(
        fieldKey => {
            return (...args) => {
                switch (fieldKey) {
                    case FILTER_KEYS.USERS: {
                        handleSetUsers(...args);
                        break;
                    }
                    case FILTER_KEYS.METRICS: {
                        setMetricFilter(...args);
                        break;
                    }
                    case FILTER_KEYS.NETWORK: {
                        setSearchParams(prevParams => ({ ...prevParams, network: args[0] }));
                        break;
                    }
                    case FILTER_KEYS.DATE_RANGE: {
                        setSearchParams(prevParams => ({ ...prevParams, preset: args[0] }));
                        break;
                    }
                    default: {
                        console.warn('Provided key does not exist');
                    }
                }
            };
        },
        [handleSetUsers],
    );

    useEffect(() => {
        dispatch(fetchAnalytict({ searchParams, userIds: users }));
    }, [searchParams, users, dispatch]);

    useEffect(() => {
        dispatch(fetchResidentialSummary());
    }, [dispatch]);

    return (
        <LogChart
            title="Proxy Analytics"
            filters={
                <Filters
                    users={users}
                    network={searchParams.network ?? ''}
                    preset={searchParams.preset}
                    metricFilter={metricFilter}
                    onChange={handleFilterChange}
                />
            }
            metricsData={metrics}
            network={searchParams.network ?? ''}
            chart={
                <Chart
                    gradientColors={users?.length ? userColorsArr : colorOptions}
                    loading={loading}
                    xLabels={labels}
                    data={chartData}
                    yAxisConfig={{ yAxisMax, yAxisValueFormatter }}
                    seriesConfig={{ valueFormatter: seriesValueFormatter }}
                />
            }
        />
    );
};

export default DashboardBandwidth;
