import './styles.scss';

import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { BADGE_VARIANTS } from 'components/_common/PopupBadge';
import Popup from 'components/_common/Popup';
import TextInput from 'components/_common/TextInput';
import PopupContent from 'components/_common/Popup/PopupContent';
import CodeSnippet from '../ModalDeleteApiKey/CodeSnippet/CodeSnippet';
import PopupActions from 'components/_common/Popup/PopupActions';
import showMessage from 'components/NoticeProvider/showMessage';

import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import { ReactComponent as ChevronLeftIcon } from 'icons/chevron-left.svg';
import { ReactComponent as CopyIcon } from 'icons/copy.svg';

const ModalGeneratedApiKeys = ({ setOpen, open, keys }) => {
    const { api_key_private_key, api_key_public_key } = keys;

    const [copied, setCopied] = useState(false);
    const [, copy] = useCopyToClipboard();

    const handleCopy = (content, close) => () => {
        copy(content)
            .then(() => {
                showMessage('Copied!', 'success');
                if (close) {
                    setCopied(close);
                }
            })
            .catch(error => {
                console.error('Failed to copy!', error);
            });
    };

    const handleClose = () => {
        if (!copied) return;

        setOpen(false);
        setCopied(false);
    };

    return (
        <Popup open={open} setOpen={handleClose}>
            <CodeSnippet badgeVariant={BADGE_VARIANTS.success} />
            <PopupContent title="Your API Key has been successfully generated">
                <Stack gap={2} mt={1}>
                    <Typography
                        className="warning-box"
                        variant="body2"
                        textAlign="center"
                        color="grey.700"
                        sx={{ opacity: 0.6 }}
                    >
                        Please copy your API Private Key and securely store it. You will not be able to see it again
                        after closing this pop up.
                    </Typography>
                    {[
                        {
                            value: api_key_private_key,
                            label: 'Private key',
                            copy: handleCopy(api_key_private_key, true),
                        },
                        {
                            value: api_key_public_key,
                            label: 'Public key',
                            copy: handleCopy(api_key_public_key, false),
                        },
                    ].map(({ copy, label, value }) => {
                        return (
                            <Box key={label} display="grid" gridTemplateColumns="1fr auto" alignItems="end" gap={1}>
                                <TextInput
                                    label={label}
                                    value={value}
                                    InputProps={{
                                        readOnly: true,
                                        sx: {
                                            '.MuiInputBase-input': { textOverflow: 'ellipsis' },
                                        },
                                        style: {
                                            backgroundColor: 'var(--ping-Grey-grey-100)',
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: 'primary.500', height: 40, boxShadow: 'none' }}
                                    onClick={copy}
                                    startIcon={<CopyIcon width={16} height={16} />}
                                >
                                    Copy
                                </Button>
                            </Box>
                        );
                    })}
                </Stack>
            </PopupContent>
            <PopupActions
                onSubmit={handleClose}
                single
                submitContent="Back to dashboard"
                submitProps={{ disabled: !copied, startIcon: <ChevronLeftIcon width={16} height={16} /> }}
            />
        </Popup>
    );
};

export default ModalGeneratedApiKeys;
