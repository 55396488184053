// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
    fetchReconfigureOptions,
    selectReconfigureOptionsError,
    selectReconfigureOptionsLoading,
    selectReconfigureParams,
} from 'store/slices/reconfigureService';
import ServicesService from 'api/services/ServicesService';
import { service_types } from 'helpers/services';

import Popup from 'components/_common/Popup';
import PopupContent from 'components/_common/Popup/PopupContent';
import PopupActions from 'components/_common/Popup/PopupActions';
import Spinner from 'components/_common/Spinner';
import showMessage from 'components/NoticeProvider/showMessage';
import ReconfigureData from './Items/ReconfigureData';
import ScheduleTabs from './Items/ScheduleTabs';

import './styles.scss';

const ModalReconfigureService = ({
    setOpen,
    open,
    serviceData = null,
    initialReconfigureData = null,
    isResidential = false,
    isExistingLoading = false,
    successMessage = 'Reconfiguration is complete',
}) => {
    const dispatch = useDispatch();
    const reconfigure_loading = useSelector(selectReconfigureOptionsLoading);
    const error = useSelector(selectReconfigureOptionsError);
    const params = useSelector(selectReconfigureParams);

    const { disabled } = params;

    const [loading, setLoading] = useState(false);
    const [reconfigureData, setReconfigureData] = useState(initialReconfigureData);

    const toggleModal = useCallback(() => setOpen(p => !p), [setOpen]);

    useEffect(() => {
        if (!open || !serviceData) {
            return;
        }

        setReconfigureData(null);
        dispatch(fetchReconfigureOptions(serviceData?.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (error) {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (reconfigureData) return;

        setReconfigureData(initialReconfigureData);
    }, [initialReconfigureData]);

    const handleCalculate = async () => {
        try {
            setLoading(true);
            if (reconfigureData) {
                const calculatedParams = isEmpty(params)
                    ? {
                          service_id: initialReconfigureData?.service_id,
                          end_of_period: false,
                          service_price_id: initialReconfigureData?.service_price_id,
                          service_quantity: initialReconfigureData?.service_quantity,
                      }
                    : params;
                await ServicesService.reconfigure(calculatedParams)
                    .then(response => {
                        if (response.status === 200) {
                            showMessage(successMessage, 'success');
                        }
                        if (response.status === 201) {
                            showMessage('Subscription schedule created and reconfiguration is scheduled', 'success');
                        }
                        setOpen(false);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                await ServicesService.reconfigureCalculate(params)
                    .then(response => {
                        setReconfigureData(response.data);
                        showMessage(response.data.message, 'success');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } catch (e) {
            return false;
        }
    };

    const isContentLoading = isExistingLoading || reconfigure_loading;

    return (
        <Popup open={open} setOpen={setOpen} className="reconfigure-popup" closeButton>
            <PopupContent title="Top Up / Reconfiguration">
                {isContentLoading ? (
                    <Spinner />
                ) : reconfigureData ? (
                    <ReconfigureData
                        data={initialReconfigureData || reconfigureData}
                        isResidential={serviceData?.service?.type === service_types.residential || isResidential}
                    />
                ) : serviceData ? (
                    <ScheduleTabs data={serviceData} />
                ) : null}
            </PopupContent>
            <PopupActions
                onCancel={toggleModal}
                onSubmit={handleCalculate}
                loading={loading}
                submitContent={reconfigureData ? 'Confirm' : 'Calculate'}
                submitProps={{
                    disabled: disabled || loading,
                    variant: 'contained',
                    sx: { backgroundColor: 'primary.500' },
                }}
            />
        </Popup>
    );
};

export default ModalReconfigureService;
